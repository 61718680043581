import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";

import CustomModal from "../custom-modal";
import { Flex, SharedButton } from "../shared";
import { SharedCard } from "../sharedCard";
import { Text } from "../styles/typography";
import { WalletContainer } from "../../modules/app/components/navbar/style";
import { FlexBox } from "../flexBox";
import copy from "../../assets/images/copy.svg";
import { copyAccount } from "../../logic/helpers/utilities";

export const DisconnectModal = (props: any) => {
  const { toggleModal , disconnect} = props;
  const { account, library } = useWeb3React();
  const [copyText, setCopyText] = useState('');
  
  const handleCopy = () =>{
    copyAccount(account);
    setTimeout(() => {
      setCopyText('')
    }, 2000);
    setCopyText('Copied!')
  }
  return (
    <CustomModal
      show={true}
      toggleModal={toggleModal}
      heading="Disconnect"
      width="448px"
      padding="24px"
      background='primary'
    >
      <SharedCard padding="16px 24px" background="secondary">
        <Text
          fontSize={16}
          fontWeight={500}
          lineHeight={24}
          color="secondary"
          textAlign="left"
          margin="0 0 6px 0"
        >
          Account
        </Text>
        <WalletContainer>
          <FlexBox>
            <Text
              fontSize={14}
              lineHeight={16}
              fontWeight={500}
              color="secondary"
            >
              {account
                ?
                copyText === '' ?
                //@ts-ignore
                  account?.slice(0, 3) +
                  "..." + //@ts-ignore
                  account?.substring(account.length - 5)
                : 'Copied!!!'
                :
                 "Connect Wallet"}
            </Text>
            <img src={copy} onClick={() => handleCopy()}  />
          </FlexBox>
        </WalletContainer>
      </SharedCard>
      <Flex>
        <SharedButton
          color="#E0476A"
          padding="12px 52px"
          margin="24px 0 0 0"
          onClick={() => disconnect()}
        >
          Disconnect
        </SharedButton>
      </Flex>
    </CustomModal>
  );
};
