import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useWeb3React } from '@web3-react/core'
import { ThemeProps, withTheme } from 'styled-components'

import usePositionColumns from './useGroupPositionColumns'
import ReactLoading from 'react-loading'
import { Flex } from '../../../../../shared/shared'
import { API_URL } from '../../../../../blockchain/helper/Config'
import { Paginated } from '../../../../../shared/pagination/pagination'
import { NoDataFound } from '../../../../../shared/noDataFound'

export const MyGroupPositions: React.FC = withTheme((props: ThemeProps<any>) => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { account } = useWeb3React()
  const columns = usePositionColumns()

  const getGroupPositions = () => {
    setLoading(true)
    axios
    .get(API_URL + 'trade/user_groups', {
      params: {
        wallet_address: account
      },
    })
    .then((res) => {
      const newArr1 = res.data && res.data.data.map((v:any) => ({...v, id: v.groupID}))
      setData(newArr1);
      setLoading(false)  })
    .catch((error) => {
      console.log(error);
      setLoading(false)
    })
  }
  /** getting the user position  and storing it in data object */
  useEffect(() => {
    setData([])
    account && getGroupPositions()
  }, [account])

  return (
    <>
      {!loading ? (
        data.length > 0 ? (
          <Paginated data={data} columns={columns} /> 
        ) : (
            <NoDataFound heading='No Positions Found' text="Looks like you haven't made any positions yet" />
        )
      ) : (
        <Flex>
          <ReactLoading type={'spinningBubbles'} color="#1c7eff" />
        </Flex>
      )}
    </>
  )
})
