/** Library imports */
import React from 'react'

/** Custom imports */
import { FlexRow } from '../../../../../shared/styles/styles';
import { Text } from '../../../../../shared/styles/typography';
import { Wrapper } from '../styles';
interface Props {
  logo?: string;
  token?: string;
}

export const Token = (props: Props) => {

  const { logo, token } = props;

  return (
    <Wrapper>
      <FlexRow justifyContent="flex-start">
        <img src={logo} alt="" />
        <Text
          fontSize={16}
          fontWeight={500}
          lineHeight={18}
          color="secondary"
          margin="0 0 0 8px"
        >
          {token}
        </Text>
      </FlexRow>
    </Wrapper>
  );
};

export default Token;