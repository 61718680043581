import React from "react";
import { useTable, usePagination } from "react-table";
import { SharedCard } from "../sharedCard";
import { FlexRow, TableStyles } from "../styles/styles";
import { TableFooter, TablePaginationButtons } from "./styles";
export const Paginated = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  return (
    <>
    <TableStyles padding="0 60px">
    <SharedCard padding="0" background="transaprent" scrollX={true} border="1px solid #3A365F">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </SharedCard>
      <TableFooter>
        <FlexRow gap='12px'>
        <TablePaginationButtons onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </TablePaginationButtons>{" "}
        <TablePaginationButtons onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </TablePaginationButtons>{" "}
        </FlexRow>
        <div>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
          </div>
          <FlexRow gap='12px'>
        <TablePaginationButtons onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </TablePaginationButtons>{" "}
        <TablePaginationButtons onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </TablePaginationButtons>
        </FlexRow>
      </TableFooter>
      </TableStyles>
    </>
  );
};
