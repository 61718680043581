import BigNumber from 'bignumber.js'

/** Calculating deadline with timestamp */
const timeStamp = new Date().getTime()
export const DEADLINE = timeStamp + Number(20) * 60

/** This is to calculate the min recieve of tokens */
export const handleMinReceive = (val: any, slippage: number) => {
  const slippageValue = new BigNumber(slippage).dividedBy(100)
  const valueTobeRemoved = new BigNumber(val).multipliedBy(slippageValue)
  const value = new BigNumber(val).minus(valueTobeRemoved)

  return value
}

/** This is to calculate the max sold of tokens */
export const handleMaxSold = (val: any, slippage: number) => {
  const slippageValue = new BigNumber(slippage).dividedBy(100)
  const valueTobeAdded = new BigNumber(val).multipliedBy(slippageValue)
  const value = new BigNumber(val).plus(valueTobeAdded)

  return value
}
