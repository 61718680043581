import styled, { css } from 'styled-components'
import { I_ContainerProps } from '../../../../shared/types'
import { I_ConnectWalletProps, I_HeaderProps, I_ImageProps, I_ImgProps, I_NavItemProps } from '../types'

const FontStyles = css`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
`

export const Container = styled.header<I_HeaderProps>`
  position: sticky;
  top: 0;
  z-index: 1;
`
export const HeaderContainer = styled.header<I_HeaderProps>`
  padding: 8px 24px;
  background: ${(props) => (props.color === 'secondary' ? props.theme.secondaryNav : props.theme.primaryNav)};
  z-index: 2;
  @media (max-width: 700px) {
    display: none;
  }
`
export const LogoContainer = styled.div`
  width: 108px;
  height: 32px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const ConnectWallet = styled.button<I_ConnectWalletProps>`
  cursor: pointer;
  background: #1c7eff;
  border: 1px solid #1c7eff;
  box-sizing: border-box;
  border-radius: ${(props) => (props.connected ? '0 6px 6px 0' : '6px')};
  padding: 12px 8px;
  text-decoration: none;
  margin: 0 10px 0 0;
  ${FontStyles}
  font-size: 16px;
  line-height: 16px;
  justify-content: center;
  align-items: center;
`
export const WalletIcon = styled.img`
  cursor: pointer;
  background: #1c7eff;
  border: 1px solid #1c7eff;
  box-sizing: border-box;
  border-radius: 24px 0 0 24px;
  height: 42px;
  justify-content: center;
  align-items: center;
`
export const NavContainer = styled.div`
  cursor: pointer;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
`

export const NavItems = styled.p<I_NavItemProps>`
  cursor: pointer;
  ${FontStyles}
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${(props) => (props.active ? '#fff' : '#FFFFFFb8')};
  padding: 8px;
  margin: 0 10px;
  ${(props) => (props.active ? `border-bottom: 4px solid ${props.theme.primaryTab};` : '')};
`

export const HeaderButton = styled.button`
  cursor: pointer;
  background: rgba(34, 45, 64, 0.5);
  border: 2px solid rgba(28, 126, 255, 0.3);
  box-sizing: border-box;
  border-radius: 8px;
  ${FontStyles}
  font-size: 16px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #ffffff;
  padding: 8px;
  margin-right: 16px;
  @media (max-width: 700px) {
    font-size: 12px;
    padding: 8px 4px;
  }
`

export const WalletContainer = styled.div`
  border: 1px solid rgba(28, 126, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  margin-top: 8px;
  cursor: pointer;

  :hover {
    border: 1px solid #1c7eff;
  }
`

export const NetworkImage = styled.img<I_ImageProps>`
  cursor: pointer;
  padding-bottom: 8px;
  border-bottom: ${(props) => (props.active ? '2px solid #1C7EFF' : '2px solid transparent')};
  margin-right: 16px;
`

export const HR = styled.hr`
  width: 100%;
  border: 1px solid rgba(28, 126, 255, 0.1);
  transform: translateY(-6px);
  margin: 0;
`

export const Img = styled.img<I_ImgProps>`
  margin-top: ${(props) => (props.margin_top ? props.margin_top + 'px' : '')};
  margin-left: ${(props) => (props.margin_left ? props.margin_left + 'px' : '')};
  margin-right: ${(props) => (props.margin_right ? props.margin_right + 'px' : '')};
  height: ${(props) => (props.ht ? props.ht : '')};
  width: ${(props) => (props.wd ? props.wd : '')};
  border-radius: ${(props) => props.borderRadius};
`

export const Balence = styled.span`
  margin-left: 4px;
  font-family: 'Poppins';
`

export const Disconnect = styled.span``

export const ThemeSwitcher = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 12px;
  svg {
    fill: none;
  }
`
export const MobileNav = styled.div<I_ContainerProps>`
  display: flex;
  justify-content: space-between;
  background: ${(props) =>
    props.background === 'primary'
      ? props.theme.primaryNav
      : props.background === 'secondary'
      ? props.theme.secondaryNav
      : props.background};
  mix-blend-mode: normal;
  box-shadow: ${(props) => props.boxShadow || ''};
  border-radius: 0;
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width || '100%'};
  padding: ${(props) => props.padding || '20px'};
  margin: ${(props) => props.margin || '0'};
  @media (min-width: 700px) {
    display: none;
  }
`

export const MobileSideNav = styled.div<any>`
  position: absolute;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  background: ${(props) =>
    props.background === 'primary'
      ? props.theme.primaryNav
      : props.background === 'secondary'
      ? props.theme.secondaryNav
      : props.background};
  height: calc(100vh - 80px);
  width: 60%;
  @media (min-width: 700px) {
    display: none;
  }
`

export const MobileBottomNav = styled.div<any>`
  position: absolute;
  bottom: 0px;
  right: 0;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  background: ${(props) =>
    props.background === 'primary'
      ? props.theme.primaryNav
      : props.background === 'secondary'
      ? props.theme.secondaryNav
      : props.background};
  @media (min-width: 700px) {
    display: none;
  }
`

export const MobileNavItems = styled.p<I_NavItemProps>`
  cursor: pointer;
  ${FontStyles}
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  color: ${(props) => (props.active ? '#fff' : '#FFFFFFb8')};
  padding: 8px;
  margin: 16px 10px;
  ${(props) => (props.active ? `border-bottom: 4px solid ${props.theme.primaryTab}; font-size:28px;` : '')};
`

export const BottomDrawer = styled.div<any>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #23262f;
  height: 235px;
  border-radius: 50px 50px 0 0;
  padding: 84px 35px 24px 35px;
  /* style={{position:'fixed', left:'0', bottom:'0', width:'100%', backgroundColor:'#23262F', height:'235px', borderRadius:'50px 50px 0 0', padding:'84px 35px 24px 35px'}} */
`
