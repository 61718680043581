/** custom module import of the contract instance */
import Web3 from 'web3'
import { rpcURL } from './Config'
import { weiToEth } from './Converstion'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {object} abi - abi of contract.
 * @param {string} poolAddress - address of token.
 */
const indexPrice = async (abi: object, poolAddress: string) => {
  try {
    const web3 = new Web3(rpcURL)

    //@ts-ignore
    const poolContractInstance = await new web3.eth.Contract(abi, poolAddress)
    const IndexPriceInWei = await poolContractInstance.methods.getBaseTokenIndexPrice(900).call()
    const IndexPrice = weiToEth(IndexPriceInWei, 18)
    return IndexPrice
  } catch (error) {
    console.log('error in index', error)
  }
}

export default indexPrice
