import React from "react";
import { useWeb3React } from "@web3-react/core";
import CustomModal from "../custom-modal";
import { Flex, SharedButton } from "../shared";
import { SharedCard } from "../sharedCard";
import { Text } from "../styles/typography";
import { WalletContainer } from "../../modules/app/components/navbar/style";
import { FlexBox } from "../flexBox";
import copy from "../../assets/images/copy.svg";
import { copyAccount } from "../../logic/helpers/utilities";
import Web3 from "web3";
import { rpcURL } from "../../blockchain/helper/Config";
const { ethereum }: any = window;

export const SwitchNetworkModal = (props: any) => {
  const { toggleModal } = props;
  const { account, activate, deactivate } = useWeb3React();
  const web3 = new Web3(rpcURL);

  //This is function is for switching network.
  const switchNetwork = async (connector: any) => {
    const chainId = 421614;
    const chainID = web3.utils.toHex(chainId?.toString());
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainID }],
      });
    } catch (switchError) {
      const error: any = switchError;
      if (error.code === 4902) {
        let chainID: any = 421614;
        chainID = web3.utils.toHex(chainID?.toString());
        ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chainID,
                chainName: `${"Mumbai Testnet"}`,
                nativeCurrency: {
                  name: "Matic",
                  symbol: "Matic",
                  decimals: 18,
                },
                rpcUrls: [rpcURL],
                blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
              },
            ],
          })
          .then(() => {
            activate(connector);
          })
          .catch((error: any) => {
            console.error(error);
          });
      }
      if (error.code === 4001) {
        deactivate();
      }
    }
  };

  return (
    <CustomModal
      show={true}
      toggleModal={toggleModal}
      heading="Switch Network"
      width="448px"
      padding="24px"
      styles={{ zIndex: "100000000" }}
    >
      <SharedCard padding="16px 24px" background="secondary">
        <Text
          fontSize={16}
          fontWeight={500}
          lineHeight={24}
          color="secondary"
          textAlign="left"
          margin="0 0 6px 0"
        >
          Connect With Metamask
        </Text>
        <WalletContainer onClick={() => copyAccount(account)}>
          <FlexBox>
            <Text
              fontSize={14}
              lineHeight={16}
              fontWeight={500}
              color="secondary"
            >
              {account
                ? //@ts-ignore
                  account?.slice(0, 3) +
                  "..." + //@ts-ignore
                  account?.substring(account.length - 5)
                : "Connect Wallet"}
            </Text>
            <img src={copy} />
          </FlexBox>
        </WalletContainer>
        <Text
          fontSize={11}
          fontWeight={500}
          lineHeight={16}
          color="secondary"
          textAlign="left"
          margin="34px 0 26px 0"
        >
          ODDZ Funds withdrawn from your account to your wallet will remain on
          the Arbitrium network. To move your assets to Ethereum mainnet, you
          will need to bridge them.
        </Text>
      </SharedCard>
      <Flex>
        <SharedButton
          color="#69CC8D"
          onClick={switchNetwork}
          padding="12px 52px"
          margin="24px 0 0 0"
        >
          Switch Network
        </SharedButton>
      </Flex>
    </CustomModal>
  );
};
