/*Library imports */
import { MaxUint256 } from '@ethersproject/constants'

/** custom module import of the contract instance */
import contractInstance from '../instances/contractInstance'
import { set_Transaction_Hash, set_Transaction_Type } from '../../logic/redux/actions'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {any} dispatch - useDispatch().
 * @param {string} account - user account address.
 * @param {any} library - wallet connection provider.
 * @param {object} tokenAbi - oddz contract abi. 
 * @param {number} tokenAddress - address of usdc token.
 * @param {number} oddzVault - address of oddz contract.
 
 
/** this function is used to call the liquidity contract on wallets */
const approveContract = async (
  dispatch: any,
  account: string,
  library: any,
  tokenAbi: object,
  tokenAddress: string,
  oddzVault: string
) => {
  try {
    if (account) {
      const tokenContractInstance = await contractInstance(account, library, tokenAbi, tokenAddress)

      const gasLimit = await tokenContractInstance.methods.approve(oddzVault, MaxUint256).estimateGas({
        from: account,
      })
      await tokenContractInstance.methods
        .approve(oddzVault, MaxUint256)
        .send({ from: account, gas: gasLimit + 1000 })
        .on('transactionHash', (e: string) => {
          dispatch(set_Transaction_Hash(e))
          dispatch(set_Transaction_Type('Approve'))
        })
    }
  } catch (error) {
    console.log('error in approve', error)
    //@ts-ignore
    if (error?.message.split('.')[1]) {
      //@ts-ignore
      return JSON.parse(error.message.split('.')[1])
    } else {
      return error
    }
  }
}

export default approveContract
