import { Hash, UrlPath, Uuid } from '../../../../shared/helpers/util'

export interface UserProps {
    user: string
}
export interface UserUrlProps extends UserProps {
    organization: Uuid
}

// Basic
export const rootPath: UrlPath<{}> = '/'
export const homePath: UrlPath<{}> = 'home'
export const tradePath: UrlPath<{}> = 'trade'
export const poolPath: UrlPath<{}> = 'pool'
export const positionPath: UrlPath<{}> = 'position'
export const historyPath: UrlPath<{}> = 'history'
// export const rewardsPath: UrlPath<{}> = 'rewards'
export const ordersPath: UrlPath<{}> = 'orders'
export const groupPath: UrlPath<{}> = 'group'
export const calculatorPath: UrlPath<{}> = 'calculator'
export const feesPath: UrlPath<{}> = 'fees'


export const groupPositionsPath: UrlPath<UserUrlProps> = `${homePath}/:id`