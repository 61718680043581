/** Library imports */
import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import BigNumber from "bignumber.js";

/** Custom imports */
import closePostionMarket from '../../../../../blockchain/helper/ClosePostionMarket'
import { quoteTokenAddress } from '../../../../../blockchain/helper/Config'
import { ethToWei, weiToEth } from '../../../../../blockchain/helper/Converstion'
import { DEADLINE, handleMaxSold, handleMinReceive } from '../../../../../blockchain/helper/handleMinMax'
import MarketPrice from '../../../../../blockchain/helper/marketPrice'
import OddzMarketContract from '../../../../../blockchain/helper/OddzMarketContract'
import swapRouterContract from '../../../../../blockchain/helper/SwapRouterContract'
import moveInContract from '../../../../../blockchain/helper/moveInContract'
import moveOutContract from '../../../../../blockchain/helper/moveOutContract';

import { Flex, SharedButton } from '../../../../../shared/shared'
import { Text } from '../../../../../shared/styles/typography'
import { Img, InfoTable } from './styles'
import PoolAbi from '../../../../../blockchain/abi/pool.json'
import { SharedCard } from '../../../../../shared/sharedCard'
import { useAppDispatch, useAppSelector } from '../../../../../logic/redux/hooks';
import { errorToast } from '../../../../../blockchain/helper/toasts';
import { set_Transaction_Success } from '../../../../../logic/redux/actions';

interface Props {
  symbol?: string
  positionData?: any
  tokenName?: string
  baseTokenAddr?: string
  poolAddress?: string
  setPositionTransactionSuccess?: any
  groupID: number
}

export const PositionDetails = (props: Props) => {

  const { symbol, positionData, tokenName, baseTokenAddr, poolAddress,
    setPositionTransactionSuccess, groupID } = props
  const { account, library, chainId } = useWeb3React()
  const dispatch = useAppDispatch()
  const { transactionSuccess } = useAppSelector((state) => state.graphSymbol)

  const [loading, setLoading] = useState<boolean>(false)

  /**This function returns the market fee */
  const getMarketFee = async () => {

    if (account && baseTokenAddr) {
      const marketData = await OddzMarketContract(
        account,
        library,
        baseTokenAddr
      );
      if (marketData) {
        return marketData[1] // Market Fee
      }
    }
  }

  /**This function returns the square root price */
  const getSqrtPrice = async () => {

    if (account && poolAddress) {
      const market_Price = await MarketPrice(account, library, PoolAbi['abi'], poolAddress)
      if (market_Price) {
        return market_Price[1] //sqrt Price
      }
    }
  }

  /**This function is for calculating quote token based on base token input */
  const quoteTokenCalculation = async (isLong: boolean) => {

    const marketFee = await getMarketFee()
    const sqrtPrice = await getSqrtPrice()
    if (account && chainId && baseTokenAddr && marketFee && sqrtPrice) {
      let amountInAddress: string
      let amountOutAddress: string
      if (isLong) {
        amountInAddress = baseTokenAddr
        amountOutAddress = quoteTokenAddress
      } else {
        amountInAddress = quoteTokenAddress
        amountOutAddress = baseTokenAddr
      }

      const baseTokenValue = Math.abs(positionData.swappedPositionSize)

      const result = await swapRouterContract(
        !isLong,
        amountInAddress, //Base token address
        amountOutAddress,
        marketFee,
        ethToWei(baseTokenValue.toString(), 18),
      )
      const resultInEth = weiToEth(parseFloat(result).toString(), 18)
      return resultInEth
    }

  }

  /**This function is for calling close position market contract */
  const closePostionMarketHandler = async () => {
    const sqrtPrice = await getSqrtPrice()
    if (account && positionData && sqrtPrice) {
      setLoading(true)
      const isLong = Number(positionData.swappedPositionSize) >= 0 ? true : false
      const quoteTokenAmount = await quoteTokenCalculation(isLong)
      const baseTokenAmount = new BigNumber(positionData.swappedPositionSize).absoluteValue().toFixed()

      let oppisiteBoundAmount: string
      const defaultSlippage = 40
      const largeBigValue = new BigNumber(Math.pow(2, 96))
      const minSqrtPrice = new BigNumber(new BigNumber(quoteTokenAmount) / new BigNumber(baseTokenAmount)).squareRoot()
      const minSqrtPriceLimit = new BigNumber(minSqrtPrice).multipliedBy(largeBigValue).toFixed()

      if (isLong) {
        oppisiteBoundAmount = ethToWei(handleMinReceive(quoteTokenAmount, defaultSlippage).toFixed(18), 18)
      } else {
        oppisiteBoundAmount = ethToWei(handleMaxSold(quoteTokenAmount, defaultSlippage).toFixed(18), 18)
      }

      const response = await closePostionMarket(
        dispatch,
        account,
        library,
        parseFloat(positionData.positionID), //postionId
        oppisiteBoundAmount, //oppositeBound amount
        minSqrtPriceLimit.split('.')[0] ? minSqrtPriceLimit.split('.')[0] : minSqrtPriceLimit, /** sqrt price limit */
        DEADLINE
      )

      if (response) { // it is a catch block error
        setLoading(false)
        if (response.message) {
          errorToast("Something went wrong: " + response.message)
        }
      }

    }
  }

  /**This useEffect is for reloading the data on successful transaction */
  useEffect(() => {

    if (transactionSuccess) {
      setPositionTransactionSuccess(true)
      setLoading(false)
      dispatch(set_Transaction_Success(false))
    }

  }, [transactionSuccess])

  /**This function is to call move in and move out contracts */
  const moveInMoveOutContract = async () => {
    if (account) {

      const isDefaultGroup = false
      const moveOut = parseFloat(positionData.groupID) > 0
      const collateralValue = new BigNumber(positionData.collateralForPosition).toFixed()

      if (!moveOut) {
        const response = await moveInContract(
          dispatch,
          account,
          library,
          parseFloat(positionData.positionID), //postionId
          parseFloat(positionData.groupID), //groupId,
          isDefaultGroup,
          Math.floor(positionData.collateralForPosition).toString()//collateralPosition
        )

        if (response) { // it is a catch block error
          // setLoading(false)
          if (response.message) {
            errorToast("Something went wrong: " + response.message)
          }
        }

      } else {

        const response = await moveOutContract(
          dispatch,
          account,
          library,
          parseFloat(positionData.positionID), //postionId
          Math.floor(positionData.collateralForPosition).toString()//collateralPosition
        )

        if (response) { // it is a catch block error
          // setLoading(false)
          if (response.message) {
            errorToast("Something went wrong: " + response.message)
          }
        }

      }
    }
  }

  return (
    <SharedCard border='1px solid #3A365F' margin='0 0 24px 0'>
      <Flex justifyContent='space-between' margin='0' >
        <Flex justifyContent='flex-start' alignItems="flex-start" gap='16px' width='80%'>
          <Img src={symbol ? symbol : ''} width="40px" />
          <Text fontSize={16} lineHeight={24} fontWeight={700} color="primary" margin="0 40px 0 0px">
            {positionData.swappedPositionSize ?
              Math.abs(positionData.swappedPositionSize).toFixed(4) : "0.00"} {tokenName} <br />
            ${positionData.swappedPositionSize ?
              Math.abs(parseFloat(positionData.swappedPositionSize) *
                parseFloat(positionData.marketPriceAfter)).toFixed(2)
              : "0.00"}
          </Text>
          <Text fontSize={16} lineHeight={24} fontWeight={700}
            color={Number(positionData.swappedPositionSize) >= 0 ? "#69CC8D" : '#E0476A'} margin='0 24px 0 0'>
            {positionData.swappedPositionSize && Number(positionData.swappedPositionSize) >= 0 ? "Long" : "Short"}
          </Text>

          <Text fontSize={16} lineHeight={24} fontWeight={500} color="primary" margin='0 24px 0 0'>
            Profit / Loss <br /> <span style={{
              fontFamily: 'Poppins', color:
                Number(positionData.realizedPnl) >= 0 ? '#69CC8D' : '#E0476A'
            }}>
              {positionData.realizedPnl ? Math.abs(positionData.realizedPnl).toFixed(4) : "0.00"}
            </span>
          </Text>
          <Text fontSize={16} lineHeight={24} fontWeight={500} color="primary">
            Avg. Open Price <br /> <span style={{ fontFamily: 'Poppins', color: '#69CC8D' }}>
              {positionData.marketPriceAfter ? Number(positionData.marketPriceAfter).toFixed(4) : "0.00"}
            </span>
          </Text>
        </Flex>
        <Text fontSize={16} lineHeight={24} fontWeight={700} color="#338BFF"
        // onClick={moveInMoveOutContract}
        >
          Move Position
        </Text>
      </Flex>
      <Flex alignItems="flex-end">
        <Flex
          flexDirection="column"
          margin="16px 0 0 50px"
          justifyContent="flex-start"
          alignItems="flex-start"
          width="80%"
        >
          <Text fontSize={16} lineHeight={24} fontWeight={700} color="primary" margin="0 0 16px 0">
            Position Info :
            <span style={{ fontSize: '16px', lineHeight: '24px', fontWeight: "700", color: "#338BFF", fontFamily: 'Poppins' }}>
              {groupID > 0 ? " Cross Margin" : " Isolated Margin"}
            </span>
          </Text>
          {groupID > 0 &&
            <Text fontSize={16} lineHeight={24} fontWeight={400} color="primary" margin="0 0 16px 0">
              Group ID: {groupID}
            </Text>
          }
          <InfoTable>
            <Text fontSize={16} lineHeight={24} fontWeight={400} color="primary" margin="0 16px 0 0">
              Liquidation Price: {" "}
              {positionData.liquidationPrice ? Number(positionData.liquidationPrice).toFixed(4) : '0.00'}
            </Text>
            <Text fontSize={16} lineHeight={24} fontWeight={400} color="primary">
              Account Margin Ratio: {" "}
              {positionData.marginRatio ? Number(positionData.marginRatio).toFixed(2) : "0.00"}%
            </Text>
            <Text fontSize={16} lineHeight={24} fontWeight={400} color="primary" margin="0 16px 0 0">
              Account Leverage: {" "}
              {positionData.leverage ? Math.abs(positionData.leverage).toFixed(2) : ' 0.00'}x
            </Text>
            <Text fontSize={16} lineHeight={24} fontWeight={400} color="primary">
              Collateral Value: {" "}
              {positionData.collateralForPosition ? Number(positionData.collateralForPosition).toFixed(4) : "0.00"}
            </Text>
          </InfoTable>
        </Flex>
        <Flex flexDirection='column'>
          <SharedButton color="#E0476A" margin="0 0 16px 0"
            onClick={closePostionMarketHandler}
            disabled={loading}
          >
            {loading ? "Loading..." : "Close Position"}
          </SharedButton>
        </Flex>
      </Flex>
    </SharedCard>
  )
}
