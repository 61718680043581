import styled from "styled-components";
import { I_ContainerProps } from "../types";

export const Container = styled.div<I_ContainerProps>`
    background: ${(props) => props.background === 'primary' ? props.theme.primary : props.background === 'secondary' ? props.theme.secondary :  props.background  };
    mix-blend-mode: normal;
    box-shadow:${(props) => props.boxShadow || ""};
    border-radius: 16px;
    height: ${(props) => props.height || 'auto'};
    width: ${(props) => props.width || '100%'};
    padding: ${(props) => props.padding || '28px'};
    margin: ${(props) => props.margin || '0'};
    border: ${(props) => props.border};
    ${(props) => props.scrollY && 'overflow-y: auto; ::-webkit-scrollbar-thumb{background-color:#1C7EFE;}'};
    ${(props) => props.scrollX && 'overflow-x: auto; ::-webkit-scrollbar-thumb{background-color:#1C7EFE;}'};
    ::-webkit-scrollbar-track {
    margin: 10px;
    /* margin-bottom: 10px; */
}
    @media(max-width:1065px){
        width: 100%;
    }
`;