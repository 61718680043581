import React, { useEffect, useState } from "react";
import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { Sub_Graph_Url } from "../../../../../blockchain/helper/Config";
import { fundingChartsQuery } from "../../../../../logic/graphQL/queries";
import moment from "moment";
import download from '../../../../../assets/images/download.svg'
import { FundingRateContainer, FundingRateWrapper } from "./styles";
import ReactLoading from 'react-loading'
import { Flex } from "../../../../../shared/shared";

export default function FundingCharts(props) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentRate, setCurrentRate] = useState('1h')
    const client = new ApolloClient({
        uri: Sub_Graph_Url,
        cache: new InMemoryCache(),
      })    

      const getFundingRates = (baseToken) => {
        if(currentRate === '1h'){
          setLoading(true)
          client
            .query({
              query: gql(fundingChartsQuery(baseToken, 1)),
            })
            .then((data) => {
              const newArr1 = data && data.data.oddzFundingHourDatas.map((v) => ({...v, FundingRate: parseFloat(v.fundingPaymentRate).toFixed(2), timestamp: moment.unix(v.timestamp).format('MMM Do YY HH:mm A')}))
              setData(newArr1)
              setLoading(false)
            })
            .catch((err) => {
              console.log(err)
              setLoading(false)
            })
        }
      
        if(currentRate === '8h'){
          setLoading(true)
          client
            .query({
              query: gql(fundingChartsQuery(baseToken, 8)),
            })
            .then((data) => {
              const newArr1 = data && data.data.oddzFundingEightHourDatas.map((v) => ({...v, FundingRate: parseFloat(v.fundingPaymentRate).toFixed(4), timestamp: moment.unix(v.timestamp).format('MMM Do YY HH:mm A')}))
              setData(newArr1)
              setLoading(false)
            })
            .catch((err) => {
              console.log(err)
              setLoading(false)
            })
        }
      
        if(currentRate === 'Annual'){
          setLoading(true)
          client
            .query({
              query: gql(fundingChartsQuery(baseToken, 365)),
            })
            .then((data) => {
              const newArr1 = data && data.data.oddzFundingAnnualDatas.map((v) => ({...v, FundingRate: Number(v.fundingPaymentRate).toFixed(4), timestamp: moment.unix(v.timestamp).format('MMM Do YY HH:mm A')}))
              setData(newArr1)
              setLoading(false)
            })
            .catch((err) => {
              console.log(err)
              setLoading(false)
            })
        }
      }
      /** getting the funding rates and storing it in data object */
      useEffect(() => {
        // setLoading(true)
        getFundingRates(props.baseToken, currentRate)
        // setLoading(false)
      }, [props.baseToken, currentRate])

        const styles = {
            fontFamily: "sans-serif",
            textAlign: "center",
            width: '100%'
        };
        const downloadFile = ( {data, fileName, fileType}) => {
            const blob = new Blob([data], { type: fileType });
            const a = document.createElement("a");
            a.download = fileName;
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
          };
          
          const exportToCsv = (e) => {
            e.preventDefault();
            let headers = ["Time,Funding Rate"];
          
            let usersCsv = data.reduce((acc, user) => {
              const { timestamp, FundingRate } = user;
              acc.push([timestamp, FundingRate].join(","));
              return acc;
            }, []);
          
            downloadFile({
              data: [...headers, ...usersCsv].join("\n"),
              fileName: "fundingRate.csv",
              fileType: "text/csv",
            });
          };
  
    const handle8hRate = () =>{
        setCurrentRate('8h');
    }

    const handle1hRate = () => {
      setCurrentRate('1h')
    }

    const handleAnnualRate = () => {
      setCurrentRate('Annual')
    }
  return (
  <div style={styles}>
    {data.length > 0 &&
    <div style={{display:'flex', justifyContent:'flex-end', padding:'0 24px 0 0'}}>
        <FundingRateWrapper>
          <FundingRateContainer active={currentRate === '1h'} onClick = {() => handle1hRate()}>1h Rate</FundingRateContainer>
          <FundingRateContainer active={currentRate === '8h'} onClick = {() => handle8hRate()}>8h Rate</FundingRateContainer>
          <FundingRateContainer active={currentRate === 'Annual'} onClick = {() => handleAnnualRate()}>Annualized</FundingRateContainer>
        </FundingRateWrapper>
        <img src={download} style={{height:'20px', width:'20px', background:'grey', borderRadius:'12px', padding:'4px', cursor:'pointer', marginLeft:'4px'}} onClick={exportToCsv}/>
    </div>
}
    {        
    !loading && data.length > 0 ? 
    <>
    <ResponsiveContainer width="90%" height={400}>
   <LineChart
            width={700}
            height={400}
            data={data}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Line type="monotone" dataKey="FundingRate" stroke="#8884d8" dot={false} />
            <XAxis dataKey="timestamp" />
            {currentRate === '1h' && <YAxis type="number" domain={['dataMin - 0.1', 'dataMax+0.1']} allowDataOverflow={true} />}
            {currentRate === '8h' && <YAxis type="number" domain={['dataMin - 1', 'dataMax+1']} allowDataOverflow={true} />}
            {currentRate === 'Annual' && <YAxis type="number" domain={['dataMin - 5000', 'dataMax+5000']} allowDataOverflow={true} />}
            <Tooltip
            wrapperStyle={{ backgroundColor: "red" }}
            labelStyle={{ color: "black" }}
            itemStyle={{ color: "black" }}
            formatter={function(value, name) {
              return `${value}`;
            }}
            labelFormatter={function(value) {
              return `Time: ${value}`;
            }}
          />          </LineChart>
          </ResponsiveContainer>
                </>
         : 
         <Flex>
           <ReactLoading type={'spinningBubbles'} color="#1c7eff" />
         </Flex>

      }
  </div>
  );
}
