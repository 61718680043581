import metamask from "../assets/images/wallets/Metamask.svg";
import coinbase from "../assets/images/wallets/Coinbase.svg";
import portis from "../assets/images/wallets/Portis.svg";
import walletConnect from "../assets/images/wallets/WalletConnect.svg";

export const sectionRender = [
  {
    text: "Portfolio",
    link: "/home",
  },
  {
    text: "Trade",
    link: "/trade",
  },
  {
    text: "Pool",
    link: "/pool",
  },
  {
    text: "Order",
    link: "/orders",
  },
  {
    text: "History",
    link: "/history",
  },
  {
    text: "Calculator",
    link: "/calculator",
  },
  {
    text: "Fees",
    link: "/fees",
  },
];

export const wallets = [
  {
    name:'Metamask',
    logo: `${metamask}`,
    type:1
  },
  {
    name:'Wallet Connect',
    logo: `${walletConnect}`,
    type:2
  },
  {
    name:'Coinbase Wallet',
    logo: `${coinbase}`,
    type:3
  },
  // {
  //   name:'Formatic',
  //   logo: `${formatic}`,
  //   type:4
  // },
  // {
  //   name:'Portis',
  //   logo: `${portis}`,
  //   type:5
  // },
];

export const feeStructure = [
  {
    tier:'I',
    balance:'>= 100',
    discount:'3%',
    volume:'< $1,000,000',
    maker:'0.070%',
    taker:'0.100%'
  },
  {
    tier:'II',
    balance:'>= 500',
    discount:'5%',
    volume:'≥ $1,000,000',
    maker:'0.060%',
    taker:'0.090%'
  },
  {
    tier:'III',
    balance:'>= 1000',
    discount:'10%',
    volume:'≥ $5,000,000',
    maker:'0.060%',
    taker:'0.080%'
  },  
  {
    tier:'IV',
    balance:'>= 5000',
    discount:'15%',
    volume:'≥ $10,000,000',
    maker:'0.050%',
    taker:'0.070%'
  },
  {
    tier:'V',
    balance:'>= 10000',
    discount:'15%',
    volume:'≥ $50,000,000',
    maker:'0.040%',
    taker:'0.060%'
  },
  {
    tier:'VI',
    balance:'>= 50000',
    discount:'20%',
    volume:'≥ 50000',
    maker:'0.030%',
    taker:'0.070%'
  },
]