export const marketTradeQuery = (currentTimeStamp:number, baseToken:any) => {
 return `query {
    oddzPositions(where:{baseToken: "${baseToken}" ,timestamp_gte: ${currentTimeStamp - (24*60*60)}, timestamp_lte:${currentTimeStamp}},orderDirection: desc, orderBy: timestamp) {
      timestamp
      marketPriceAfter
      swappedPositionSize
      }
    }`
}


export const volumeQuery = (currentTimeStamp: number, baseToken: string) => {
  return  `query{
   oddzPositions(where:{timestamp_gte: 1652355223, timestamp_lte:${currentTimeStamp},baseToken: "${baseToken}"}) {
     swappedPositionNotional
    }
  }
`
}
   
export const tvlQuery = (currentTimeStamp: number, baseToken: string) => {
  return  `query{
    oddzMarketsVolumeHistories(where:{timestamp_gte: ${currentTimeStamp - (24*60*60)}, timestamp_lte:${currentTimeStamp},baseToken: "${baseToken}", type:"position"}) {
      baseTokenVolume
    }
  }
`
}
   

  