import React from 'react'
import styled from 'styled-components'
import { screenSizes } from '../../styles/theme'
import { GridContainer } from './style'

interface I_GridProps {
    children?: React.ReactNode
}

export const BasicGrid = (props: I_GridProps) => {
    const { children } = props
    return (
        <GridContainer>
            {children}
        </GridContainer>
    )
}
export const FourColumns = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  /* padding: 0px 145px 0px 145px; */
  @media (min-width: ${screenSizes.S}px) {
    grid-gap: 25px;
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: ${screenSizes.L}px) {
    grid-gap: 12px;
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: ${screenSizes.XL}px) {
    grid-gap: 12px;
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const TwoColumns = styled.div<any>`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  /* padding:12px 60px; */
  padding: ${(props) => (props.padding ? props.padding : "0")};
  @media (min-width: ${screenSizes.M}px) {
    grid-gap: ${(props) => (props.gridGap ? props.gridGap : "25px")};
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${screenSizes.L}px) {
    grid-gap: ${(props) => (props.gridGap ? props.gridGap : "25px")};
    grid-template-columns: repeat(2, 1fr) !important;
  }
  @media (min-width: ${screenSizes.XL}px) {
    grid-gap: ${(props) => (props.gridGap ? props.gridGap : "25px")};
    grid-template-columns: repeat(2, 1fr);
  }
`;