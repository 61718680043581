import React, { useState } from "react";
import { ThemeProps, withTheme } from "styled-components";
import { Transactions } from "./components/Transactions";
import { Items, FlexContainer } from "./style";
import { FundingPayments } from "./components/FundingPayments";
import { DepositsWithdraw } from "./components/DepositsWithdraw";
import { MyOrders } from "../orders/components/MyOrders";

export const History: React.FC = withTheme((props: ThemeProps<any>) => {
  const types = ["Transactions","Funding Payments","Deposits/Withdrawals"];
  const [active, setActive] = useState(types[0]);

  return (
    <>
      <FlexContainer>
        {types.map((type, index) => (
          <Items
            key={index}
            active={active === type}
            onClick={() => {
              setActive(type);
            }}
          >
            {type}
          </Items>
        ))}
      </FlexContainer>
      {active === "Transactions" ? (
        // <Transactions />
        <MyOrders />
      ) : active === "Funding Payments" ? (
        <FundingPayments />
      ) : active === "Deposits/Withdrawals" ? (
        <DepositsWithdraw />
      ) : (
        ""
      )}
    </>
  );
});
export default History;
