
import { createAction } from '@reduxjs/toolkit'

export const set_graph_symbol = createAction<string>('SET_GRAPH_SYMBOL')

export const set_base_token = createAction<string>('ADD_BASE_TOKEN')

export const set_market_address = createAction<string>('SET_MARKET_ADDRESS')

export const set_Transaction_Hash= createAction<string>('SET_TRANSACTION_HASH')

export const set_Transaction_Success= createAction<boolean>('SET_TRANSACTION_SUCCESS')

export const set_Transaction_Type = createAction<string>('SET_TRANSACTION_TYPE')