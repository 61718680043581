/** custom module import of the contract instance */
//@ts-ignore
import contractInstance from '../instances/contractInstance'
import { oddzMarketAddress } from './Config'
import oddzMarketAbi from '../../blockchain/abi/oddzMarketAddress.json'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {string} account - wallet address of user.
 * @param {any} library - wallet connection provider.
 * @param {string} baseTokenAddress - address of base token.
 */
const OddzMarketContract = async (account: string, library: any, baseTokenAddress: string) => {
  try {
    const oddzMarketInstance = await contractInstance(account, library, oddzMarketAbi['abi'], oddzMarketAddress)
    const swapFee = await oddzMarketInstance.methods.fetchSwapFee(account, baseTokenAddress).call()

    const marketData = await oddzMarketInstance.methods.fetchMarketData(baseTokenAddress).call()
    return [swapFee, marketData.uniswapFee]
  } catch (error) {
    console.log('error in OddzMarketContract', error)
  }
}

export default OddzMarketContract
