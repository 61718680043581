/** custom module import of the contract instance */
//@ts-ignore
import contractInstance from '../instances/contractInstance'
import { oddzConfig } from './Config'
import oddzConfigAbi from '../../blockchain/abi/oddzConfig.json'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {string} account - wallet address of user.
 * @param {any} library - wallet connection provider.
 */

const OddzConfigContract = async (account: string, library: any) => {
  try {
    const oddzConfigInstance = await contractInstance(account, library, oddzConfigAbi['abi'], oddzConfig)

    const maxGroups = await oddzConfigInstance.methods.maxGroupsPerAccount().call()
    const maxPositions = await oddzConfigInstance.methods.maxPositionsPerGroup().call()

    return { maxPositions, maxGroups }
  } catch (error) {
    console.log('error in OddzConfigContract', error)
  }
}

export default OddzConfigContract
