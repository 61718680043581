/** custom module import of the contract instance */
import contractInstance from '../instances/contractInstance'
import { clearingHouseAddress } from './Config'
import clearHouseAbi from '../abi/clearhouse.json'
import { set_Transaction_Hash, set_Transaction_Type } from '../../logic/redux/actions'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {any} dispatch - useDispatch().
 * @param {string} account - user account address.
 * @param {any} library - wallet connection provider.
 * @param {object} oddzabi - oddz contract abi. 
 * @param {string} oddzVault - address of oddzVault.
 * @param {string} withdrawAmount - amount of usdc entered.
 * @param {number} tokenAddress - address of usdc token.
 
 
/** this function is used to call the liquidity contract on wallets */
const withdrawContract = async (
  dispatch: any,
  account: string,
  library: any,
  oddzabi: object,
  oddzVault: string,
  withdrawAmount: string,
  tokenAddress: string
) => {
  try {
    if (account) {
      const oddzVaultContractInstance = await contractInstance(account, library, oddzabi, oddzVault)

      const gasLimit = await oddzVaultContractInstance.methods
        .withdrawCollateral(tokenAddress, withdrawAmount)
        .estimateGas({
          from: account,
        })
      await oddzVaultContractInstance.methods
        .withdrawCollateral(tokenAddress, withdrawAmount)
        .send({ from: account, gas: gasLimit + 1000 })
        .on('transactionHash', (e: string) => {
            dispatch(set_Transaction_Hash(e))
            dispatch(set_Transaction_Type('withdraw'))
          
        })
    }
  } catch (error) {
    console.log('error in withdraw', error)
    //@ts-ignore
    if (error?.message.split('.')[1]) {
      //@ts-ignore
      return JSON.parse(error.message.split('.')[1])
    } else {
      return error
    }
  }
}

export default withdrawContract
