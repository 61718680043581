import React from 'react'

import { BottomContainer, ContainerWrapper, Input, Token, AbsPosition, GridContainer } from './style'
import { withTheme, ThemeProps } from 'styled-components'
import { useState } from 'react'
import CustomModal from '../../../shared/custom-modal'
import eth from '../../../assets/images/eth.svg'
import usdc from '../../../assets/images/usdc.svg'
import { Flex, SharedButton } from '../../../shared/shared'
import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'
import allowanceOfToken from '../../../blockchain/helper/Allowance'
import usdcabi from '../../../blockchain/abi/usdc.json'
import vaultabi from '../../../blockchain/abi/vault.json'
import contractInstance from '../../../blockchain/instances/contractInstance'
import { Text } from '../../../shared/styles/typography'
import { FlexRow } from '../../../shared/styles/styles'
import { oddzVault, usdcTokenAddress } from '../../../blockchain/helper/Config'
import { WalletConnectModal } from '../../../shared/wallet-connect/WalletConnectModal'
import { ethToWei, weiToEth } from '../../../blockchain/helper/Converstion'
import collateralBalance from '../../../blockchain/helper/CollateralBalance'
import DepositedBalance from '../../../blockchain/helper/depositedBalance'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { TwoColumns } from '../../../shared/basicGrid'
import newHere from '../../../assets/images/NewHere.svg'
import { errorToast } from '../../../blockchain/helper/toasts'
import { getreceipt } from '../../../blockchain/helper/getReceipt'
import { useAppDispatch, useAppSelector } from '../../../logic/redux/hooks'
import { set_Transaction_Success } from '../../../logic/redux/actions'
import depositContract from '../../../blockchain/helper/depositContract'
import approveContract from '../../../blockchain/helper/approveContract'
import withdrawContract from '../../../blockchain/helper/withdrawContract'
import { SharedCard } from '../../../shared/sharedCard'
import Positions from './position'

/** Home Page */
export const Home: React.FC = withTheme((props: ThemeProps<any>) => {
  const dispatch = useAppDispatch()
  const { transactionHash, transactionType, transactionSuccess } = useAppSelector((state) => state.graphSymbol)

  const [show, setShow] = useState('')
  const [loading, setLoading] = useState(false)
  // const [transactionHash, setTransactionHash] = useState<string>('')
  const [allowance, setAllowance] = useState<number>(0)
  const [inputAmount, setInputAmount] = useState<any>('')
  const [walletConnected, setWalletConnected] = useState(false)
  const [depositedAmt, setDepositedAmt] = useState<string>('')
  const [usdcBalance, setUsdcBalance] = useState<string>('')
  const [collateralBal, setCollateralBal] = useState<string>('0')

  const { account, library, chainId } = useWeb3React()

  const close = () => {
    setShow('')
    setInputAmount('')
  }

  /** This is function is used to get the balance of token */
  const balanceOfToken = async () => {
    if (account) {
      try {
        const tokenContractInstance = await contractInstance(account, library, usdcabi['abi'], usdcTokenAddress)
        console.log("from tokenBalance", tokenContractInstance, account)
        const decimals = await tokenContractInstance.methods.decimals().call()
        const tokenBalance = await tokenContractInstance.methods.balanceOf(account).call()
        console.log("from tokenBalance", tokenBalance)

        const amt = weiToEth(tokenBalance, decimals)
        setUsdcBalance(amt)
      } catch (error) {
        console.log("error from tokenBalance", error)
      }
    }
  }

  /** This is function is used to get the deposited balance */
  const getDepositedBalance = async () => {
    if (account) {
      const deposited = await DepositedBalance(
        account,
        library,
        vaultabi['abi'],
        oddzVault,
        usdcabi['abi'],
        usdcTokenAddress
      )
      if (deposited) {
        setDepositedAmt(deposited)
      }
    }
  }

  /** This is function is used to get the allownace of token */
  const allowanceOfTokenHandler = async () => {
    if (account) {
      const allowance = await allowanceOfToken(account, library, usdcabi['abi'], usdcTokenAddress)
      setAllowance(allowance)
    }
  }

  const approveHandler = async () => {

    if (account) {
      setLoading(true)

      const response = await approveContract(
        dispatch,
        account,
        library,
        usdcabi['abi'],
        usdcTokenAddress,
        oddzVault
      )

      if (response) {        // it is a catch block error
        setLoading(false)
        if (response.message) {
          errorToast("Something went wrong: " + response.message)
        }
      }

    }
  }

  /** function to handle deposit functionality */
  const depositHandler = async () => {
    if (Number(inputAmount) > Number(usdcBalance)) {
      errorToast('Insufficient Balance')
      return
    }
    if (account) {
      setLoading(true)
      const depositAmount = ethToWei(inputAmount, 8)
      // setTransferableAmount(Number(dipositAmount));
      const response = await depositContract(
        dispatch,
        account,
        library,
        vaultabi['abi'],
        oddzVault,
        depositAmount,
        usdcTokenAddress
      )

      if (response) {        // it is a catch block error
        setLoading(false)
        if (response.message) {
          errorToast("Something went wrong: " + response.message)
        }
      }
    }
  }

  /** function to handle withdraw functionality */
  const withdrawHandler = async () => {
    if (Number(inputAmount) > Number(collateralBal)) {
      errorToast('Insufficient Balance')
      return
    }
    if (account) {
      setLoading(true)
      const withdrawAmount = ethToWei(inputAmount, 8)

      const response = await withdrawContract(
        dispatch,
        account,
        library,
        vaultabi['abi'],
        oddzVault,
        withdrawAmount,
        usdcTokenAddress
      )

      if (response) {        // it is a catch block error
        setLoading(false)
        if (response.message) {
          errorToast("Something went wrong: " + response.message)
        }
      }
    }
  }

  useEffect(() => {

    if (transactionSuccess) {
      balanceOfToken()
      allowanceOfTokenHandler()
      getDepositedBalance()
      getWithdrawBalance()
      setLoading(false)
      if (transactionType !== 'Approve') {
        close()
      }
      dispatch(set_Transaction_Success(false))
    }

  }, [transactionSuccess])

  useEffect(() => {

    if (transactionHash && transactionType) {
      if (transactionType !== 'Approve') {
        setInputAmount('')
      }
      const interval = setInterval(() => {
        getreceipt(transactionHash, dispatch, transactionType)
      }, 3000)
      return () => clearInterval(interval)
    }

  }, [transactionHash, dispatch, transactionType])

  const getWithdrawBalance = async () => {
    if (account) {
      const Balance = await collateralBalance(
        account,
        library,
        vaultabi['abi'],
        oddzVault,
        usdcabi['abi'],
        usdcTokenAddress
      )
      setCollateralBal(Balance)
    }
  }

  const inputHandler = (e: any) => {
    const approveAmount = e.target.value
    setInputAmount(approveAmount)
  }

  useEffect(() => {
    console.log("account" , account)
    if (account) {
      console.log("account" , account)
      balanceOfToken()
      allowanceOfTokenHandler()
      getDepositedBalance()
      getWithdrawBalance()
    }
  }, [account, chainId, loading, show])

  return (
    <GridContainer>
      <SharedCard background="linear-gradient(174.5deg, #294799 5.66%, #2B3976 31.95%, #2D2C58 96.49%)" width="100%">
        <Flex justifyContent="space-around" gap="16px">
          <Text fontSize={24} lineHeight={36} textAlign="left" fontWeight={700} color="#FFFFFF" margin="0 0 0 0">
            {account ? account.slice(0, 6) + '. . . .' + account?.substring(account.length - 5) : 'Not Connected'}
          </Text>
          <FlexRow gap="44px" justifyContent="flex-start">
            <Text fontWeight={500} fontSize={16} lineHeight={24} color="#FFFFFF" textAlign="left">
              Account Value <br /> ${account && depositedAmt ? parseFloat(depositedAmt).toFixed(4) : '0.00'}
            </Text>
            <Text fontWeight={500} fontSize={16} lineHeight={24} color="#FFFFFF" textAlign="left">
              Free Collateral <br /> $ {account && collateralBal ? parseFloat(collateralBal).toFixed(4) : '0.00'}
            </Text>
          </FlexRow>
          {account ? (
            <FlexRow gap="12px" justifyContent="flex-end">
              <SharedButton disabled={!account} onClick={() => setShow('deposit')} padding="12px 36px">
                Deposit
              </SharedButton>
              <SharedButton disabled={!account} onClick={() => setShow('withdraw')} padding="12px 36px">
                Withdraw
              </SharedButton>
            </FlexRow>
          ) : (
            <SharedButton onClick={() => setShow('connect')} padding="12px 52px">
              Connect Wallet
            </SharedButton>
          )}
        </Flex>
      </SharedCard>
      <Positions />
      {show === 'connect' ? (
        <WalletConnectModal show={show} setWalletConnected={setWalletConnected} toggleModal={() => close()} />
      ) : (
        ''
      )}
      <CustomModal
        show={show === 'deposit' ? true : false}
        toggleModal={() => close()}
        heading="Deposit"
        background="primary"
        width="448px"
      >
        <ContainerWrapper>
          <FlexRow>
            <Text
              fontWeight={500}
              fontSize={16}
              lineHeight={18}
              color="primary"
              textAlign="left"
              margin="12px 0 0 18px"
            >
              Amount (USDC)
            </Text>
            <Text fontWeight={500} fontSize={16} lineHeight={18} color="secondary" margin="12px 18px 0 0">
              Balance :{' '}
              <span style={{ color: '#69CC8D', fontFamily: 'Poppins' }}>
                ${usdcBalance ? parseFloat(usdcBalance).toFixed(4) : '0.00'}
              </span>
            </Text>
          </FlexRow>
          <div>
            <Input
              step="0.01"
              onChange={inputHandler}
              type="number"
              min={0}
              placeholder="Enter Amount"
              onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              value={inputAmount}
            />
            <AbsPosition top="124px" left="348px">
              <SharedButton
                color="#BEDAFF"
                textColor="#000"
                padding="4px 10px"
                onClick={() => setInputAmount(usdcBalance.toString())}
              >
                Max
              </SharedButton>
            </AbsPosition>
          </div>
          <BottomContainer>
            <Text
              fontWeight={500}
              fontSize={12}
              lineHeight={16}
              color="secondary"
              textAlign="left"
              padding="0 16px 0 0"
            >
              You'll need USDC for trading and some ETH to pay for the L2 gas fees.{' '}
            </Text>
            <div
              style={{
                marginTop: '34px',
                marginBottom: '27px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <a target="_blank" href="https://bridge.connext.network/USDC-from-ethereum-to-arbitrum">
                <Token>
                  <FlexRow justifyContent="flex-start">
                    <img style={{ marginRight: '4px', width: '20px' }} src={usdc} alt="" />
                    <Text fontWeight={700} fontSize={16} lineHeight={24} color="primary" textAlign="left" padding="0 16px 0 0" >
                    Oddz USDC
                    </Text>
                  </FlexRow>
                </Token>
              </a>
              <a target="_blank" href="https://bridge.connext.network/USDC-from-ethereum-to-arbitrum">
                <Token>
                  <FlexRow justifyContent="flex-start">
                    <img style={{ marginRight: '4px', width: '20px' }} src={eth} alt="" />
                    <Text fontWeight={700} fontSize={16} lineHeight={24} color="primary" textAlign="left" padding="0 16px 0 0" >
                    Oddz ETH
                  </Text>
                  </FlexRow>
                </Token>
              </a>
            </div>
            {allowance > 0 ? (
              <SharedButton
                color="#69CC8D"
                disabled={loading || Number(usdcBalance) < 0 || !inputAmount || Number(inputAmount) <= 0}
                onClick={allowance > 0 && Number(usdcBalance) > 0 ? depositHandler : null}
              >
                {loading ? 'Loading...' : 'Deposit'}
              </SharedButton>
            ) : (
              <SharedButton
                color="#69CC8D"
                disabled={loading || !account || !inputAmount || Number(inputAmount) <= 0}
                onClick={allowance > 0 ? null : approveHandler}
              >
                {loading ? 'Loading...' : 'Approve'}
              </SharedButton>
            )}
          </BottomContainer>
        </ContainerWrapper>
      </CustomModal>
      <CustomModal
        show={show === 'withdraw' ? true : false}
        toggleModal={() => close()}
        heading="Withdraw"
        background="primary"
        width="448px"
      >
        <ContainerWrapper>
          <FlexRow>
            <Text
              fontWeight={500}
              fontSize={16}
              lineHeight={18}
              color="primary"
              textAlign="left"
              margin="12px 0 0 18px"
            >
              Amount (USDC)
            </Text>
            <Text fontWeight={500} fontSize={16} lineHeight={18} color="secondary" margin="12px 18px 0 0">
              Balance :{' '}
              <span style={{ color: '#69CC8D', fontFamily: 'Poppins' }}>
                ${collateralBal ? parseFloat(collateralBal).toFixed(4) : '0.00'}
              </span>
            </Text>
          </FlexRow>
          <Input
            step="0.01"
            onChange={inputHandler}
            type="number"
            min={0}
            placeholder="Enter Amount"
            onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            value={inputAmount}
          />
          <AbsPosition top="124px" left="348px">
            <SharedButton
              color="#BEDAFF"
              textColor="#000"
              padding="4px 10px"
              onClick={() => setInputAmount(collateralBal)}
            >
              Max
            </SharedButton>
          </AbsPosition>
          <BottomContainer>
            <Text fontWeight={500} fontSize={11} lineHeight={16} color="secondary" textAlign="left">
              ODDZ Funds withdrawn from your account to your wallet will remain on the Arbitrium network. To move your
              assets to Ethereum mainnet, you will need to bridge them.
            </Text>
            <SharedButton
              color="#69CC8D"
              disabled={loading || !inputAmount || Number(inputAmount) <= 0}
              onClick={inputAmount > 0 && Number(collateralBal) > 0 ? withdrawHandler : null}
            >
              {loading ? 'Loading...' : 'Withdraw'}
            </SharedButton>
          </BottomContainer>
        </ContainerWrapper>
      </CustomModal>
      <ToastContainer position="top-center" style={{ zIndex: '10000' }} />
    </GridContainer>
  )
})
