import styled, { css } from 'styled-components'

const FontStyles = css`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
`

export const Img = styled.img`
width:40px;
object-fit:contain;
`

export const InfoTable = styled.div`
display:Flex;
width:100%;
flex-wrap:wrap;
justify-content:flex-start;
>*{
  display:flex;
  justify-content:flex-start;
  margin-bottom:16px;
  width:45%;
}
`

export const Input = styled.input`
  margin-top: 10px;
  width: 100%;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 6px;
  background: ${(props) => props.theme.primary};
  border-radius: 8px;
  ${FontStyles}
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 10px 8px;
  color: ${(props) => props.theme.primaryText};
`

interface PositionProps {
  top: string
  left: string
}

export const AbsPosition = styled.div<PositionProps>`
  position: relative;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
`

interface ImageProps {
  active?: boolean
}

export const ArrowImage = styled.img<ImageProps>`
  ${(props) =>
    props.active
      ? '-webkit-transform:rotate(180deg);  -moz-transform: rotate(180deg);  -ms-transform: rotate(180deg);  -o-transform: rotate(180deg);  transform: rotate(180deg);'
      : ''}
`

export const PositionTabWrapper = styled.div`
  margin-top:30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding:22px 16px;
  width:100%;
  /* background: #202129; */
  border: 1px solid #3A365F;
  border-radius: 16px;
  height: 386px;
`
export const FlexContainer = styled.div`
  display : flex ;
  justify-content :flex-start;
  align-items : center;
  /* border-bottom: 1px solid blue; */
`;

export const PositionWrapper = styled.div`
  border-bottom: 1px solid #3a365f;
`;

export const ScrollableFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  /* align-items:center; */
  margin: 16px 0 8px 0;
  max-height: 340px;
  overflow-y: auto;
`;

export const DropdownValues = styled.div`
cursor: pointer;
position: relative;
width: 274px;
padding: 10px 16px;
display: flex;
z-index:2;
justify-content: space-between;
  /* flex-direction: column; */
  border-radius: 8px;
  background: ${(props) => props.theme.primary};
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

export const TokenContainer = styled.div`
  padding: 28px 0px;
  display: flex;
  gap: 8px;
  /* justify-content : center; */
  align-items: center;
  /* flex-flow : row wrap; */
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TotalContainer = styled.div<any>`
    background: ${(props) => props.background === 'primary' ? props.theme.primary : props.background === 'secondary' ? props.theme.secondary :  props.background  };
    mix-blend-mode: normal;
    border: 1px solid #374151;
    border-radius: 0 0 16px 16px;
    height: ${(props) => props.height || 'auto'};
    width: ${(props) => props.width || '100%'};
    padding: ${(props) => props.padding || '28px'};
    margin: ${(props) => props.margin || '0'};
`;

export const FundingRateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: grey;
  border-radius: 16px;
`;

export const FundingRateContainer = styled.div<any>`
  padding: 2px 8px;
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 400;
  color: white;
  background: grey;
  border-radius: 16px;
  cursor: pointer;
  ${(props) => props.active && 'background:#1C7EFF' }
`;