import styled from "styled-components";
import { I_ModalBodyProps, I_ModalProps } from "../../../types";

export const ModelHead = styled.div`
  color: ${(props) => props.theme.primaryText};
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ModalBody = styled.div<I_ModalBodyProps>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const ModalContent = styled.div<I_ModalProps>`
  padding: ${(props) => props.padding ? props.padding : '20px' };
  display: inline-block;
  border-radius: 8px;
  margin: 0 auto;
  border: none;
  border-radius: 4px;
  overflow: visible;
  position: absolute;
  left: 78%;
  top: 60px;
  max-height: 100%;
  width: ${(props) => props.width ? props.width : '320px'};
    background: ${(props) => props.background === 'primary' ? props.theme.primary : props.background === 'secondary' ? props.theme.secondary :  '#222D40' };
    box-shadow: ${(props) => props.boxShadow ? props.boxShadow : '0px 4px 12px rgba(218, 220, 224, 0.25)' };   
    border-radius: 12px;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
`;

export const Close = styled.img`
  cursor: pointer;
  margin-right: 0;
`;
