import React, { useEffect, useState } from "react";

import axios from "axios";

import { TokenCard } from "../../pool/components/TokenCard";
import { SearchInput, SearchWrapper } from "../../pool/styles";
import {
  API_URL,
  vBtc_Address,
  vEth_Address,
  VETH_USD_PairAddress,
  vLink_Address,
  vMatic_Address,
  vSol_Address,
} from "../../../../../blockchain/helper/Config";
import { TokenContainer } from "./styles";
import { useWeb3React } from "@web3-react/core";
import { useAppSelector } from "../../../../../logic/redux/hooks";

interface Props {
  setBaseTokenAddr?: any;
  setPoolAddr?: any;
  setSelectedTokenName?: any;
  setSelectedLogo?: any;
  setPositionTransactionSuccess?: any;
  setMarketPrice?: any;
}

export const PoolDetails = (props: Props) => {
  const {
    setBaseTokenAddr,
    setPoolAddr,
    setSelectedTokenName,
    setSelectedLogo,
    setMarketPrice,
  } = props;
  const { transactionSuccess } = useAppSelector((state) => state.graphSymbol);

  const [poolData, setPoolData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedPool, setSelectedPool] = useState<string>(
    VETH_USD_PairAddress
  );
  const [selectedToken, setSelectedToken] = useState<string>("ETH");
  // const [marketPrice, setMarketPrice] = useState<number>(0)
  const [selectedTokenLogo, setSelectedTokenLogo] = useState<string>("");

  const { account, library, chainId } = useWeb3React();

  /**This useffect is to set BaseToken and Pool addresses */
  useEffect(() => {
    if (selectedToken === "ETH") {
      setBaseTokenAddr(vEth_Address);
      setPoolAddr(selectedPool);
      setSelectedTokenName(selectedToken);
      setSelectedLogo(selectedTokenLogo);
    } else if (selectedToken === "BTC") {
      setBaseTokenAddr(vBtc_Address);
      setPoolAddr(selectedPool);
      setSelectedTokenName(selectedToken);
      setSelectedLogo(selectedTokenLogo);
    } else if (selectedToken === "MATIC") {
      setBaseTokenAddr(vMatic_Address);
      setPoolAddr(selectedPool);
      setSelectedTokenName(selectedToken);
      setSelectedLogo(selectedTokenLogo);
    } else if (selectedToken === "LINK") {
      setBaseTokenAddr(vLink_Address);
      setPoolAddr(selectedPool);
      setSelectedTokenName(selectedToken);
      setSelectedLogo(selectedTokenLogo);
    } else if (selectedToken === "SOL") {
      setBaseTokenAddr(vSol_Address);
      setPoolAddr(selectedPool);
      setSelectedTokenName(selectedToken);
      setSelectedLogo(selectedTokenLogo);
    }
  }, [selectedToken]);

  const getMarkets = () => {
    axios
      .get(API_URL + "market/getMarkets", {
        params: {
          wallet_address: `${account ? account : ""}`,
        },
      })
      .then((res) => {
        setPoolData(res.data.data);
      })
      .catch((error) => {
        // alert(error)
      });
  };

  useEffect(() => {
    getMarkets();
  }, [account, transactionSuccess]);
  /**Getting searched Pools Info from the APIs */
  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setSearchValue(e.target.value);
      if (e.target.value) {
        axios
          .get(API_URL + "market/search_markets", {
            params: {
              market_name: `${e.target.value}`,
              wallet_address: `${account}`,
            },
          })
          .then((res) => {
            setPoolData(res.data.data);
          })
          .catch((error) => {
            // alert(error);
          });
      } else {
        getMarkets();
      }
    } catch (error) {
      return;
    }
  };

  return (
    <TokenContainer>
      <SearchWrapper>
        <SearchInput
          // type="search"
          className="search"
          placeholder="Search Token"
          value={searchValue}
          onChange={searchHandler}
        />
      </SearchWrapper>
      {poolData.map((type: any) => (
        <TokenCard
          logo={type.marketIcon}
          token={type.marketNameMod}
          value={type.marketFixedPrice}
          marketGraphSymbol={type.marketGraphSymbol}
          marketAddress={type.marketAddress}
          position={"up"}
          setSelectedToken={setSelectedToken}
          poolAddress={type.marketAddress}
          active={type.marketNameMod === selectedToken}
          setSelectedTokenLogo={setSelectedTokenLogo}
          setSelectedPool={setSelectedPool}
          setMarketPrice={setMarketPrice}
        />
      ))}
    </TokenContainer>
  );
};
