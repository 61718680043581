/** custom module import of the contract instance */
//@ts-ignore
import contractInstance from '../instances/contractInstance'
import { orderManager } from './Config'
import orderManagerAbi from '../../blockchain/abi/orderManager.json'

import { ethers, providers } from 'ethers'
import { weiToEth } from './Converstion'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {string} account - wallet address of user.
 * @param {any} library - wallet connection provider.
 * @param {object} abi - abi of contract.
 * @param {string} baseTokenAddress - address of token.
 */
const getAmountsForLiquidity = async (
  account: string,
  library: any,
  baseTokenAddress: string, //Base token address
  orderId: any,
  isBaseToken: boolean
) => {
  try {
    const orderManagerInstance = await contractInstance(account, library, orderManagerAbi['abi'], orderManager)

    const amount = await orderManagerInstance.methods
      .getCurrentTotalTokenAmountInPoolByOrderId(orderId, isBaseToken)
      .call()

    return weiToEth(amount, 18)
  } catch (error) {
    console.log('error in orderManagerInstance', error)
  }
}

export default getAmountsForLiquidity
