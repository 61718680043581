/**
 * Represent creation of contract instance
 * @constructor
 * @param {string} account - wallet address of user.
 * @param {any} provider - wallet connection provider.
 * @param {object} abi - abi of contract.
 * @param {string} tokenAddress - address of token.
 */
const contractInstance = async (account: string, provider: any, abi: object, tokenAddress: string) => {
  if (account) {
    const sentInstance = await new provider.eth.Contract(
      abi,
      tokenAddress
    );
    return sentInstance;
  }
};
export default contractInstance;
