import styled, { css } from "styled-components";
interface ButtonProps {
  disabled?: boolean;
  width?: string;
  color: string;
  padding?: string;
  onClick?: any;
  margin?: string;
  active?: boolean;
  textColor?: string;
}
interface RadioProps {
  active: boolean;
}

const FontStyles = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
`;

export const Wrapper = styled.div<any>`
  background: ${(props) => props.theme.secondary};
  border: 1px solid #1c7eff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #1c1d29;
  padding: 6px 16px 8px 13px;
  min-width: ${(props) => props.width ? props.width : '162px'};
  mix-blend-mode: normal;
  border-radius: 12px;
  max-height: 56px;
  ${(props) => props.active ? 'background:#1C7EFF;': ''};
  cursor: pointer;
  /* background:#1C7EFF; */
  /* border: 10px solid; */
  /* border-image-slice: 1;
  border-width: 5px; */
  /* border-image-source:  radial-gradient(rgba(79, 110, 152, 0), rgba(28, 126, 255, 1)); */
  /* width: 178px; */
`;

export const Text = styled.p<any>`
  font-family: "Poppins";
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: "center";
  color: ${(props) => props.active ? '#fff' :  props.theme.primaryText};
  margin: 0;
  margin-left: 5px;
  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: "center";
  }
  /* margin-left: 8px; */
`;

export const Img = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const Image = styled.img`
  margin: 16px 0 0 24px;
  cursor: pointer;
`;

export const Type = styled.div`
  display: flex;
  padding: 1px;
  background: ${(props) => props.theme.primary};
  border: 2px solid transparent;
  border-radius: 8px;
  max-width: 400px;
`;

export const TypeButton = styled.button<ButtonProps>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${FontStyles}
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${(props) =>
    props.textColor ? props.textColor : props.theme.primaryText};
  background: ${(props) =>
    props.color && props.active ? props.color : "transparent"};
  mix-blend-mode: normal;
  border: 1.5px solid
    ${(props) => (props.color && props.active ? props.color : "transparent")};
  box-sizing: border-box;
  border-radius: 8px;
  padding: ${(props) => (props.padding ? props.padding : "4px")};
  width: ${(props) => (props.width ? props.width : "198px")};
  margin: ${(props) => props.margin};
`;

export const Block = styled.div`
  padding: 6px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border: 1px solid ${(props) => props.theme.secondaryText};
  border-radius: 8px;
`;

export const Dropdown = styled.div`
  margin-top: 8px;
  cursor: pointer;
  padding: 10px 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.primary};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
`;

export const DropDownContainer = styled.div`
  /* margin-top: 8px; */
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.primary};  
  .select_box{
  width: 100%;
  overflow: hidden;
  border: 1px solid #000;
  position: relative;
  padding: 8px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 8px 12px;
  color: ${(props) => props.theme.primaryText};
}
.select_box select{
  width: 100%;
  border: 0;
  position: relative;
  z-index: 99;
  background: none;
  border:none;
  outline: none;
  color: ${(props) => props.theme.primaryText};
  font-family: 'Poppins';
  background: transparent;
}

.select_box select option { 
  color: black; 
/* background:red;  */
}

`;

export const DropdownValues = styled.div`
cursor: pointer;
position: absolute;
width: 79%;
padding: 10px 19px;
display: flex;
justify-content: space-between;
  /* flex-direction: column; */
  border-radius: 8px;
  background: ${(props) => props.theme.primary};
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

export const RadioButton = styled.div<RadioProps>`
  margin: 16px 0 0 6px;
  height: 20px;
  width: 20px;
  background: ${(props) => (props.active ? "#2E73E4" : props.theme.primary)};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
`;

export const InputBox = styled.input`
  padding: 6px;
  ${FontStyles};
  color: #ffffffb8;
  background: ${(props) => props.theme.primary};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  width:100%;
  :focus {
    outline: none;
    border: 1px solid #2e73e4;
  }
`;

export const LeverageBlock = styled.div`
background:  ${(props) => props.theme.inputElement.primary};
padding:14px;
margin-bottom: 12px;
border: 1px solid #374151;
box-shadow: inset 0px 2px 4px #1C1D29;
border-radius: 8px;
display: flex;
justify-content: space-between;
width:170px;
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
`;


