import React from 'react'
import { ThemeProps, withTheme } from 'styled-components'
import { Text } from '../../../../shared/styles/typography'
import { MyOrders } from './components/MyOrders'

export const Orders: React.FC = withTheme((props: ThemeProps<any>) => {
  return (
    <>
      <Text fontSize={24} fontWeight={500} lineHeight={36} color="primary" margin="27px 0 16px 62px">
        My Order
      </Text>
      <MyOrders />
    </>
  )
})
export default Orders
