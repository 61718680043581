import styled, { css } from "styled-components";
import { I_ContainerProps } from "../../../../shared/types";
import dropImage from '../../../../assets/images/downArrow.svg'

export const DropDownsDiv = styled.div`
display: flex;
justify-content:space-between;
align-items:center;
width:50%;
position:absolute;
top:-38px;
left:0;
padding:0 40px;
`

export const Input = styled.input`
width:100%;
font-family: 'Poppins';
margin-right:10px;
background:transparent;
border:none;
border-bottom:1px solid rgba(55, 65, 81, 0.5);
color:${(props) => props.theme.primaryText};
font-size:14px;
:focus{
    outline:none
}
::placeholder{
    color:#9CA3AF;
}
`
export const Container = styled.div<I_ContainerProps>`
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* align-items: center;  */
    background: ${(props) => props.background === 'primary' ? props.theme.primary : props.background === 'secondary' ? props.theme.secondary :  props.background  };
    mix-blend-mode: normal;
    box-shadow:${(props) => props.boxShadow || ""};
    border-radius: 16px;
    height: ${(props) => props.height || 'auto'};
    width: ${(props) => props.width || '100%'};
    padding: ${(props) => props.padding || '28px'};
    margin: ${(props) => props.margin || '0'};
    border: ${(props) => props.border};
    ${(props) => props.scrollY && 'overflow-y: auto; ::-webkit-scrollbar{border:1px solid #27344E;} ::-webkit-scrollbar-thumb{background-color:#1C7EFE;}'};
    ${(props) => props.scrollX && 'overflow-x: auto; ::-webkit-scrollbar{border:1px solid #27344E;} ::-webkit-scrollbar-thumb{background-color:#1C7EFE;}'};
    @media(max-width:700px){
        width: 90%;
    }
`;

export const OptionWrapper = styled.div<any>`
select, option {
    background-color: transparent;
    border: none;
    color:  rgba(255, 255, 255, 0.8);
    font-family: 'Poppins';
    font-size: 12px;
    line-height: 18px;
    outline: none;
    font-weight: 400;
}
option{
    color: black;
}

.dropbtn {
    display: flex;
  background-color: transparent;
  color: ${(props) =>  props.theme.secondaryText}; 
  /* padding: 16px; */
  font-size: 12px;
  font-family: 'Poppins';
  border: none;
  cursor: pointer;
  padding: 0;
}
.dropbtn span.icon {
    background: url(${dropImage}) no-repeat;
    float: right;
    width: 10px;
    height: 10px;
    text-align: center;
    margin: 6px 0 0 6px;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: transparent;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: ${(props) => props.active ? 'block' : 'none'};
  position: absolute;
  background-color: ${(props) =>  props.theme.secondary} ;
  min-width: 84px;
  overflow: auto;
  font-family: 'Poppins';
  color: ${(props) =>  props.theme.secondaryText}; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  font-size: 12px;
}

.dropdown-content a {
color: ${(props) =>  props.theme.secondaryText}; 
  padding: 12px 16px;
  font-family: 'Poppins';

  text-decoration: none;
  display: block;
}
.dropdown a:hover {background-color: ${(props) =>  props.theme.secondaryNav}}

`;

export const LeverageBlock = styled.div`
background:  ${(props) => props.theme.inputElement.primary};
padding:14px;
margin-bottom: 12px;
border: 1px solid #374151;
box-shadow: inset 0px 2px 4px #1C1D29;
border-radius: 24px;
display: flex;
justify-content: space-between;
width:80%;
`;
