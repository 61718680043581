/** custom module import of the contract instance */
//@ts-ignore
import contractInstance from '../instances/contractInstance'
import { rpcURL, uniSwapRouter } from './Config'
import uniSwapRouterAbi from '../../blockchain/abi/swapRouter.json'

import { ethers, providers } from 'ethers'
import { selectRpc } from '../wallethelper/Useauth'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {boolean} isBaseToken - base token or not.
 * @param {string} amountInTokenAddress - token address of amount in.
 * @param {string} amountOutTokenAddress - token address of amount out.
 * @param {number} fee - required fee for contract(uniswap fee).
 * @param {string} enteredAmount - entered amount by user.
 */
const swapRouterContract = async (
  isBaseToken: boolean,
  amountInTokenAddress: string, //Base token address
  amountOutTokenAddress: string,
  fee: number,
  enteredAmount: string
) => {
  try {
    let JRPC_infuraRopstenProvider = new ethers.providers.JsonRpcProvider(rpcURL)

    const signer = new ethers.Wallet(
      '475888ffe7037758c70217440b6a4bd3abc270a5f91a8cb4cfe351e1c2d258e7',
      JRPC_infuraRopstenProvider
    )

    const contract = new ethers.Contract(uniSwapRouter, uniSwapRouterAbi['abi'], signer)

    console.log('isBaseToken', isBaseToken, amountInTokenAddress, amountOutTokenAddress, fee, enteredAmount)
    if (isBaseToken) {
      const response = await contract.callStatic.quoteExactOutputSingle(
        amountInTokenAddress,
        amountOutTokenAddress,
        fee,
        enteredAmount,
        '0'
      )
      return response
    } else {
      const response = await contract.callStatic.quoteExactInputSingle(
        amountInTokenAddress,
        amountOutTokenAddress,
        fee,
        enteredAmount,
        '0'
      )
      return response
    }
  } catch (error) {
    console.log('error in swapRouterContract', error)
  }
}

export default swapRouterContract
