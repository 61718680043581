import React, { useState } from 'react'
import { useMemo } from 'react'
import { SharedButton } from '../../../../../shared/shared'
import { Text } from '../../../../../shared/styles/typography'
import { useNavigate } from 'react-router-dom'

export default function usePositionColumns() {
  const navigate = useNavigate()
  const [group, setGroup] = useState(0)
  const positionColumns = useMemo<any>(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Group ID
          </div>
        ),
        accessor: 'groupID',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <>
            {setGroup(cell.value)}
            <Text color="primary" fontSize={16} fontWeight={700}>
              {cell.value}
            </Text>
          </>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Group Collateral
          </div>
        ),
        accessor: 'collateralAllocated',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {cell.value} USD
          </Text>
        ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            PNL
          </div>
        ),
        accessor: 'realizedPnl',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) =>
          cell.value &&
          <Text color={cell.value <= 0 ? "#CF3E40" : "#69CC8D"} fontSize={16} fontWeight={700}>
            {Math.abs(cell.value).toFixed(2)}
          </Text>
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            No of Positions
          </div>
        ),
        accessor: 'groupPositionsInfo',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {cell.value}
          </Text>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            {' '}
          </div>
        ),
        accessor: 'id',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <SharedButton width="114px" onClick={() => navigate('/home/' + `${cell.value}`)}>
            View
          </SharedButton>
        ),
      },
    ],
    []
  )
  return positionColumns
}
