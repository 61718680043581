/** custom module import of the contract instance */
import { ethers } from 'ethers'

import PoolAbi from '../../blockchain/abi/pool.json'
import { HttpProvider } from '../instances/httpProviderInstance'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {string} poolAddress - address of token.
 */
const tickSpacingContract = async (poolAddress: string) => {
  try {
    const poolContractInstance = new ethers.Contract(poolAddress, PoolAbi['abi'], HttpProvider)

    const tick = await poolContractInstance.tickSpacing()
    return tick
  } catch (error) {
    console.log('error in marketPlace', error)
  }
}

export default tickSpacingContract
