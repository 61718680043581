/** Library imports */
import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import axios from 'axios'
import Slider from "rc-slider";
import { useWeb3React } from "@web3-react/core";

/** Custom imports */
import { FlexBox } from "../../../../../shared/flexBox";
import { Flex, SharedButton } from "../../../../../shared/shared";
import { SharedCard } from "../../../../../shared/sharedCard";
import { Text } from "../../../../../shared/styles/typography";
import { SliderContainer, Value } from "../styles";
import {
  Block,
  Dropdown,
  DropDownContainer,
  DropdownValues,
  InputBox,
  RadioButton,
  Type,
  TypeButton,
} from "./styles";
import usdc from "../../../../../assets/images/usdc.svg";
import up from "../../../../../assets/images/dropdown-up.svg";
import down from "../../../../../assets/images/dropdown-down.svg";
import removeLiquidity from "../../../../../blockchain/helper/RemoveLiquidity";
import { negativeValidation, specialCharValidation } from "../../../../../utils/helper";
import { ethToWei, weiToEth } from "../../../../../blockchain/helper/Converstion";
import balanceManagerContract from "../../../../../blockchain/helper/balanceManager";
import getAmountsForLiquidity from "../../../../../blockchain/helper/GetAmountsForLiquidity";
import { DEADLINE, handleMinReceive } from "../../../../../blockchain/helper/handleMinMax";
import { API_URL } from "../../../../../blockchain/helper/Config";
import { TwoColumns } from "../../../../../shared/basicGrid";
import { useAppDispatch, useAppSelector } from "../../../../../logic/redux/hooks";
import { set_Transaction_Success } from "../../../../../logic/redux/actions";
import { errorToast } from "../../../../../blockchain/helper/toasts";
interface Props {
  poolData?: any;
  marketPrice?: number;
  baseTokenAddress?: string;
  baseToken?: string;
  toggleModal?: any;
  setLiquidtyTransactionSuccess?: any;
  selectedTokenLogo?: string;
  collateralBal?: number;
  showModal?: boolean
}

export const RemoveLiquidityModal = (props: Props) => {

  const { poolData, marketPrice, baseTokenAddress, baseToken, toggleModal, selectedTokenLogo,
    setLiquidtyTransactionSuccess, collateralBal, showModal } = props;
  const { account, library } = useWeb3React();
  const dispatch = useAppDispatch()
  const { transactionHash, transactionSuccess } = useAppSelector((state) => state.graphSymbol)

  const [percentage, setPercentage] = useState<number>(20);
  const [position, setPosition] = useState<string>("open");
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [dropdownValue, setDropdownValue] = useState<string>("Cross margin");
  const [slippage, setSlippage] = useState<number>(1.5);
  const [group, setGroup] = useState<string>("new");
  const [isolatedPosition, setIsolatedPosition] = useState<string>("new");
  const [groupId, setGroupId] = useState<string>('')
  const [positionId, setPositionId] = useState<string>('')
  const [collateral, setCollateral] = useState<string>('')
  const [accountLeverage, setAccountLeverage] = useState<string>('0.00')
  const [profitLoss, setProfitLoss] = useState<string>('0.00')
  const [marginRatio, setMarginRatio] = useState<string>('0.00')
  const [loading, setLoading] = useState(false)
  const [insufficientToast, setInsufficientToast] = useState<boolean>(false)
  const [hideImpermanent, setHideImpermanent] = useState<boolean>(false)
  const [invalidId, setInvalidId] = useState<boolean>(false)
  const [groupsData, setGroupsData] = useState<any>([])
  const [isDefaultGroup, setisDefaultGroup] = useState<boolean>(false)
  const [defaultGroupId, setDefaultGroupID] = useState<number>(0)
  const [baseTokenValue, setBaseTokenValue] = useState<string>('')
  const [basePercentage, setBasePercentage] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [positionData, setPositionData] = useState<any>([])

  /**This function is to reset the invalid id */
  const resetInvalidId = () => {

    setInvalidId(false)

  }

  /**This function is to reset the position values */
  const resetPositionValues = () => {

    resetInvalidId()
    setIsolatedPosition("new")
    setGroup("new")

  }

  /**This function is to reset all the values */
  const resetValues = () => {

    setCollateral('')
    setDropdownValue("Isolated margin")
    setPercentage(20)
    setProfitLoss('0.00')
    setAccountLeverage('0.00')
    setMarginRatio('0.00')
    setSlippage(1.5)
    setHideImpermanent(false)
    resetPositionValues()

  }

  /**This useEffect is to reset all the values */
  useEffect(() => {

    resetValues()
    if (!showModal) {
      setPosition("open")
    }

  }, [position, showModal])

  /**This useEffect is to reset only position values */
  useEffect(() => {

    resetPositionValues()

  }, [dropdownValue])

  /**This useEffect is to reset only the invalid id */
  useEffect(() => {

    resetInvalidId()

  }, [isolatedPosition, group])

  /**This function is to calculate the account leverage value */
  const accountLeverageHandler = (value: string) => {

    if (marketPrice && poolData && Number(value) > 0) {
      const baseValue = (poolData.currentBaseTokenBalance * marketPrice) + (poolData.currentQuoteTokenBalance * 1)
      const leverage = baseValue / (Number(value) * 10)
      setAccountLeverage(leverage.toString())
    } else {
      setAccountLeverage('0.00')
    }

  }

  /**This function is called on change of collateral input field */
  const collateralInputHandler = (evt: any) => {

    const value = evt.target.value;
    if (
      specialCharValidation.test(value) ||
      negativeValidation.test(value) ||
      value.split(".").length > 2
    ) {
      return false;
    }
    else {
      const valueTobeSet = value.replace(/\s/g, '')
      const newValue = valueTobeSet.split('.', 2)[1]
      if (newValue && newValue.length > 7) {
        setCollateral(collateral)
      } else {
        setCollateral(valueTobeSet)
      }
      accountLeverageHandler(value.replace(/\s/g, ''))

      //** Note: Do not put like "collateralBal &&"" in if condition , as it won't work for 0 */
      //@ts-ignore
      if (parseFloat(value) > parseFloat(collateralBal)) {
        setInsufficientToast(true)
        setCollateral(collateral)
      } else {
        setInsufficientToast(false)
      }
    }

  }

  /**This useEffect is to show the insufficient collateral toast */
  useEffect(() => {

    if (account && insufficientToast) {
      errorToast('Insufficient Collateral')
    }

  }, [account, insufficientToast])

  /**This function is to get the group positions */
  const getGroupPositions = (groupsInfo: any) => {

    axios
      .get(API_URL + 'trade/user_groups', {
        params: {
          wallet_address: account
        },
      })
      .then((res: any) => {
        const groupsArray = [...groupsInfo]
        res.data.data.map((data: any) => {
          const groupIdIncluded = groupsArray.includes(data.groupID)
          if (!groupIdIncluded) {
            groupsArray.push(data.groupID)
          }
        })

        setGroupsData(groupsArray)
      })
      .catch((error) => {
        console.log("error from user_groups api ", error);
      })

  }

  /**This function is to get the positions data */
  const getPositionData = () => {

    axios
      .get(API_URL + 'trade/positions', {
        params: {
          wallet_address: account,
        },
      })
      .then((res) => {
        let array: any = []
        const groupsArray = [...groupsData]

        res.data.data.positionsData.map((data: any) => {

          if (data.baseToken.toLowerCase() === baseTokenAddress?.toLowerCase() && data.groupID === 0) {
            array.push(data.positionID)
          } else if (data.baseToken.toLowerCase() === baseTokenAddress?.toLowerCase() && data.groupID !== 0) {
            groupsArray.push(data.groupID.toString())
          }
        })
        setPositionData(array)
        account && getGroupPositions(groupsArray)

      })
      .catch((error) => {
        console.log("error from positions api", error);
      })

  }

  /**This useEffect is to set the default position id */
  useEffect(() => {

    if (positionData.length > 0) {
      setPositionId(positionData[0])
    }

  }, [positionData])

  /**This useEffect is to set the default group id */
  useEffect(() => {

    if (groupsData.length > 0) {
      setGroupId(groupsData[0])
    }

  }, [groupsData])

  /**This useEffect is to get the data for groups & positions */
  useEffect(() => {

    account && getPositionData()

  }, [account, baseTokenAddress])

  /**This function is to call the remove liquidity contract */
  const removeLiquidityHandler = async () => {

    if (invalidId) {

      const isIsolate = dropdownValue === "Cross margin" ? false : true
      if (isIsolate) {
        errorToast('Invalid Position ID')
      } else {
        errorToast('Invalid Group ID')
      }
    } else if (account && poolData && marketPrice && baseTokenAddress) {

      setLoading(true)
      const lowerTick = poolData.lowerTick
      const upperTick = poolData.upperTick
      const isClose = position === "open" ? false : true
      const isIsolate = hideImpermanent || isClose ? false : dropdownValue === "Cross margin" ? false : true

      let groupIdValue: string
      let positionIdValue: string
      if (isIsolate) {
        if (isolatedPosition === 'new') {
          positionIdValue = ''
        } else {
          positionIdValue = positionId
        }
        groupIdValue = ''
      } else {
        positionIdValue = ''
        if (group === 'new') {
          groupIdValue = ''
        } else {
          groupIdValue = groupId
        }
      }

      const BaseAmount = await getAmountsForLiquidity(
        account,
        library,
        baseTokenAddress,
        poolData.orderID,
        true
      )

      const QuoteAmount = await getAmountsForLiquidity(
        account,
        library,
        baseTokenAddress,
        poolData.orderID,
        false
      )
      if (BaseAmount && QuoteAmount) {
        const Amount = new BigNumber(poolData.liquidity).multipliedBy(percentage / 100)

        const collateralAmount = collateral ? ethToWei(collateral, 8) : 0
        const BigBaseAmount = new BigNumber(BaseAmount).multipliedBy(percentage / 100)
        const BigQuoteAmount = new BigNumber(QuoteAmount).multipliedBy(percentage / 100)

        const minBaseAmount = ethToWei(handleMinReceive(BigBaseAmount, slippage).toFixed(18), 18);
        const minQouteAmount = ethToWei(handleMinReceive(BigQuoteAmount, slippage).toFixed(18), 18);
        const liquidityAmount = ethToWei((Amount).toFixed(18), 18)

        const response = await removeLiquidity(
          dispatch,
          account,
          library,
          baseTokenAddress, //Base token address
          lowerTick,
          upperTick,
          liquidityAmount,
          isClose,
          isIsolate,
          isDefaultGroup,
          Number(groupIdValue),
          Number(positionIdValue),
          Number(collateralAmount),
          minBaseAmount,
          minQouteAmount,
          '0',
          '0',
          DEADLINE.toString()
        )

        if (response) {
          toggleModal()
          setLoading(false)
          if (response.message) {
            errorToast("Something went wrong: " + response.message)
          }
        }

      }
    }
  }

  /**This useEffect is to close the modal on succeful removal of liquidity */
  useEffect(() => {

    if (transactionSuccess) {
      toggleModal()
      setLiquidtyTransactionSuccess(true)
      setLoading(false)
      dispatch(set_Transaction_Success(false))
    }

  }, [transactionSuccess, transactionHash])

  /**This function is to get the profit, loss, default group id and margin ratio values */
  const profitNLossHandler = async () => {

    if (account && poolData && baseTokenAddress) {
      const result = await balanceManagerContract(
        account,
        library,
        baseTokenAddress,
        poolData.orderID
      )

      if (result) {
        const resultInEth = weiToEth(result[0], 18)
        setProfitLoss(resultInEth.toString())
        setDefaultGroupID(result[1])

        if (resultInEth && marketPrice && !isNaN(parseFloat(collateral)) && parseFloat(poolData.currentBaseTokenBalance) > 0) {
          const ratio = (parseFloat(collateral) + parseFloat(resultInEth)) / (parseFloat(poolData.currentBaseTokenBalance) * marketPrice)

          if (!isNaN(ratio)) {
            setMarginRatio(ratio.toFixed(2))
          }
        } else {
          setMarginRatio("0.00")
        }
      }
    }

  }

  /**This useEffect is to hide impermanent positions data & to call profitNLossHandler function */
  useEffect(() => {

    profitNLossHandler()
    if (poolData) {
      const BaseValue = new BigNumber((poolData.currentBaseTokenBalance).toString()).minus(new BigNumber((poolData.base).toString())).toFixed()
      const shortOrLong = new BigNumber(BaseValue).isGreaterThanOrEqualTo(new BigNumber(0))
      setType(shortOrLong ? "Long" : "Short")

      const BaseValueAbs = new BigNumber(BaseValue).absoluteValue().toFixed()
      setBaseTokenValue(BaseValueAbs)

      const baseValueLimit = weiToEth('10', 18)
      const hide = new BigNumber(BaseValueAbs).isLessThanOrEqualTo(new BigNumber(baseValueLimit))
      setHideImpermanent(hide)
    }

  }, [account, showModal, baseTokenAddress, poolData, collateral, position])

  /**This useEffect is to set the base percentage value */
  useEffect(() => {

    if (baseTokenValue) {
      const percentageValue = new BigNumber(baseTokenValue).multipliedBy(new BigNumber(percentage)).dividedBy(new BigNumber(100))
      setBasePercentage(percentageValue.toString())
    }

  }, [percentage, baseTokenValue])

  return (
    <>
      <SharedCard
        background="secondary"
        padding="16px 24px"
        margin="26px 0 0 0"
      >
        <Text
          fontSize={14}
          fontWeight={500}
          lineHeight={21}
          color="primary"
          margin="-48px 0 28px 18px"
          textAlign="center"
        >
          Remove Liquidity
        </Text>
        <FlexBox>
          <Text
            fontSize={16}
            fontWeight={500}
            lineHeight={24}
            color="secondary"
            margin="0 0 14px 0"
          >
            Available liquidity
          </Text>
          <Text
            fontSize={16}
            fontWeight={500}
            lineHeight={24}
            color="secondary"
            margin="0 0 14px 0"
          >
            Balance :{" "}
            <span
              style={{
                color: "#69CC8Db8",
                fontWeight: "700",
                fontStyle: "Poppins",
              }}
            >
              {" "}
              ${poolData && marketPrice ? ((poolData.currentBaseTokenBalance * marketPrice) + (poolData.currentQuoteTokenBalance * 1)).toFixed(2) : "0.00"}
            </span>
          </Text>
        </FlexBox>
        <FlexBox>
          <Text fontSize={16} fontWeight={500} lineHeight={24} color="primary">
            0%
          </Text>
          <SliderContainer width="75%">
            <Slider
              className="up"
              onChange={(nextValues: any) => {
                setPercentage(nextValues);
              }}
              min={0}
              max={100}
              defaultValue={percentage}
              step={1}
              value={percentage}
            />
          </SliderContainer>
          <TypeButton
            color="#BEDAFF"
            active={true}
            onClick={() => setPercentage(100)}
            width="48px"
            textColor="#000000"
          >
            Max
          </TypeButton>
        </FlexBox>
        <Flex justifyContent="center">
          <Value>{percentage}%</Value>
        </Flex>
        {hideImpermanent ? "" :
          <>
            <Flex justifyContent="space-between">
              <Text fontSize={13} fontWeight={500} lineHeight={24} color="primary">
                Impermanent → Permanent Position
              </Text>
              <Flex flexDirection="row">
                <Type>
                  <TypeButton
                    color="#69CC8D"
                    active={position === "open"}
                    onClick={() => setPosition("open")}
                    width="48px"
                  >
                    Open
                  </TypeButton>
                  <TypeButton
                    color="#E0476A"
                    active={position === "close"}
                    onClick={() => setPosition("close")}
                    width="48px"
                  >
                    Close
                  </TypeButton>
                </Type>
              </Flex>
            </Flex>
            <Text
              fontSize={11}
              fontWeight={500}
              lineHeight={16}
              color="secondary"
              margin="0 0 15px 0"
            >
              Removing liquidity will result in a new trader's position. If you
              already have a position, they will be merged.
            </Text>
            <Block>
              <Flex>
                <img src={selectedTokenLogo ? selectedTokenLogo : usdc} />
                <Text
                  fontSize={12}
                  fontWeight={700}
                  lineHeight={18}
                  color="primary"
                  margin="0 0 0 12px"
                  ls='1px'
                >
                  {baseToken} <br />
                  {basePercentage ? basePercentage : "0.00"}
                </Text>
              </Flex>
              <Text fontSize={12} fontWeight={700} lineHeight={18} color="#69CC8D">
                {type}
              </Text>
            </Block>
            {position === "open" ? (
              <>
                <Dropdown onClick={() => setDropdown(!dropdown)}>
                  <Text
                    fontSize={16}
                    fontWeight={400}
                    lineHeight={24}
                    color="secondary"
                    margin="0 0 0 12px"
                    onClick={() => setDropdownValue("Cross margin")}
                  >
                    {dropdownValue}
                  </Text>
                  {!dropdown ? <img src={down} /> : <img src={up} />}
                </Dropdown>
                {dropdown && (
                  <>
                    {dropdownValue !== "Cross margin" ? (
                      <DropdownValues
                        onClick={() => {
                          setDropdownValue("Cross margin");
                          setDropdown(false);
                        }}>
                        <Text
                          fontSize={16}
                          fontWeight={400}
                          lineHeight={24}
                          color="secondary"
                          margin="0 0 0 12px"
                          onClick={() => {
                            setDropdownValue("Cross margin");
                            setDropdown(false);
                          }}>
                          Cross Margin
                        </Text>
                      </DropdownValues>
                    ) : (
                      <DropdownValues
                        onClick={() => {
                          setDropdownValue("Isolated margin");
                          setDropdown(false);
                        }}>
                        <Text
                          fontSize={16}
                          fontWeight={400}
                          lineHeight={24}
                          color="secondary"
                          margin="0 0 0 12px"
                          onClick={() => {
                            setDropdownValue("Isolated margin");
                            setDropdown(false);
                          }}>
                          Isolated Margin
                        </Text>
                      </DropdownValues>
                    )}
                  </>
                )}
                {dropdownValue === "Cross margin" ? (
                  <>
                    <Flex justifyContent="space-between">
                      <Flex onClick={() => setGroup("new")}>
                        <RadioButton active={group === "new"} />
                        <Text
                          fontSize={14}
                          fontWeight={400}
                          lineHeight={21}
                          color="secondary"
                          margin="16px 0 0 6px"
                        >
                          {" "}
                          New Group
                        </Text>
                      </Flex>
                      {
                        groupsData && groupsData.length > 0 ?
                          <Flex onClick={() => setGroup("existing")}>
                            <RadioButton active={group === "existing"} />
                            <Text
                              fontSize={14}
                              fontWeight={400}
                              lineHeight={21}
                              color="secondary"
                              margin="16px 0 0 6px"
                            >
                              {" "}
                              Existing Group
                            </Text>
                          </Flex>
                          : ""
                      }
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex justifyContent="space-between">
                      <Flex onClick={() => setIsolatedPosition("new")}>
                        <RadioButton active={isolatedPosition === "new"} />
                        <Text
                          fontSize={14}
                          fontWeight={400}
                          lineHeight={21}
                          color="secondary"
                          margin="16px 0 0 6px"
                        >
                          {" "}
                          New Position
                        </Text>
                      </Flex>
                      {
                        positionData && positionData.length > 0 ?
                          <Flex onClick={() => setIsolatedPosition("existing")}>
                            <RadioButton active={isolatedPosition === "existing"} />
                            <Text
                              fontSize={14}
                              fontWeight={400}
                              lineHeight={21}
                              color="secondary"
                              margin="16px 0 0 6px"
                            >
                              {" "}
                              Existing Position
                            </Text>
                          </Flex>
                          : ""
                      }
                    </Flex>
                  </>
                )}
                <Flex justifyContent="space-between">
                  <Flex flexDirection="column">
                    <Text
                      fontSize={14}
                      fontWeight={400}
                      lineHeight={21}
                      color="secondary"
                      margin="16px 0 0 6px"
                    >
                      {" "}
                      Collateral
                    </Text>
                  </Flex>
                  {group === "existing" && dropdownValue === "Cross margin" ? (
                    <Flex>
                      <Text
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={21}
                        color="secondary"
                        margin="16px 0 0 6px"
                      >
                        {" "}
                        Group ID
                      </Text>
                    </Flex>
                  ) : (
                    ""
                  )}
                  {isolatedPosition === "existing" &&
                    dropdownValue === "Isolated margin" ? (
                    <Flex flexDirection="column">
                      <Text
                        fontSize={14}
                        fontWeight={400}
                        lineHeight={21}
                        color="secondary"
                        margin="16px 0 0 6px"
                      >
                        {" "}
                        Position ID
                      </Text>
                    </Flex>
                  ) : (
                    ""
                  )}
                </Flex>
                <TwoColumns>
                  <InputBox placeholder="Enter collateral"
                    value={collateral}
                    onChange={collateralInputHandler}
                  />
                  {isolatedPosition === "existing" &&
                    dropdownValue === "Isolated margin" ? (
                    <DropDownContainer>
                      <div className="select_box">
                        <select onChange={(evt: any) => {
                          setPositionId(evt.target.value)
                        }}
                          disabled={!(positionData.length > 0)}>
                          {
                            positionData && positionData.length > 0 && positionData.map((data: any, idx: number) => {
                              return (
                                <option style={{ color: "blue" }}>{data}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </DropDownContainer>
                  ) : (
                    ""
                  )}
                  {group === "existing" && dropdownValue === "Cross margin" ? (
                    <DropDownContainer>
                      <div className="select_box">
                        <select onChange={(evt: any) => {
                          setGroupId(evt.target.value)
                        }}
                          disabled={!(groupsData.length > 0)}>

                          {
                            groupsData && groupsData.length > 0 && groupsData.map((data: any, idx: number) => {
                              return (
                                <option style={{ color: "blue" }}>{data}
                                </option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </DropDownContainer>
                  ) : (
                    ""
                  )}
                </TwoColumns>
                {/* <HR /> */}
              </>
            ) : (
              <></>
              /** Note : Display common for Both Open and Close Positions */
            )}
          </>
        }
        <Flex justifyContent="space-between">
          <Text
            fontSize={12}
            fontWeight={400}
            lineHeight={24}
            color="secondary"
            margin="0 0 0 12px"
          >
            Profit / Loss
          </Text>
          <Text
            fontSize={12}
            fontWeight={400}
            lineHeight={24}
            color="secondary"
            margin="0 0 0 12px"
          >
            {profitLoss ? profitLoss : "0.00"}
          </Text>
        </Flex>
        {position === "open" && !hideImpermanent ?
          <>
            <Flex justifyContent="space-between">
              <Text
                fontSize={12}
                fontWeight={400}
                lineHeight={24}
                color="secondary"
                margin="0 0 0 12px"
              >
                Account Margin Ratio
              </Text>
              <Text
                fontSize={12}
                fontWeight={400}
                lineHeight={24}
                color="secondary"
                margin="0 0 0 12px"
              >
                {marginRatio ? marginRatio : "0.00"}%
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text
                fontSize={12}
                fontWeight={400}
                lineHeight={24}
                color="secondary"
                margin="0 0 0 12px"
              >
                Account Leverage
              </Text>
              <Text
                fontSize={12}
                fontWeight={400}
                lineHeight={24}
                color="secondary"
                margin="0 0 0 12px"
              >
                {accountLeverage}
              </Text>
            </Flex>
          </>
          :
          ""}
        <Text
          fontSize={12}
          fontWeight={400}
          lineHeight={24}
          color="secondary"
          margin="0 0 0 12px"
        >
          Slippage Tolerance
        </Text>
        <Block>
          <Text
            fontSize={12}
            fontWeight={400}
            lineHeight={24}
            color="primary"
            margin="0 0 0 12px"
          >
            {slippage}%
          </Text>
          <div>
            <TypeButton
              color="#BEDAFF"
              active={true}
              width="48px"
              textColor="#000000"
              margin="0 0 0 8px"
              onClick={() => setSlippage(1)}
            >
              1%
            </TypeButton>
            <TypeButton
              color="#BEDAFF"
              active={true}
              width="48px"
              textColor="#000000"
              margin="0 0 0 8px"
              onClick={() => setSlippage(1.5)}
            >
              1.5%
            </TypeButton>
            <TypeButton
              color="#BEDAFF"
              active={true}
              width="48px"
              textColor="#000000"
              margin="0 0 0 8px"
              onClick={() => setSlippage(2)}
            >
              2%
            </TypeButton>
          </div>
        </Block>
      </SharedCard>

      <Flex>
        <SharedButton margin="18px 0 0 0" padding="12px 60px" color="#E0476A"
          onClick={removeLiquidityHandler}
          disabled={percentage === 0 || loading ||
            (!hideImpermanent && position === "open" && (Number(collateral) <= 0 ||
              (collateralBal && (Number(collateral) > collateralBal)))) ||
            (isolatedPosition === "existing" && (!(positionData.length > 0) || !positionId)) ||
            (group === "existing" && (!(groupsData.length > 0) || !groupId))
          }
        >
          {loading ? 'Loading...' : 'Remove Liquidity'}
        </SharedButton>
      </Flex>
    </>
  );
};
