import React, { useEffect, useState } from 'react'
import { withTheme } from 'styled-components'
import { Flex, SharedButton } from '../../../../shared/shared'
import { SharedCard } from '../../../../shared/sharedCard'
import { FlexRow } from '../../../../shared/styles/styles'
import { Text } from '../../../../shared/styles/typography'
import { SliderContainer, Type, TypeButton } from '../../pages/pool/styles'
import { Container, Input, OptionWrapper, LeverageBlock } from './style'
import { PoolDetails } from '../trade/components/PoolDetails'
import { vEth_Address, VETH_USD_PairAddress } from '../../../../blockchain/helper/Config'
import Slider from 'rc-slider'
import { HR } from '../../components/navbar/style'

interface I_CalculatorProps {}

const InputElement = (props: any) => {
  const { value, onChange, placeholderText } = props
  const handleClick = (e: any) => {
    onChange(e.target.value)
  }
  const [active, setActive] = useState(false)
  const [dropdownValue, setDropdownValue] = useState('ETH')
  return (
    <SharedCard background="primary" padding="15px" width="50%">
      <FlexRow>
        <Input placeholder="0.000" onChange={handleClick} />
        <Text color="secondary" fontFamily="Poppins" fontSize={12} fontWeight={600} lineHeight={16}>
          {placeholderText}
        </Text>
        {!placeholderText && (
          /** get dropdown values from apis  */
          <OptionWrapper active={active}>
            <div className="dropdown">
              <button onClick={() => setActive(!active)} className="dropbtn">
                {dropdownValue} <span className="icon"></span>
              </button>
              <div id="myDropdown" className="dropdown-content">
                <a
                  href="#home"
                  onClick={() => {
                    setDropdownValue('ETH')
                    setActive(false)
                  }}
                >
                  ETH
                </a>
                <a
                  href="#about"
                  onClick={() => {
                    setDropdownValue('BTC')
                    setActive(false)
                  }}
                >
                  BTC
                </a>
                <a
                  href="#contact"
                  onClick={() => {
                    setDropdownValue('USD')
                    setActive(false)
                  }}
                >
                  USD
                </a>
              </div>
            </div>
          </OptionWrapper>
        )}
      </FlexRow>
    </SharedCard>
  )
}

export const Calculator: React.FC = withTheme((props: I_CalculatorProps) => {
  const types = ['PNL', 'Target Price', 'Liquidation Price', 'Max Open', 'Open Price']
  const [active, setActive] = useState(types[0])
  const [CurrentTab, setCurrentTab] = useState('PNL')
  const [positionType, setPositionType] = useState('Long')
  const [entryPrice, setEntryPrice] = useState(0)
  const [exitPrice, setExitPrice] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [baseTokenAddr, setBaseTokenAddr] = useState<string>(vEth_Address)
  const [poolAddress, setPoolAddress] = useState<string>(VETH_USD_PairAddress)
  const [selectedToken, setSelectedToken] = useState<string>('ETH')
  const [selectedLogo, setSelectedLogo] = useState<string>('')
  const [marketPrice, setMarketPrice] = useState<number>(0)
  const [pnl, setPnl] = useState(0)
  const [roe, setRoe] = useState(0)
  const [leveragePercentage, setLeveragePercentage] = useState(10)
  const [initialMargin, setInitialMargin] = useState(0)
  //**This function is called on change of leverage slider */
  const leveragePercentageHandler = (value: any) => {
    setLeveragePercentage(Number(value))
  }
  const getCalculatorValues = () => {
    if (positionType === 'Short') {
      setPnl((entryPrice - exitPrice) * quantity)
    } else {
      setPnl((exitPrice - entryPrice) * quantity)
    }
  }

  useEffect(() => {
    setInitialMargin( (entryPrice * quantity) / leveragePercentage )
    setRoe((pnl / ( (entryPrice / leveragePercentage )* quantity ))*100)
  }, [pnl, leveragePercentage])

  return (
    <>
      <Flex flexDirection="column" margin="24px 0">
        <Type>
          <TypeButton
            color="#1C7EFF"
            active={CurrentTab === 'PNL'}
            onClick={() => {
              setCurrentTab('PNL')
            }}
            disabled={false}
          >
            PNL
          </TypeButton>
          <TypeButton
            color="#1C7EFF"
            active={CurrentTab === 'Liquidation'}
            onClick={() => {
              setCurrentTab('Liquidation')
            }}
            disabled={false}
          >
            Liquidation Price
          </TypeButton>
        </Type>

        <Container
          background="transaprent"
          border="1px solid #3A365F"
          width="640px"
          padding="30px 30px 60px 30px"
          height="100%"
          margin="24px 0 0 0"
        >
          <PoolDetails
            setBaseTokenAddr={setBaseTokenAddr}
            setPoolAddr={setPoolAddress}
            setSelectedTokenName={setSelectedToken}
            setSelectedLogo={setSelectedLogo}
            setMarketPrice={setMarketPrice}
          />
          <Flex margin="16px 0">
            <Type borderRadius="20px" width="180px">
              <TypeButton
                color="#69CC8D"
                active={positionType === 'Long'}
                borderRadius="20px"
                width="90px"
                onClick={() => {
                  setPositionType('Long')
                }}
                disabled={false}
              >
                Long
              </TypeButton>
              <TypeButton
                color="#E1574B"
                borderRadius="20px"
                width="90px"
                active={positionType === 'Short'}
                onClick={() => {
                  setPositionType('Short')
                }}
                disabled={false}
              >
                Short
              </TypeButton>
            </Type>
          </Flex>
          <Flex>
            <LeverageBlock>
              <Text fontSize={10} fontWeight={500} lineHeight={16} color="secondary" margin="2px 16px 0 0">
                0.00x
              </Text>
              <SliderContainer width="80%" padding="0">
                <Slider
                  defaultValue={10}
                  value={leveragePercentage}
                  min={0}
                  max={30}
                  step={1}
                  onChange={leveragePercentageHandler}
                ></Slider>
              {/* <Text fontSize={10} fontWeight={500} lineHeight={16} color="secondary" margin="2px 16px 0 0">
                {leveragePercentage}</Text> */}
              </SliderContainer>
              <SharedButton
                padding="1px 8px"
                borderRadius="24px"
                onClick={() => setLeveragePercentage(30)}
                margin="0 0 0 16px"
              >
                Max
              </SharedButton>
            </LeverageBlock>
          </Flex>
          <Flex margin="0">
              <Text fontSize={12} fontWeight={500} lineHeight={16} color="secondary" margin="0">
                {leveragePercentage} x 
              </Text>
              </Flex>
          <FlexRow justifyContent="space-between" alignItems="space-around" margin="0 0 16px 0">
            <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
              Maximum position at current leverage
            </Text>
            <Text color="#1C7EFF" fontFamily="Poppins" fontSize={20} fontWeight={600}>
              10,000,000 CTA
            </Text>
          </FlexRow>
          <HR />
          <FlexRow justifyContent="space-between" alignItems="center" margin="32px 0 18px 0">
            <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
              Entry Price
            </Text>
            <InputElement value={entryPrice} onChange={setEntryPrice} placeholderText="USDT" />
          </FlexRow>

          <FlexRow justifyContent="space-between" alignItems="center" margin="0 0 18px 0">
            <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
              Exit Price
            </Text>
            <InputElement value={exitPrice} onChange={setExitPrice} placeholderText="USDT" />
          </FlexRow>

          <FlexRow justifyContent="space-between" alignItems="center" margin="0 0 24px 0">
            <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
              Quantity
            </Text>
            <InputElement value={quantity} onChange={setQuantity} />
          </FlexRow>
          <HR />

          {CurrentTab === 'PNL' && (
            <>
              <FlexRow justifyContent="space-between" alignItems="center" margin="12px 0 18px 0">
                <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
                  Initial Margin
                </Text>
                <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
                  {initialMargin.toFixed(2)} - USDT
                </Text>
              </FlexRow>
              <FlexRow justifyContent="space-between" alignItems="center" margin="0 0 18px 0">
                <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
                  PNL
                </Text>
                <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
                  {pnl.toFixed(2)} - USDT
                </Text>
              </FlexRow>
              <FlexRow justifyContent="space-between" alignItems="center" margin="0 0 18px 0">
                <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
                  ROE
                </Text>
                <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
                  {roe && roe.toFixed(2) || '0'} %
                </Text>
              </FlexRow>
            </>
          )}

          {CurrentTab === 'Liquidation' && (
            <>
              <FlexRow justifyContent="space-between" alignItems="center" margin="12px 0 18px 0">
                <Text color="primary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
                  Liquidation Price
                </Text>
                <Text color="secondary" fontFamily="Poppins" fontSize={16} fontWeight={400}>
                  0.000 - USDT
                </Text>
              </FlexRow>
              <Flex>
                <Text
                  color="secondary"
                  fontFamily="Poppins"
                  fontSize={12}
                  lineHeight={18}
                  fontWeight={400}
                  textAlign="center"
                  margin="0 0 24px 0"
                >
                  Your open positions will be taken into consideration when calculating the liquidation price.
                  Unrealized PNL and maintenance margin of your open position will affect the calculation of liquidation
                  price.
                </Text>
              </Flex>
            </>
          )}
          <Flex>
            <SharedButton width="200px" onClick={() => getCalculatorValues()}>
              Calculate
            </SharedButton>
          </Flex>
        </Container>
        <Text
          color="secondary"
          fontFamily="Poppins"
          fontSize={12}
          lineHeight={18}
          fontWeight={400}
          textAlign="center"
          margin="12px 0 24px 0"
        >
          See what the potential risk and reward will be in monetary terms on any given trade. Use our Futures
          Calculator to establish
          <br />
          your potential profit/loss on a future trade. Read tips on how to use.{' '}
        </Text>
      </Flex>
    </>
  )
})

export default Calculator
