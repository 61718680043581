import Web3 from 'web3'
import { set_Transaction_Hash, set_Transaction_Success } from '../../logic/redux/actions'
import { rpcURL } from './Config'
import { errorToast, successToast } from './toasts'

/** this function is used to get the receipt of transaction*/
export const getreceipt = async (hash: string, dispatch: any, transationMessage: string) => {
  try {
    const web3 = new Web3(rpcURL)

    //@ts-ignore
    await new web3.eth.getTransactionReceipt(hash).then((receipt: any) => {
      if (receipt !== null) {
        if (receipt.status === true) {
          successToast(transationMessage + ' Successful!')
          dispatch(set_Transaction_Hash(''))
          dispatch(set_Transaction_Success(true))
        } else {
          dispatch(set_Transaction_Hash(''))
          errorToast(transationMessage + ' Failed')
        }
      } else {
        console.log('error')
      }
    })
  } catch (error) {
    console.log('error', error)
  }
}
