import { createReducer } from '@reduxjs/toolkit'
import { set_graph_symbol,set_base_token,set_market_address, set_Transaction_Hash, set_Transaction_Success, set_Transaction_Type } from '../actions'

interface I_ApplicationState {
    baseToken:string
    marketGraphSymbol: string
    marketAddress:string
    transactionHash: string
    transactionSuccess: boolean
    transactionType: string
}

export const initialState: I_ApplicationState = {
    baseToken: '0x6D1134E5596e177F5a4902d46D66B7a8a1EA997A',
    marketGraphSymbol: "ETHUSD",
    marketAddress: '0x3c556c0bA20A540c62e5b24EBfcaDF3c9BC378CA',
    transactionHash: '',
    transactionSuccess: false,
    transactionType: ''
}

export default createReducer(initialState, (builder) => {
    builder
        .addCase(set_graph_symbol, (state, action) => {
            state.marketGraphSymbol = action.payload
        })
        .addCase(set_base_token, (state, action) => {
            state.baseToken = action.payload
        })
        .addCase(set_market_address, (state, action) => {
            state.marketAddress = action.payload
        })
        .addCase(set_Transaction_Hash, (state, action) => {
            state.transactionHash = action.payload
        })
        .addCase(set_Transaction_Success, (state, action) => {
            state.transactionSuccess = action.payload
        })
        .addCase(set_Transaction_Type, (state, action) => {
            state.transactionType = action.payload
        })
})