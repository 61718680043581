import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react'
import { useTable } from 'react-table';
import { ThemeProps, withTheme } from 'styled-components';
import { Sub_Graph_Url } from '../../../../../blockchain/helper/Config';
import { fundingQuery } from '../../../../../logic/graphQL/queries';
import { NoDataFound } from '../../../../../shared/noDataFound';
import { Paginated } from '../../../../../shared/pagination/pagination';
import { Flex } from '../../../../../shared/shared';
import { TableStyles } from '../../../../../shared/styles/styles';
import useFundingColumns from './useFundingColumns';
import ReactLoading from 'react-loading'

export const FundingPayments: React.FC = withTheme((props: ThemeProps<any>) => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const client = new ApolloClient({
    uri: Sub_Graph_Url,
    cache: new InMemoryCache(),
  })

  const { account } = useWeb3React()
  const columns = useFundingColumns();
  
  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: gql(fundingQuery(account)),
      })
      .then((data: any) => {
        setData(data.data.oddzUserFundingPaymentSettleds)
        setLoading(false)
      })
      .catch((err: any) => {
        setLoading(false)
      })
  }, [account])

  return (
    <>
          {!account ? <NoDataFound heading='Wallet not connected' text='Please connect your wallet.'/>
          :
      !loading ? (
        data.length > 0 ? ( <Paginated data={data} columns={columns} /> ) : 
        (
          <NoDataFound heading='No History Found' text="Looks like you haven't made any Deposits / Withdraw yet."/>
        )
      ) : (
        <Flex>
          <ReactLoading type={'spinningBubbles'} color="#1c7eff" />
        </Flex>
      )}
    </>
  )
})