import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { rpcURL } from '../helper/Config'

export const injected = new InjectedConnector({
  supportedChainIds: [
    1, 3, 4, 5, 42, 56, 69, 97, 137, 421613, 80001, 43114, 1284, 1285, 4689, 62621, 1088, 1666600000, 43113, 250, 66,
    25, 128, 10,11155111,421614  
  ],
})
export const selectRpc = (type: number): any => {
  switch (type) {
    case 1:
      return {
        1: 'https://mainnet.infura.io/v3/0fe795d7c0254f8096cdeba845d83e99',
      }
    case 56:
      return { 56: 'https://bsc-dataseed.binance.org/' }
    // case 69:
    //   return { 69: "https://kovan.optimism.io/" };
    case 421614:
      return {
        421614: rpcURL,
      }
  }
}

export const walletlink = new WalletLinkConnector({
  url: selectRpc(421614),
  appName: 'Oddz',
  supportedChainIds: [
    1, 3, 4, 5, 42, 56, 69, 97, 137, 421613, 80001, 43114, 1284, 1285, 4689, 62621, 1088, 1666600000, 43113, 250, 66,
    25, 128, 10,421614
  ],
})

export const portis = new PortisConnector({
  dAppId: '213e4d1f-b3c6-45a8-adff-4c1c74dcf449',
  networks: [1],
})

const useAuth = () => {
  const { activate, deactivate } = useWeb3React()
  const localChainId = 421614

  const walletconnect = new WalletConnectConnector({
    rpc: selectRpc(421614),
    qrcode: true,
    // pollingInterval: POLLING_INTERVAL,
    bridge: 'https://bridge.walletconnect.org',
    chainId: localChainId,
  })

  const login = useCallback(
    (connectorID: any) => {
      console.log('walletLink', connectorID)

      const selecWallet = (type: number): any => {
        switch (type) {
          case 1:
            return injected
          case 2:
            return walletconnect
          case 3:
            return walletlink
          case 5:
            return portis
        }
      }
      if (connectorID) {
        activate(selecWallet(connectorID), async (error) => {
          console.log(error)
          if (error instanceof UnsupportedChainIdError) {
            activate(selecWallet(connectorID))
          } else {
            if (error instanceof NoEthereumProviderError) {
              console.log(error)
            } else if (
              error instanceof UserRejectedRequestErrorInjected ||
              error instanceof UserRejectedRequestErrorWalletConnect
            ) {
              //@ts-ignore
              walletconnect.walletConnectProvider = null
              console.log(error)
            } else {
              localStorage.clear()
              console.log(error)

            }
          }
        })
      } else {
      }
    },
    [activate]
  )
  const logout = useCallback(
    (connectorID: any) => {
      //@ts-ignore
      // const walletType = JSON.parse(localStorage.getItem("connectorId"));
      if (connectorID === 2 && walletconnect) {
        //@ts-ignore
        walletconnect.walletConnectProvider = null
      } else if (connectorID === 3) {
        // walletconnect.walletConnectProvider = null;
        walletlink.close()
        // window.location.reload()
      }
      deactivate()
      localStorage.clear()
    },
    [deactivate]
  )
  return { login, logout }
}
export default useAuth
