import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import info from '../../assets/images/tooltip.svg'
import styled from 'styled-components';

// const StyledTooltip = styled((props:any) => (
//     <Tooltip classes={{ popper: props.className }} {...props} />
//   ))`
//     & .MuiTooltip-tooltip {
//       background-color: papayawhip;
//       color: #000;
//     }
//   `;
interface I_Props {
    toolTipText : string;
    placement ?: "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top" | undefined
}
export const ToolTip = (props: I_Props) => {
    const {toolTipText, placement} = props
    return (
        <Tooltip title={toolTipText || ''}  placement={placement} arrow>
           <span>   
             <img style={{height:'14px', width:'14spx', display:'inline-block' , margin:'8px 0 0 0', cursor:'pointer'}} src={info} />
            </span>
        </Tooltip>
        )
}
