/** custom module import of the contract instance */
//@ts-ignore
import contractInstance from '../instances/contractInstance'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {string} account - wallet address of user.
 * @param {any} library - wallet connection provider.
 * @param {object} abi - abi of contract.
 * @param {string} poolAddress - address of token.
 */
const MarketPrice = async (account: string, library: any, abi: object, poolAddress: string) => {
  try {
    const poolContractInstance = await contractInstance(account, library, abi, poolAddress)
    const poolData = await poolContractInstance.methods.slot0().call()

    const sqrtPriceX96 = poolData[0]
    const marketPrice = Math.pow(sqrtPriceX96, 2) / Math.pow(Math.pow(2, 96), 2)
    return [marketPrice, sqrtPriceX96]
  } catch (error) {
    console.log('error in marketPlace', error)
  }
}

export default MarketPrice
