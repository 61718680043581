import styled, { css } from "styled-components";
import { I_ModalBodyProps } from "../components/types";
import { I_ButtonProps, I_CardProps, I_Heading1Props, I_PositionProps } from "./types";

export const FontStyles = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
`;

export const Card = styled.div<I_CardProps>`
  margin-bottom: 44px;
  mix-blend-mode: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  max-width: 100%;
  min-height: 300px;
  background: ${(props) =>
    props.background === "primary"
      ? props.theme.secondary
      : props.background
      ? props.background
      : "linear-gradient(136.66deg, #7DA2D0 6.57%, #9B99FF 96.72%)"};
  mix-blend-mode: normal;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25),
    inset 0px 2px 36px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 20px;
  opacity: 0.5;
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100% !important;
  }
  ${(props) =>
    props.active
      ? "height:auto; padding:20px 36px; opacity:1; min-height:320px"
      : ""}
`;

export const PageContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  width: calc(100% - 2 * var(--pageMargin));
  margin: 0 auto;
  min-height: auto;
  padding: 48px 128px;
  transition: all 300ms ease-in-out;
  align-items: center;
  @media(max-width: 900px){
    padding: 48px;
  }
`;

export const GridContainer = styled.div<any>`
  /* display: grid; */
  place-items: center;
  align-content: center;
  /* grid-template-rows: repeat(2, 1fr); */
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  min-height: auto;
  padding: 48px 60px;
  transition: all 300ms ease-in-out;
  align-items: center;
  @media(max-width: 900px){
    padding: 48px;
  }
`;

export const Heading1 = styled.h1<I_Heading1Props>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : "#111827")};
`;

export const Heading2 = styled.h1<I_Heading1Props>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: ${(props) =>
    props.textAlignment ? props.textAlignment : "center"};
  color: ${(props) => (props.color ? props.color : "#111827")};
`;
export const Paragraph = styled.p<I_Heading1Props>`
  ${FontStyles}
  line-height: 24px;
  text-align: ${(props) =>
    props.textAlignment ? props.textAlignment : "center"};
  color: ${(props) => (props.color ? props.color : "#111827")};
  opacity: ${(props) => props.opacity};
  width: ${(props) => props.width};
`;

export const Button = styled.button<I_ButtonProps>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${FontStyles}
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  background: #1c7eff;
  mix-blend-mode: normal;
  border: 1.5px solid #8d9eea;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 12px 48px;
  width: ${(props) => props.width};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Block = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ModalBody = styled.div<I_ModalBodyProps>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(15, 16, 23, 0.9);
`;

export const ModalContent = styled.div`
  padding: 20px;
  display: inline-block;
  border-radius: 8px;
  margin: 0 auto;
  border: none;
  border-radius: 4px;
  overflow: visible;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  width: 320px;
  height: 418px;
  background: #222d40;
  box-shadow: 0px 4px 12px rgba(218, 220, 224, 0.25);
  border-radius: 12px;
`;

export const ModelHead = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #8499ae;
    margin: 0;
    @media (min-width: 300px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

export const Close = styled.img`
  cursor: pointer;
  width: 20px;
`;

export const Heading = styled.h2`
  ${FontStyles}
  margin-left: 18px;
`;

export const Amount = styled.p`
  ${FontStyles}
  text-align: right;
  margin: 5px 18px 27px 0;
  color: rgba(255, 255, 255, 0.72);
`;

export const Input = styled.input`
  margin-top: 10px;
  width: 90%;
  margin-left: 18px;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 6px;
  background: ${(props) => props.theme.primary};
  border-radius: 8px;
  ${FontStyles}
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 10px 8px;
  color: ${(props) => props.theme.primaryText};
`;

export const BackButton = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 345px;
  background: ${(props) => props.theme.secondary};
  border-radius: 8px;
  margin-top: 12px;
  padding-top: 11px;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 220px;
  background: ${(props) => props.theme.secondary};
  border-radius: 8px;
  padding: 20px;
`;

export const Para = styled.p`
  ${FontStyles}
  font-size: 11px;
  line-height: 16px;
  margin: 0;
  color: rgba(255, 255, 255, 0.72);
`;

export const ApproveButton = styled(Button)`
  width: 100%;
  background: #69cc8d;
  border: 1.5px solid #69cc8d;
`;

export const Token = styled.button`
  width: 162px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  margin-right: 6px;
  background: transparent;
  border: 1px solid rgba(28, 126, 255, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  padding: 14px;
  :hover {
    border: 1px solid #1c7eff;
  }
`;

export const AbsPosition = styled.div<I_PositionProps>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
`;
