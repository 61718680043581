import styled from "styled-components";

interface I_ButtonProps{
    disabled ?: boolean;
}

export const TableFooter = styled.div`
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        color: ${(props) => props.theme.secondaryText} ;
    }
    select , input{
        padding: 4px;
        background-color: transparent;
        border: 1px solid #1c7eff;
        color: ${(props) => props.theme.primaryText} ;
        cursor: pointer;
    }
`;

export const TablePaginationButtons = styled.div<I_ButtonProps>`
        padding: 12px;
        border-radius: 8px;
        background-color: ${(props) => props.theme.secondaryNav} ;
        cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
        color: ${(props) => props.theme.primaryText} ;
        ${(props) => props.disabled && `opacity:0.3` }
`;