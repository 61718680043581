/** Library imports */
import React, { useEffect, useState } from "react";
import { withTheme, ThemeProps } from "styled-components";
import Slider from "rc-slider";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import ReactLoading from "react-loading";
import { useWeb3React } from "@web3-react/core";

/** Custom imports */
import { SharedCard } from "../../../../shared/sharedCard";
import { FlexBox } from "../../../../shared/flexBox";
import {
  SearchInput,
  SearchWrapper,
  TokenContainer,
  Type,
  TypeButton,
  SliderContainer,
  AddButton,
  RemoveButton,
} from "./styles";
import { TokenCard } from "./components/TokenCard";
import CustomModal from "../../../../shared/custom-modal";
import { Flex, SharedButton } from "../../../../shared/shared";
import { Text } from "../../../../shared/styles/typography";
import "../../../../../node_modules/rc-slider/assets/index.css";
import {
  VETH_USD_PairAddress,
  oddzVault,
  usdcTokenAddress,
  vEth_Address,
  vBtc_Address,
  VBTC_USD_PairAddress,
  API_URL,
  vMatic_Address,
  VMATIC_USD_PairAddress,
  vSol_Address,
  VSOL_USD_PairAddress,
  vLink_Address,
  VLINK_USD_PairAddress,
} from "../../../../blockchain/helper/Config";
import usdcabi from "../../../../blockchain/abi/usdc.json";
import vaultabi from "../../../../blockchain/abi/vault.json";
import PoolAbi from "../../../../blockchain/abi/pool.json";
import { RemoveLiquidityModal } from "./components/RemoveLiquidityModal";
import SimpleLiquidity from "./components/add-liquidity/SimpleLiquidity";
import AdvancedLiquidity from "./components/add-liquidity/AdvancedLiquidity";
import collateralBalance from "../../../../blockchain/helper/CollateralBalance";
import contractInstance from "../../../../blockchain/instances/contractInstance";
import { TICK_MAX, TICK_MIN } from "../../../../blockchain/helper/tick";
import { useAppDispatch, useAppSelector } from "../../../../logic/redux/hooks";
import drop from "../../../../assets/images/drop.svg";
import { TwoColumns } from "../../../../shared/basicGrid";
import NotFoundImage from "../../../../assets/images/notFound.svg";
import { ToolTip } from "../../../../shared/tooltip";
import { getreceipt } from "../../../../blockchain/helper/getReceipt";

export const Pool: React.FC = withTheme((props: ThemeProps<any>) => {
  const dispatch = useAppDispatch();
  const {
    transactionHash,
    transactionType,
    transactionSuccess,
  } = useAppSelector((state) => state.graphSymbol);
  const { account, library, chainId } = useWeb3React();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showWithDrawModal, setShowWithDrawModal] = useState<boolean>(false);
  const [type, setType] = useState<string>("simple");
  const [poolData, setPoolData] = useState<any>([]);
  const [collateralBal, setcollateralBal] = useState<number>(0);
  const [selectedToken, setSelectedToken] = useState<string>("ETH");
  const [selectedTokenLogo, setSelectedTokenLogo] = useState<string>("");
  const [baseTokenAddress, setbaseTokenAddress] = useState(vEth_Address);
  const [poolAddress, setPoolAddress] = useState(VETH_USD_PairAddress);
  const [poolInfo, setPoolInfo] = useState<any>({});
  const [openLiquidityPositions, setopenLiquidityPositions] = useState<any>([]);
  const [marketPrice, setMarketPrice] = useState<number>(0);
  const [poolId, setPoolId] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedPool, setSelectedPool] = useState<string>(
    VETH_USD_PairAddress
  );
  const [totalLiquidity, setTotalLiquidty] = useState(0);
  const [currentTick, setCurrentTick] = useState<number>(0);
  const [prePrediction, setPrePrediction] = useState("");
  const [liquidtyTransactionSuccess, setLiquidtyTransactionSuccess] = useState<
    boolean
  >(false);
  const [loading, setLoading] = useState(false);
  const [baseApr, setBaseApr] = useState(0);
  /**This useffect is to set BaseToken and Pool addresses */
  useEffect(() => {
    if (selectedToken === "ETH") {
      setbaseTokenAddress(vEth_Address);
      setPoolAddress(VETH_USD_PairAddress);
    } else if (selectedToken === "BTC") {
      setbaseTokenAddress(vBtc_Address);
      setPoolAddress(VBTC_USD_PairAddress);
    } else if (selectedToken === "MATIC") {
      setbaseTokenAddress(vMatic_Address);
      setPoolAddress(VMATIC_USD_PairAddress);
    } else if (selectedToken === "SOL") {
      setbaseTokenAddress(vSol_Address);
      setPoolAddress(VSOL_USD_PairAddress);
    } else if (selectedToken === "LINK") {
      setbaseTokenAddress(vLink_Address);
      setPoolAddress(VLINK_USD_PairAddress);
    }
  }, [selectedToken]);

  /** This useEffect is for Calling marketPrice and Deposited functions */
  useEffect(() => {
    getDepositedBalance();
  }, [account, chainId, poolAddress]);

  /** This function is to get the collateral amount to calculate BUYING POWER */
  const getDepositedBalance = async () => {
    if (account) {
      const Balance = await collateralBalance(
        account,
        library,
        vaultabi["abi"],
        oddzVault,
        usdcabi["abi"],
        usdcTokenAddress
      );
      if (Balance) {
        setcollateralBal(Number(Balance));
      }
    }
  };

  /**This function is for Getting Pools Info from the APIs */
  const getMarkets = () => {
    axios
      .get(API_URL + "market/getMarkets", {
        params: {
          wallet_address: `${account ? account : ""}`,
        },
      })
      .then((res) => {
        setPoolData(res.data.data);
      })
      .catch((error) => {
        console.log("error from getMarkets api", error);
      });
  };

  /**This useEffect is to call getMarkets api */
  useEffect(() => {
    getMarkets();
  }, [account, liquidtyTransactionSuccess, transactionSuccess]);

  /** This function is for getting base apr from the APIs */
  const getBaseApr = () => {
    axios
      .get(API_URL + "market/poolapr_info", {
        params: {
          pool_address: poolAddress,
        },
      })
      .then((res) => {
        totalLiquidity &&
          setBaseApr(res.data.data.baseFeeShare / totalLiquidity);
      })
      .catch((error) => {
        console.log("error from api", error);
      });
  };

  useEffect(() => {
    getBaseApr();
  }, [poolAddress, totalLiquidity]);

  /**This function is for Getting searched Pools Info from the APIs */
  const searchHandler = (e: any) => {
    try {
      setSearchValue(e.target.value);
      if (e.target.value) {
        axios
          .get(API_URL + "market/search_markets", {
            params: {
              market_name: `${e.target.value}`,
              wallet_address: `${account ? account : ""}`,
            },
          })
          .then((res) => {
            setPoolData(res.data.data);
          })
          .catch((error) => {
            console.log("error from search_markets api", error);
          });
      } else {
        getMarkets();
      }
    } catch (error) {
      console.log("error from search_markets api", error);
    }
  };

  /** This useEffect is to get the pool details */
  useEffect(() => {
    if (poolAddress) {
      axios
        .get(API_URL + "market/pool_info", {
          params: {
            pool_address: poolAddress,
          },
        })
        .then((res) => {
          setPoolInfo(res.data.data);
        })
        .catch((error) => {
          console.log("error from pool info api", error);
        });
    }
  }, [poolAddress]);

  /**This function is to get the liquidity position details */
  const liquidityPositionDetails = () => {
    if (account) {
      setLoading(true);
      try {
        axios
          .get(API_URL + "liquidity/liquidity_positions_list", {
            params: {
              wallet_address: `${account}`,
              maker_address: baseTokenAddress,
              pool_address: poolAddress,
            },
          })
          .then((res) => {
            setLoading(false);
            setopenLiquidityPositions(res.data.data);
            setLiquidtyTransactionSuccess(false);
          });
      } catch (error) {
        setLoading(false);
        setopenLiquidityPositions([]);
        console.log("error from liquidity_positions_list api", error);
      }
    }
  };

  /**This useEffect is to get liquidity details for the trader */
  useEffect(() => {
    if (account) {
      if (liquidtyTransactionSuccess) {
        setTimeout(() => {
          liquidityPositionDetails();
        }, 5000);
      } else {
        liquidityPositionDetails();
      }
    } else {
      setopenLiquidityPositions([]);
    }
  }, [account, baseTokenAddress, liquidtyTransactionSuccess]);

  /**This function is to calculate the total liquidity value */
  const totalLiquidityCal = (market: number) => {
    let liquidty = 0;
    openLiquidityPositions &&
      openLiquidityPositions.map((position: any, index: number) => {
        liquidty =
          liquidty +
          (position.currentBaseTokenBalance * market +
            position.currentQuoteTokenBalance * 1);
      });
    setTotalLiquidty(liquidty);
  };

  /**This useEffect is to calculate total liquidity */
  useEffect(() => {
    totalLiquidityCal(marketPrice);
  }, [openLiquidityPositions, marketPrice]);

  /**This function is to get the current tick from contract */
  const getCurrentTick = async () => {
    if (account && poolAddress) {
      const poolContractInstance = await contractInstance(
        account,
        library,
        PoolAbi["abi"],
        poolAddress
      );
      const slot = await poolContractInstance.methods.slot0().call();
      setCurrentTick(slot.tick);
    }
  };

  /**This useEffect is to get current tick  */
  useEffect(() => {
    getCurrentTick();
  }, [openLiquidityPositions, marketPrice]);

  /**This useEffect is to reset values on close of modal */
  useEffect(() => {
    if (!showModal) {
      setPrePrediction("");
      setType("simple");
    }
  }, [showModal]);

  /**This useEffect is to close modals on account or network change */
  useEffect(() => {
    setShowModal(false);
    setShowWithDrawModal(false);
  }, [chainId, account]);

  /**This useEffect is to get the receipt for pool transactions */
  useEffect(() => {
    if (transactionHash && transactionType) {
      const interval = setInterval(() => {
        getreceipt(transactionHash, dispatch, transactionType);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [transactionHash, dispatch, transactionType]);

  return (
    <>
      <TokenContainer>
        <SearchWrapper>
          <SearchInput
            className="search"
            placeholder="Search Token"
            value={searchValue}
            onChange={searchHandler}
          />
        </SearchWrapper>

        {poolData.map((type: any) => (
          <TokenCard
            logo={type.marketIcon}
            token={type.marketNameMod}
            value={type.marketFixedPrice}
            marketGraphSymbol={type.marketGraphSymbol}
            position={"up"}
            setSelectedToken={setSelectedToken}
            poolAddress={type.marketAddress}
            active={type.marketNameMod === selectedToken}
            setSelectedTokenLogo={setSelectedTokenLogo}
            setSelectedPool={setSelectedPool}
            setMarketPrice={setMarketPrice}
            marketAddress={baseTokenAddress}
            liquidityPositions={type?.totalLiquidityPositions}
          />
        ))}
      </TokenContainer>
      <TwoColumns padding="12px 60px">
        <SharedCard
          width="100%"
          padding="32px"
          background="primary"
          scrollY={true}
          height="548px"
        >
          <div style={{ marginBottom: "41px" }}>
            <Text
              color="primary"
              fontSize={24}
              lineHeight={36}
              fontWeight={500}
            >
              Liquidity Mining
            </Text>
            <Text
              color="primary"
              fontSize={16}
              lineHeight={24}
              fontWeight={500}
              margin="6px 0 8px 0"
            >
              Earn ODDZ by providing liquidity! The more trading fees you earn
              proportionately in a week, the higher % you can claim from the
              liquidity mining rewards.
            </Text>
            <SharedButton padding="12px 72px">Click Here</SharedButton>
          </div>
          <SharedCard width="100%" background="secondary" padding="24px">
            <Text
              color="primary"
              fontSize={24}
              lineHeight={28}
              fontWeight={500}
              margin="0 0 21px 0"
            >
              Pool Info
            </Text>
            <Text
              color="secondary"
              fontSize={16}
              lineHeight={24}
              fontWeight={500}
              margin="0 0 6px 0"
            >
              Pool Base APR{" "}
              <ToolTip toolTipText="The est. APR from Trading fees" /> -{" "}
              {poolInfo ? poolInfo?.poolBaseApr?.toFixed(4) : ""} %
            </Text>

            <Text
              color="secondary"
              fontSize={16}
              lineHeight={24}
              fontWeight={500}
              margin="0 0 6px 0"
            >
              Mark Price - $ {marketPrice}
            </Text>

            <Text
              color="secondary"
              fontSize={16}
              lineHeight={24}
              fontWeight={500}
              margin="0 0 6px 0"
            >
              TVL - ${" "}
              {poolInfo ? poolInfo?.tvl?.totalValueLocked?.toFixed(4) : ""}
            </Text>

            <Text
              color="secondary"
              fontSize={16}
              lineHeight={24}
              fontWeight={500}
              margin="0 0 6px 0"
            >
              Volume (24h) - $ {poolInfo ? poolInfo?.volume24h?.toFixed(4) : ""}
            </Text>

            <Text
              color="secondary"
              fontSize={16}
              lineHeight={24}
              fontWeight={500}
              margin="0 0 6px 0"
            >
              24h Fees - $ {poolInfo ? poolInfo?.last24hrsFees?.toFixed(4) : ""}
            </Text>
          </SharedCard>
        </SharedCard>
        <SharedCard
          width="100%"
          background="primary"
          scrollY={true}
          height="548px"
        >
          <Text
            color="primary"
            fontSize={24}
            lineHeight={28}
            fontWeight={500}
            margin="0 0 21px 0"
          >
            Total Liquidity
          </Text>
          <FlexBox>
            <Text
              color="#69CC8D"
              fontSize={24}
              lineHeight={28}
              fontWeight={500}
              margin="0 0 20px 0"
            >
              {" "}
              ${totalLiquidity ? Number(totalLiquidity).toFixed(2) : "0.00"}
            </Text>
            <FlexBox>
              <SharedButton
                onClick={() => {
                  setShowModal(true);
                  setPrePrediction("");
                }}
                color="#69CC8D"
                margin="0 4px 20px 0"
                padding="12px 24px 12px 16px"
              >
                <img src={drop} style={{ marginRight: "8px" }} />
                Add Liquidity
              </SharedButton>
            </FlexBox>
          </FlexBox>
          <SharedCard width="100%" background="secondary" padding="24px">
            <Text
              color="secondary"
              fontSize={16}
              lineHeight={24}
              fontWeight={500}
              margin="0 0 0 0"
            >
              Base APR <ToolTip toolTipText="The est.APR from Trading fees" /> -{" "}
              {baseApr.toFixed(4)}
            </Text>
            {/* <Text color="secondary" fontSize={16} lineHeight={24} fontWeight={500} margin="0 0 0 0">
              Rewards APR <ToolTip toolTipText='The est.APR from from Liquidity mining rewards ' /> -
            </Text> */}
          </SharedCard>
          <Flex justifyContent="space-between">
            <Text
              color="primary"
              fontSize={18}
              lineHeight={21}
              fontWeight={500}
              margin="32px 0 20px 0"
            >
              Liquidity Positions
            </Text>
            <Text
              color="primary"
              fontSize={18}
              lineHeight={21}
              fontWeight={500}
              margin="32px 0 20px 0"
            ></Text>
          </Flex>
          {!account ? (
            <Flex flexDirection="column" margin="0 0 0 0">
              <img src={NotFoundImage} />
              <Text
                color="primary"
                textAlign="center"
                fontWeight={500}
                margin="4px 0 0 0"
                fontSize={16}
                line-height={20}
              >
                Wallet not connected
              </Text>
              <Text
                color="primary"
                textAlign="center"
                fontWeight={500}
                margin="4px 0 0 0"
                fontSize={12}
                line-height={14}
              >
                Please connect your wallet.
              </Text>
            </Flex>
          ) : !loading ? (
            !openLiquidityPositions.length && (
              <>
                <Flex flexDirection="column" margin="0 0 0 0">
                  <img src={NotFoundImage} style={{ height: "72px" }} />
                  <Text
                    color="primary"
                    textAlign="center"
                    fontWeight={500}
                    margin="4px 0 0 0"
                    fontSize={16}
                    line-height={20}
                  >
                    No Positions Found
                  </Text>
                  <Text
                    color="primary"
                    textAlign="center"
                    fontWeight={500}
                    margin="4px 0 0 0"
                    fontSize={12}
                    line-height={14}
                  >
                    Looks like you haven't made any positions yet.
                  </Text>
                </Flex>
              </>
            )
          ) : (
            <Flex>
              <ReactLoading type={"spinningBubbles"} color="#1c7eff" />
            </Flex>
          )}
          {!loading &&
            openLiquidityPositions &&
            openLiquidityPositions.map((position: any, index: number) => (
              <SharedCard
                width="auto"
                background="secondary"
                padding="24px"
                margin="0 0 24px 0"
              >
                <>
                  <Flex justifyContent="space-between">
                    <Text
                      color="secondary"
                      fontSize={16}
                      lineHeight={24}
                      fontWeight={500}
                    >
                      Set Price Range
                    </Text>
                    <Text
                      color="secondary"
                      fontSize={12}
                      lineHeight={18}
                      fontWeight={500}
                    >
                      {currentTick > position.lowerTick &&
                      currentTick < position.upperTick ? (
                        <>In Range</>
                      ) : (
                        <> Out Of Range </>
                      )}
                    </Text>
                  </Flex>
                  <Text
                    color="secondary"
                    fontSize={14}
                    lineHeight={21}
                    fontWeight={500}
                    margin="4px 0 12px 0"
                  >
                    ${parseFloat(position.originalLowerTick).toFixed(2)} {"<->"}{" "}
                    ${parseFloat(position.originalUpperTick).toFixed(2)}
                  </Text>

                  <Text
                    color="secondary"
                    fontSize={16}
                    lineHeight={24}
                    fontWeight={500}
                    margin="0 0 12px 0"
                  >
                    Position Leverage -{" "}
                    {parseFloat(position?.positionLeverage).toFixed(4)}
                  </Text>

                  <Text
                    color="secondary"
                    fontSize={16}
                    lineHeight={24}
                    fontWeight={500}
                    margin="0 0 12px 0"
                  >
                    Margin Ratio -{" "}
                    {position?.marginRatio &&
                      parseFloat(position?.marginRatio).toFixed(4)}
                  </Text>
                  <Text
                    color="secondary"
                    fontSize={16}
                    lineHeight={24}
                    fontWeight={500}
                    margin="0 0 24px 0"
                  >
                    Collateral - {position?.collatral}
                  </Text>
                  <Flex justifyContent="space-between">
                    <Flex justifyContent="flex-start">
                      <img src={drop} style={{ marginTop: "12px" }} />
                      <Text
                        color="secondary"
                        fontSize={16}
                        lineHeight={24}
                        fontWeight={500}
                        margin="12px 0 0 4px"
                      >
                        Liquidity{" "}
                        <ToolTip
                          toolTipText="Liquidity for Positions"
                          placement="right"
                        />
                      </Text>
                    </Flex>
                    <Text
                      color="secondary"
                      fontSize={16}
                      lineHeight={24}
                      fontWeight={500}
                      margin="12px 0 0 0"
                    >
                      Fee -{" "}
                      {position.fees
                        ? parseFloat(position.fees).toFixed(4)
                        : "0.00"}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Text
                      color="secondary"
                      fontSize={16}
                      lineHeight={24}
                      fontWeight={700}
                    >
                      $
                      {(
                        position.currentBaseTokenBalance * marketPrice +
                        position.currentQuoteTokenBalance * 1
                      ).toFixed(2)}
                    </Text>
                    <Text
                      color="secondary"
                      fontSize={16}
                      lineHeight={24}
                      fontWeight={700}
                    >
                      {position.collectedFee}
                    </Text>
                  </Flex>
                  <SliderContainer>
                    <Slider
                      className="up"
                      value={
                        ((position.currentQuoteTokenBalance * 1) /
                          (position.currentBaseTokenBalance * marketPrice +
                            position.currentQuoteTokenBalance * 1)) *
                        100
                      }
                    ></Slider>
                  </SliderContainer>
                  <Flex justifyContent="space-between">
                    <Text
                      color="secondary"
                      fontSize={14}
                      lineHeight={21}
                      fontWeight={500}
                      margin="4px 0 20px 0"
                    >
                      $
                      {Number(
                        position.currentBaseTokenBalance * marketPrice
                      ).toFixed(2)}{" "}
                      {selectedToken} -{" "}
                      {Number(position.currentBaseTokenBalance).toFixed(4)}{" "}
                      {selectedToken} -{" "}
                      {(
                        100 -
                        ((position.currentQuoteTokenBalance * 1) /
                          (position.currentBaseTokenBalance * marketPrice +
                            position.currentQuoteTokenBalance * 1)) *
                          100
                      ).toFixed(2)}
                      %
                    </Text>
                    <Text
                      color="secondary"
                      fontSize={14}
                      lineHeight={21}
                      fontWeight={500}
                      margin="4px 0 20px 0"
                    >
                      ${Number(position.currentQuoteTokenBalance).toFixed(2)}{" "}
                      USD -
                      {(
                        ((position.currentQuoteTokenBalance * 1) /
                          (position.currentBaseTokenBalance * marketPrice +
                            position.currentQuoteTokenBalance * 1)) *
                        100
                      ).toFixed(2)}{" "}
                      %
                    </Text>
                  </Flex>
                  <AddButton
                    onClick={() => {
                      setPoolId(index);
                      if (
                        currentTick > position.lowerTick &&
                        currentTick < position.upperTick
                      ) {
                        setType("advanced");

                        if (
                          parseFloat(position.lowerTick) ===
                            parseFloat(TICK_MIN.toString()) &&
                          parseFloat(position.upperTick) ===
                            parseFloat(TICK_MAX.toString())
                        ) {
                          setPrePrediction("full range");
                        } else {
                          setPrePrediction("simple range");
                        }
                      } else if (currentTick < position.lowerTick) {
                        setType("simple");
                        setPrePrediction("up");
                      } else if (currentTick > position.upperTick) {
                        setType("simple");
                        setPrePrediction("down");
                      }
                      setShowModal(true);
                    }}
                  >
                    Increase
                  </AddButton>
                  <RemoveButton
                    onClick={() => {
                      setPoolId(index);
                      setShowWithDrawModal(true);
                    }}
                  >
                    Remove
                  </RemoveButton>
                </>
              </SharedCard>
            ))}
        </SharedCard>
      </TwoColumns>
      <CustomModal
        padding="20px 24px"
        width="448px"
        show={showModal}
        toggleModal={setShowModal}
        background="primary"
        boxShadow=" 0px 12px 24px rgba(0, 0, 0, 0.25);"
      >
        <Text
          fontSize={14}
          fontWeight={500}
          lineHeight={21}
          color="primary"
          margin="-20px 0 16px 18px"
          textAlign="center"
        >
          Add Liquidity
        </Text>

        <Flex flexDirection="row">
          <Type>
            <TypeButton
              color="#1C7EFF"
              active={type === "simple"}
              onClick={() => {
                setType("simple");
              }}
              disabled={
                prePrediction === "full range" ||
                prePrediction === "simple range"
              }
            >
              Simple
            </TypeButton>
            <TypeButton
              color="#1C7EFF"
              active={type === "advanced"}
              onClick={() => {
                setType("advanced");
              }}
              disabled={prePrediction === "up" || prePrediction === "down"}
            >
              Advanced
            </TypeButton>
          </Type>
        </Flex>
        {type === "simple" ? (
          <SimpleLiquidity
            baseTokenAddress={baseTokenAddress}
            poolAddress={poolAddress}
            selectedToken={selectedToken}
            collateralBal={collateralBal}
            toggleModal={setShowModal}
            marketPrice={marketPrice}
            prePrediction={prePrediction}
            setLiquidtyTransactionSuccess={setLiquidtyTransactionSuccess}
            poolData={openLiquidityPositions[poolId]}
            showModal={showModal}
          />
        ) : (
          <AdvancedLiquidity
            baseTokenAddress={baseTokenAddress}
            poolAddress={poolAddress}
            selectedToken={selectedToken}
            collateralBal={collateralBal}
            toggleModal={setShowModal}
            marketPrice={marketPrice}
            setLiquidtyTransactionSuccess={setLiquidtyTransactionSuccess}
            selectedTokenLogo={selectedTokenLogo}
            prePrediction={prePrediction}
            poolData={openLiquidityPositions[poolId]}
            showModal={showModal}
          />
        )}
      </CustomModal>
      <CustomModal
        padding="20px 24px"
        width="448px"
        show={showWithDrawModal}
        toggleModal={setShowWithDrawModal}
        background="primary"
        boxShadow=" 0px 12px 24px rgba(0, 0, 0, 0.25);"
      >
        <RemoveLiquidityModal
          poolData={openLiquidityPositions[poolId]}
          marketPrice={marketPrice}
          baseTokenAddress={baseTokenAddress}
          baseToken={selectedToken}
          toggleModal={setShowWithDrawModal}
          setLiquidtyTransactionSuccess={setLiquidtyTransactionSuccess}
          selectedTokenLogo={selectedTokenLogo}
          collateralBal={collateralBal}
          showModal={showWithDrawModal}
        />
      </CustomModal>
      <ToastContainer position="top-right" style={{ zIndex: "1000" }} />
    </>
  );
});
