/** custom module import of the contract instance */
import contractInstance from '../instances/contractInstance'
import { clearingHouseAddress } from './Config'
import clearHouseAbi from '../../blockchain/abi/clearhouse.json'
import { set_Transaction_Hash, set_Transaction_Type } from '../../logic/redux/actions'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {any} dispatch - useDispatch().
 * @param {string} account - user account address.
 * @param {any} library - wallet connection provider.
 * @param {string} baseTokenAddress - address of base token. 
 * @param {string} token1Amount - amount of base token.
 * @param {string} token2Amount - amount of quote token.
 * @param {number} lowerTick - lower tick of contract.
 * @param {number} upperTick - upper tick of contract. 
 * @param {string} minToken1Amount - minimum amount of base token (with slippage).
 * @param {string} minToken2Amount - minimum amount of quote token (with slippage).
 * @param {string} collateralAmount - provided collateral amount by user.
 * @param {number} DEADLINE - Dead line of contract. 
 
 
/** this function is used to call the liquidity contract on wallets */
const liquidity = async (
  dispatch: any,
  account: string,
  library: any,
  baseTokenAddress: string, //Base token address
  token1Amount: string,
  token2Amount: string,
  lowerTick: number,
  upperTick: number,
  minToken1Amount: string,
  minToken2Amount: string,
  collateralAmount: string,
  DEADLINE: number
) => {
  try {
    if (account) {
      const clearingHouseInstance = await contractInstance(account, library, clearHouseAbi['abi'], clearingHouseAddress)

      const gasLimit = await clearingHouseInstance.methods
        .addLiquidity([
          baseTokenAddress, //Base token address
          token1Amount,
          token2Amount,
          lowerTick,
          upperTick,
          minToken1Amount,
          minToken1Amount,
          collateralAmount,
          DEADLINE,
        ])
        .estimateGas({
          from: account,
        })
      await clearingHouseInstance.methods
        .addLiquidity([
          baseTokenAddress,
          token1Amount,
          token2Amount,
          lowerTick,
          upperTick,
          minToken1Amount,
          minToken1Amount,
          collateralAmount,
          DEADLINE,
        ])
        .send({
          from: account,
          gas: gasLimit + 1000,
        })
        .on('transactionHash', (e: string) => {
          dispatch(set_Transaction_Hash(e))
          dispatch(set_Transaction_Type('Add Liquidity'))
        })
        .on('receipt', (e: string) => {})
    }
  } catch (error) {
    console.log('error in add Liquidity', error)
    //@ts-ignore
    if (error?.message.split('.')[1]) {
      //@ts-ignore
      return JSON.parse(error.message.split('.')[1])
    } else {
      return error
    }
  }
}

export default liquidity
