import styled from "styled-components";

export const Container = styled.div`
    padding: 24px 16px;
    margin: 12px 60px;
    background: transparent;
    border: 1px solid #3A365F;
    width: auto;
    mix-blend-mode: normal;
    border-radius: 16px;
`;

export const GridContainer = styled.div<any>`
  display: grid;
  place-items: center;
  align-content: center;
  /* grid-template-rows: repeat(2, 1fr); */
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  min-height: auto;
  padding: 48px 60px;
  transition: all 300ms ease-in-out;
  align-items: center;
  @media(max-width: 900px){
    padding: 48px;
  }
`;