import styled, { css } from "styled-components";
import arrows from "../../../../assets/images/arrows.svg";
import search from "../../../../assets/images/search.svg";
import add from "../../../../assets/images/add-icon.svg";
import remove from "../../../../assets/images/close-icon.svg"
import { colors } from "../../../../styles/theme";

interface ButtonProps {
  disabled?: boolean;
  width?: string;
  color: string;
  padding?: string;
  onClick?: any;
  margin?: string;
  active?: boolean;
  borderRadius?: string;
}

const FontStyles = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px 60px;
  gap: 30px;
  @media (max-width: 1024px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const TokenContainer = styled.div`
  padding: 28px 60px;
  display: flex;
  gap: 8px;
  /* justify-content : center; */
  align-items: center;
  /* flex-flow : row wrap; */
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const SearchWrapper = styled.div`
  position: relative;
  ::after {
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    bottom: 20px;
    right: 20px;
    /* background: url(${arrows}) no-repeat; */
    background-position: center;
  }
`;

export const SearchInput = styled.input`
  height:56px;
  padding-left: 70px;
  background: ${(props) => props.theme.primary};
  border: 2px solid #374151;
  box-sizing: border-box;
  border-radius: 16px;
  /* width: 453px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: ${(props) => props.theme.primaryText};
  background: ${(props) => props.theme.primary} url(${search}) no-repeat 36px
    center;
  ::placeholder {
    color: ${(props) => props.theme.primaryText};
  }
  :focus {
    outline: none !important;
    border: 2px solid #1c7eff;
  }
`;

export const Type = styled.div<any>`
  display: flex;
  padding: 1px;
  background: ${(props) => props.theme.primary};
  border: 2px solid rgba(28, 126, 255, 0.3);
  border-radius:${(props) => props.borderRadius || '6px'};
  max-width: 400px;
  width: ${(props) => (props.width && props.width)};
`;

export const TypeButton = styled.button<ButtonProps>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${FontStyles}
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${(props) => props.theme.primaryText};
  background: ${(props) =>
    props.color && props.active ? props.color : "transparent"};
  mix-blend-mode: normal;
  border: 1.5px solid
    ${(props) => (props.color && props.active ? props.color : "transparent")};
  box-sizing: border-box;
  border-radius: ${(props) => props.borderRadius || '6px'};
  padding: ${(props) => (props.padding ? props.padding : "6px auto")};
  width: ${(props) => (props.width ? props.width : "198px")};
  margin: ${(props) => props.margin};
`;

interface InputProps {
  width?: string;
  margin?: string;
  background?: string;
  padding?: string;
  align?: string;
  borderColor?:string;
}
export const Input = styled.input<InputProps>`
  border: 1px solid #2f3d55;
  box-sizing: border-box;
  text-align: ${({ align }) => align || "left"};
  border-radius: 6px;
  /* width: 100%; */
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  min-width: 185px;
  background: ${(props) => props.background === 'primary' ? props.theme.inputElement.primary :  "transparent"};
  border-radius: 12px;
  vertical-align:middle;
  ${FontStyles}
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding || "10px 8px"};
  color: ${(props) => props.theme.primaryText};

  :focus {
    outline: none !important;
    border-color: ${({borderColor}) => borderColor || '#1c7efe'} ;
  }
`;

interface SliderProps {
  width?: string;
  padding?: string;
}
export const SliderContainer = styled.div<SliderProps>`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  width: ${({ width }) => width || "100%"};
  padding:  ${({padding}) => padding || '12px 0px 6px 0px'};
  .rc-slider-disabled{
    background-color: transparent;
   }
  .rc-slider-track {
    position: absolute;
    height: 4px;
    background: #69cc8d; // ${(props) => props.theme.primaryText};
    border-radius: 6px;
  }

  .rc-slider-handle {
    position: absolute;
    width: 16px;
    height: 16px;
    margin-top: -6px;
    background: #69cc8d;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    touch-action: pan-x;
    /* z-index: 1; */
  }

  .rc-slider-rail {
    background: #A9A9A9;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: none;
    box-shadow: 0 0 0 5px transparent;
  }
`;

export const Wrapper = styled.div`
  background: transparent;
  /* border: 1px solid #1c7eff; */
  /* border: 2px solid; */
  border-radius: 12px;
  padding: 10px 0px 10px 12px;
  min-width: 105px;
  height: 44px;
`;

export const TokenWrapper = styled.div`
  width: 50%;
  margin: -69px 0 16px 0px;
  height: 44px;
`;

export const TopContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  margin: 0 auto;
  min-height: auto;
  padding: 0 60px;
  padding-bottom: 100px;
  transition: all 300ms ease-in-out;
  align-items: center;
  /* background:#23262F; */
`;

export const Value = styled.div`
  width: 72px;
  padding: 9px 24px 6px 24px;
  background: ${(props) => props.theme.primary};
  border: 1px solid #222d40;
  box-shadow: inset 0px 2px 4px #1c1d29;
  border-radius: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.primaryText};
  margin-bottom: 24px;
`;

export const HR = styled.hr`
  width: 400px;
  border: 1px solid ${(props) => props.theme.primary};
  margin: 0;
  margin-bottom: 27px;
  transform: translateX(-24px);
`;

interface PredictProps {
  background?: string;
  border?: string;
  active?: boolean;
  onClick?: any;
}

export const Predict = styled.div<PredictProps>`
    background: ${(props) =>
      props.background}; //linear-gradient(180deg, rgba(46, 51, 57, 0) 41.67%, #69CC8D 100%);
    /* border:${(props) => props.border}; // 1px solid #69CC8D; */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
    width:160px;
    height: 92px;
    cursor: pointer;
    border:${(props) => (!props.active ? props.border : "1px solid #1C7EFF")};
`;

export const AddButton = styled.button`
padding: 14px 22px;
background: transparent;
border: 1px solid rgba(28, 126, 255, 0.1);
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
border-radius: 8px 0px 0px 8px;
cursor: pointer;
color: ${(props) => props.theme.secondaryText};
:hover{
border: 1px solid #1C7EFF;
}
::before{
  content: url(${add});
  margin-right: 4px;
}
`;

export const RemoveButton = styled(AddButton)`
border-radius: 0px 8px 8px 0px;
::before{
  content: url(${remove});
  margin-right: 4px;
}
`;