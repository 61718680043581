import React from "react";
import styled from "styled-components";
import { FlexContainer } from "./style";

interface I_FlexProps {
  children?: React.ReactNode;
  onClick?: any;
}

export const FlexBox = (props: I_FlexProps) => {
  const { children, onClick } = props;
  return <FlexContainer>{children}</FlexContainer>;
};
