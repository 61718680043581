/** custom module import of the contract instance */
import contractInstance from '../instances/contractInstance'
import { volumeManagerAddress } from './Config'
import volumeManagerAbi from '../abi/volumeManager.json'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {string} account - wallet address of user.
 * @param {any} library - wallet connection provider.
 */

/**This function returns last 30 days volume */
const volumeManagerContract = async (account: string, library: any) => {
  try {
    const volumeManagerInstance = await contractInstance(
      account,
      library,
      volumeManagerAbi['abi'],
      volumeManagerAddress
    )

    const volume = await volumeManagerInstance.methods.fetchLast30DaysVolume(account).call()
    return volume
  } catch (error) {
    console.log('error from volumecontract', error)
  }
}

export default volumeManagerContract
