import React from 'react'
import { useMemo } from 'react'
import { Text } from '../../../../../shared/styles/typography'

export default function usePositionColumns() {
  function handleRoundDecimals(amount: any) {
    if (amount >= 0.1) {
      return (amount % 1 !== 0) ? amount.toFixed(2) : amount;
    }
    else return '<0.1'
  }
  const positionColumns = useMemo<any>(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Position ID
          </div>
        ),
        accessor: 'positionID',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {cell.value.toUpperCase()}
          </Text>
        ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Position
          </div>
        ),
        accessor: 'swappedPositionNotional',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) =>
          cell.value &&
          <Text color={cell.value >= 0 ? "#CF3E40" : "#69CC8D"} fontSize={16} fontWeight={700}>
            {cell.value >= 0 ? 'Short' : "Long"}
          </Text>

      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Collateral
          </div>
        ),
        accessor: 'collateralForPosition',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {handleRoundDecimals(parseFloat(cell.value))} USD
          </Text>
        ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Market
          </div>
        ),
        accessor: 'marketRef.poolName',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {cell.value.toUpperCase()}
          </Text>
        ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Leverage
          </div>
        ),
        accessor: 'leverage',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {Math.abs(cell.value).toFixed(2)}x
          </Text>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            PNL
          </div>
        ),
        accessor: 'realizedPnl',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) =>
          cell.value &&
          <Text color={cell.value <= 0 ? "#CF3E40" : "#69CC8D"} fontSize={16} fontWeight={700} >
            {Math.abs(cell.value).toFixed(2)}
          </Text >
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Position Size
          </div>
        ),
        accessor: 'swappedPositionSize',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {handleRoundDecimals(Math.abs(cell.value))} ETH
          </Text>
        ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Liquidation
          </div>
        ),
        accessor: 'liquidationPrice',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            $ {cell.value ? handleRoundDecimals(parseFloat(cell.value)) : "0.00"}
          </Text>
        ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Price
          </div>
        ),
        accessor: 'marketPriceAfter',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {parseFloat(cell.value).toFixed(2)} USD
          </Text>
        ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Status
          </div>
        ),
        accessor: 'positionStatus',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {cell.value.toUpperCase()}
          </Text>
        ),
      },
    ],
    []
  )
  return positionColumns
}
