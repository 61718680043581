import styled from "styled-components";
import { FontStyles } from "../style";
import { I_NavItemProps } from "../types";

export const Items = styled.p<I_NavItemProps>`
cursor: pointer;
${FontStyles}
font-size: 16px;
font-weight:${(props)=>props.active ? '700' :'400' };
line-height: 24px;
color: ${(props)=>props.active ? props.theme.primaryText : props.theme.secondaryText }; 
padding: 0 12px 16px 12px ;
margin: 0 36px;
${(props)=>props.active ? "border-bottom: 4px solid #1C7EFF;" :"" }; 
`;

export const FlexContainer = styled.div<any>`
padding: 64px 60px 0 60px;
display : flex ;
justify-content :${(props)=> props.justifyContent ? props.justifyContent :'flex-start' };
align-items : center;
flex-flow : row wrap;
`;