import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Items } from '../styles';
import { PositionDetails } from './PositionDetails';
import { PositionTabWrapper, FlexContainer } from './styles'
import icon from '../../../../../assets/images/eth.svg'
import { useWeb3React } from '@web3-react/core';
import { API_URL } from '../../../../../blockchain/helper/Config'
import { SharedCard } from '../../../../../shared/sharedCard';
import { Text } from '../../../../../shared/styles/typography';
import NotFoundImage from '../../../../../assets/images/notFound.svg'
import { Flex } from '../../../../../shared/shared';
import ReactLoading from 'react-loading'
import { NoDataFound } from '../../../../../shared/noDataFound';

interface Props {
  selectedToken?: string,
  selectedLogo?: string
  baseTokenAddr?: string
  poolAddress?: string
  positionTransactionSuccess?: boolean
  setPositionTransactionSuccess?: any
}

const PositionTab = (props: Props) => {
  const { selectedLogo, selectedToken, baseTokenAddr, poolAddress, positionTransactionSuccess,
    setPositionTransactionSuccess } = props
  const { account, library, chainId } = useWeb3React()
  const types = ["Positions", "Orders"];
  const [active, setActive] = useState(types[0]);
  const [loading, setLoading] = useState(false)
  const [positionDetails, setPositionDetails] = useState<any>([])

  const tradePositionDetails = () => {
    if (account) {
      setLoading(true)
      try {
        axios.get(API_URL + "trade/cross_isolatepositions",
          {
            params: {
              wallet_address: `${account}`,
              maker_address: `${baseTokenAddr}`
            },
          }
        ).then((res) => {
          if (res.data.message === 'success') {
            res.data.data && setPositionDetails(res.data.data);
            setPositionTransactionSuccess(false)
          }
          else {
            setPositionDetails([])
            setPositionTransactionSuccess(false)
          }
          setLoading(false)
        })
      } catch (error) {
        console.log("trade response error", error)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (positionTransactionSuccess) {
      setTimeout(() => {
        tradePositionDetails()
      }, 5000)
    } else {
      tradePositionDetails()
    }
  }, [account, chainId, positionTransactionSuccess, baseTokenAddr])

  return (
    <PositionTabWrapper>
      <FlexContainer>
        {types.map((type, index) => (
          <Items
            key={index}
            active={active === type}
            onClick={() => {
              setActive(type);
            }}
          >
            {type}
            <br />
          </Items>
        ))}
      </FlexContainer>
      <SharedCard background="transparent" margin="0 0 0 0" padding="0 0 0 16px" scrollY={true} height="300px">
        {!account ?
          <Flex flexDirection='column' margin='12px 0 0 0'>
            <img src={NotFoundImage} />
            <Text color="primary" textAlign="center" fontWeight={500} margin="16px 0 0 0px" fontSize={24}>
              Wallet not connected
            </Text>
            <Text color="primary" textAlign="center" fontWeight={500} margin="16px 0 0 0" fontSize={16}>
              Please connect your wallet.
            </Text>
          </Flex>
          :
          !loading ?
            positionDetails && positionDetails.length > 0 ?
              positionDetails.map((positionData: any, index: number) => {
                return (
                  <FlexContainer key={index}>
                    <PositionDetails
                      symbol={selectedLogo ? selectedLogo : icon}
                      tokenName={selectedToken ? selectedToken : "ETH"}
                      groupID={positionData.groupID}
                      positionData={positionData}
                      baseTokenAddr={baseTokenAddr}
                      poolAddress={poolAddress}
                      setPositionTransactionSuccess={setPositionTransactionSuccess}

                    />

                  </FlexContainer>
                )
              }) :
              <>
                <Flex flexDirection='column' margin='12px 0 0 0'>
                  <img src={NotFoundImage} />
                  <Text color="primary" textAlign="center" fontWeight={500} margin="16px 0 0 0px" fontSize={24}>
                    No Positions Found
                  </Text>
                  <Text color="primary" textAlign="center" fontWeight={500} margin="16px 0 0 0" fontSize={16}>
                    Looks like you haven't made any positions yet.
                  </Text>
                </Flex>
              </>
            :
            <Flex>
              <ReactLoading type={'spinningBubbles'} color="#1c7eff" />
            </Flex>
        }
      </SharedCard>
    </PositionTabWrapper>
  )
}

export default PositionTab
