import React from 'react'
import { useMemo } from 'react'
import { Text } from '../../../../../shared/styles/typography'

export default function useOrdersColumns() {
  const orderColumns = useMemo<any>(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            ID
          </div>
        ),
        accessor: 'positionID',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {parseFloat(cell.value)}
          </Text>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Position
          </div>
        ),
        accessor: 'swappedPositionNotional',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) =>
          cell.value >= 0 ? (
            <Text color="#CF3E40" fontSize={16} fontWeight={700}>
              Short
            </Text>
          ) : (
            <Text color="#69CC8D" fontSize={16} fontWeight={700}>
              Long
            </Text>
          ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Market
          </div>
        ),
        accessor: 'marketRef.poolName',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {cell.value.toUpperCase()}
          </Text>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Realized PNL
          </div>
        ),
        accessor: 'realizedPnl',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) =>
          cell.value <= 0 ? (
            <Text color="#CF3E40" fontSize={16} fontWeight={700}>
              $ {parseFloat(cell.value).toFixed(2)}
            </Text>
          ) : (
            <Text color="#69CC8D" fontSize={16} fontWeight={700}>
              $ {parseFloat(cell.value).toFixed(2)}
            </Text>
          ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Position Size
          </div>
        ),
        accessor: 'swappedPositionSize',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {parseFloat(cell.value).toFixed(4)} ETH
          </Text>
        ),
      },

      // {
      //   Header: () => (
      //     <div
      //       style={{
      //         textAlign: 'left',
      //       }}
      //     >
      //       Liquidation
      //     </div>
      //   ),
      //   accessor: 'liquidationPrice',
      //   Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
      //     <Text color="primary" fontSize={14} fontWeight={700}>
      //       $ {cell.value}
      //     </Text>
      //   ),
      // },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Price
          </div>
        ),
        accessor: 'marketPriceAfter',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {parseFloat(cell.value).toFixed(2)} USD
          </Text>
        ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Status
          </div>
        ),
        accessor: 'positionStatus',
        Cell: (cell: { accessor: {} | null | undefined; value: any }) => (
          <Text color="primary" fontSize={14} fontWeight={700}>
            {cell.value.toUpperCase()}
          </Text>
        ),
      },
    ],
    []
  )
  return orderColumns
}
