import React, { useEffect, useState } from 'react'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import moment from 'moment'
import gql from 'graphql-tag'

import { Flex } from '../../../../../shared/shared'
import { SharedCard } from '../../../../../shared/sharedCard'
import { Text } from '../../../../../shared/styles/typography'
import { Sub_Graph_Url } from '../../../../../blockchain/helper/Config'
import { marketTradeQuery } from '../../../../../utils/Queries'
import { FlexRow } from '../../../../../styles/styled'
import { NoDataFound } from '../../../../../shared/noDataFound'

interface Props {
  baseTokenAddr : any;
 }

const MarketTrades = (props: Props) => {
  const [data, setData] = useState<any>([])
  const baseToken = props.baseTokenAddr
  const client = new ApolloClient({
    uri: Sub_Graph_Url,
    cache: new InMemoryCache(),
  })

  const currentTimeStamp = moment().unix()

  //getting the markettrades and storing it in data object
  useEffect(() => {
    client
      .query({
        query: gql(marketTradeQuery(currentTimeStamp, baseToken)),
      })
      .then((data: any) => {
        setData(data.data.oddzPositions)
      })
      .catch((err: any) => {
        console.log('Error fetching data: ', err)
      })
  }, [baseToken])

  return (
    <SharedCard background="primary" width="340px" margin="38px 0 0 0" padding="20px 8px 20px 0" border='1px solid #1C7EFF'>
      <Flex justifyContent="space-between" margin="0 12px 8px 0">
        <Text fontSize={16} fontWeight={500} lineHeight={24} color="primary" margin="0 0 16px 16px">
          Market Trades
        </Text>
        <Text fontSize={12} fontWeight={500} lineHeight={18} color="secondary" margin="0 16px 16px 0">
          24hr
        </Text>
      </Flex>
      <SharedCard background="primary" margin="0 0 0 0" padding="0 0 0 16px" scrollY={true} height="300px">
        <FlexRow justifyContent="space-between" margin="0 12px 8px 0">
          <Text fontSize={14} fontWeight={500} lineHeight={21} color="primary" width='74px'>
            Size
          </Text>
          <Text fontSize={14} fontWeight={500} lineHeight={21} color="primary" width='74px'>
            Price
          </Text>
          <Text fontSize={14} fontWeight={500} lineHeight={21} color="primary" width='74px'>
            Time
          </Text>
        </FlexRow>

       { data.map((item: any) => (
          <FlexRow justifyContent="space-between" margin="16px 12px 8px 0">
            <Text fontSize={14} fontWeight={500} lineHeight={21} color="primary" width='74px'>
              {item.swappedPositionSize?.slice(0, 5)}
            </Text>
            <Text fontSize={14} fontWeight={500} lineHeight={21} color="primary" width='74px'>
              ${item.marketPriceAfter?.slice(0, 7)}
            </Text>
            <Text fontSize={14} fontWeight={500} lineHeight={21} color="primary" width='74px'>
              {moment.unix(item.timestamp).format('HH:mm A')}
            </Text>
          </FlexRow>
        ))}
      </SharedCard>
    </SharedCard> 
  )
}

export default MarketTrades
