import React from 'react'
import { Text } from '../styles/typography'
import { Container, GridContainer } from './style'
import NotFoundImage from '../../assets/images/notFound.svg'

interface Props {
    heading ?   : string;
    text ?: string;
    img ?: string;
}

export const NoDataFound = (props: Props) => {
    const {heading, text, img } = props
    return (
        <Container>
            <GridContainer>
          <img src={img || NotFoundImage} />
           <Text color="primary" textAlign="center" fontWeight={500} margin="16px 0 0 0px" fontSize={24}>
            { heading || 'No Order Found' }
           </Text>
           <Text color="primary" textAlign="center" fontWeight={500} margin="16px 0 0 0" fontSize={16}>
             {text || "Looks like you haven't made any order yet." }
           </Text>
           </GridContainer>
        </Container>
    )
}
