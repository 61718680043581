/** custom module import of the contract instance */
import contractInstance from '../instances/contractInstance'
import { oddzVault } from './Config'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {string} account - wallet address of user.
 * @param {any} library - wallet connection provider.
 * @param {object} abi - abi of contract.
 * @param {string} tokenAddress - address of token.
 */
const allowanceOfToken = async (account: string, library: any, abi: object, tokenAddress: string) => {
  const tokenContractInstance = await contractInstance(account, library, abi, tokenAddress)
  const allowance = await tokenContractInstance.methods.allowance(account, oddzVault).call()
  return allowance
}

export default allowanceOfToken
