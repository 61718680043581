/**APi URL For Local and Development */
// export const API_URL = 'https://oddz-api.dev.rapidinnovation.tech/api/v1/'

/**APi URL For Staging */
export const API_URL = 'https://api-oddz.dev.rapidinnovation.tech/api/v1/'

/** Sub-graph URL */
export const Sub_Graph_Url = 'https://api.thegraph.com/subgraphs/name/dravidsajin5/oddzpricesfixes'

/** sepolia RPC-URL */
export const rpcURL = 'https://sepolia-rollup.arbitrum.io/rpc'
//'https://rpc-mumbai.maticvigil.com'
//'https://arb-goerli.g.alchemy.com/v2/QLnfdg3gX2ku8oV08UZqwY0ge59hDW82'

/** Address of the contract used in the app */

/** vault contract address */
export const oddzVault = '0xf390F071016616B1031e8c7368ea98D9d1557c8d'

/** usdc address */
export const usdcTokenAddress = '0x2a71f33E62f87DD6Ea794d59C738c2e78b8f33f6'

/**quote token address */
export const quoteTokenAddress = '0x2381efbBda63BF35B69093760740074FD31D40b9'

/** clearingHouse Address */
export const clearingHouseAddress = '0xEA717E374BFe0596B068c68E6A364693693CEC4C'

/** clearin house extended address */
export const clearingHouseExtendedAddress = '0x5dF6D1dEFAcBa0b3dF7DaaF67080C54b16731A22'

/** vEth address */
export const vEth_Address = '0x1993D10514D45b705A303B8A7b1f3637f4Ddb490'

/** vBtc address */
export const vBtc_Address = '0x0657601487797Aa8a4d0CCF6259211E4B276FE30'

/** vMatic address */
export const vMatic_Address = '0x11175bE4Ecdc52B4e35D58b1BB86824BBAfDEa8F'

/** vLink address */
export const vLink_Address = '0x1165523530AC7b1EFaBd6dBf803dA7DaB759a4A6'

/** vSol address */
export const vSol_Address = '0x1cca0ebD47466955FE3Ef3e4Bb8017dF1ab2465c'

/**Swap router address */
export const uniSwapRouter = '0x20E0da5141d328A67f8Ed0703dE9E3e5D0863683'

/**Balance manager address */
export const balanceManager = '0x655b62c2a4A077Cd083228785c66975Cc114208E'

/** Order Manager address */
export const orderManager = '0x94e219a9B32AE979663C53B1ef5fE0CF9204F0E5'

/**Oddz market address */
export const oddzMarketAddress = '0x1ba16Ad67758e3ec512C7C961794970105e3c192' //'0x860Bf639127550Bab3529bD7f084FB1077A5Ef8D'

/**Oddz config address */
export const oddzConfig = '0x9e18D6C28d84355ef9fE79f76FEfED16e9FA6934'

/**volume manager address */
export const volumeManagerAddress = '0xE43C148095334CDEc52d3a9427F98dd724900048'

/** vEth and vUSD pool address  */
export const VETH_USD_PairAddress = '0x4C3Acb840e281a09DA6D327110734865F2107ce4'

/** vBTC and vUSD pool address  */
export const VBTC_USD_PairAddress = '0xC3766aAD1fa13C4D14B742DE28a8fE19fe2EDD35'

/** vBTC and vUSD pool address  */
export const VMATIC_USD_PairAddress = '0xbC925e8BcEa2Fc9944f494c9d25d8768a3EEa102'

/** vBTC and vUSD pool address  */
export const VLINK_USD_PairAddress = '0x84E7cE634Bb8bD27d5326b3663895445F718b58d'

/** vBTC and vUSD pool address  */
export const VSOL_USD_PairAddress = '0x6d0b3B9aE87C3E9e21c3C39a0a606C03cF684b4d'
