import React, { useEffect, useState } from "react";
import axios from 'axios'

import { useWeb3React } from "@web3-react/core";
import { ThemeProps, withTheme } from "styled-components";

import { Text } from "../../../../../shared/styles/typography";
import ReactLoading from "react-loading";
import { Flex } from "../../../../../shared/shared";
import { useLocation, useNavigate } from "react-router-dom";
import back from '../../../../../assets/images/back.svg'; 
import { API_URL } from "../../../../../blockchain/helper/Config";
import usePositionColumns from "./usePositionColumns";
import { Paginated } from "../../../../../shared/pagination/pagination";

export const GroupView: React.FC = withTheme((props: ThemeProps<any>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
  const { account } = useWeb3React();
  const columns = usePositionColumns();

  /** getting the user position  and storing it in data object */
  const getPositionData = () => {
    setLoading(true)
    axios
    .get(API_URL + 'trade/group_positions', {
      params: {
        group_id: location.pathname.split('/')[2],
        wallet_address: account
      },
    })
    .then((res) => {
      setData(res.data.data);
      setLoading(false)  })
    .catch((error) => {
      console.log(error);
      setLoading(false)
    })
  }
  
    useEffect(() => {
      account && getPositionData()
    }, [account])

  return (
    <>
      <Flex justifyContent='space-between' margin='64px 80px 0 80px'>
        <Text fontSize={16} fontWeight={500} lineHeight={24} color="primary" margin='0 0 16px 0'>
          Group ID {location.pathname.split('/')[2]}
        </Text>
        <Flex alignItems='center' onClick={() => navigate('/home')}>
          <img src={back} style={{ marginBottom: '12px' }} />
          <Text fontSize={16} fontWeight={500} lineHeight={24} color="primary" margin='0 0 16px 8px' onClick={() => navigate('/position')}>
            Back
          </Text>
        </Flex>
      </Flex>
      {!loading ? (
        data.length > 0 ? (
          <Paginated data={data} columns={columns} /> 
        ) : (
          <Text
            color="primary"
            textAlign="center"
            margin="16px 0 0 0"
            fontSize={16}
          >
            No Data to Display
          </Text>
        )
      ) : (
        <Flex>
          <ReactLoading type={"spinningBubbles"} color="#1c7eff" />
        </Flex>
      )}
    </>
  );
});
