import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import { oddzVault, usdcTokenAddress } from '../../../../blockchain/helper/Config'
import DepositedBalance from '../../../../blockchain/helper/depositedBalance'
import { TwoColumns } from '../../../../shared/basicGrid'
import { SharedCard } from '../../../../shared/sharedCard'
import { Text } from '../../../../shared/styles/typography'
import { feeStructure } from '../../../../utils/constants'
import { FeesContainer, TableWrapper } from './styles'
import usdcabi from '../../../../blockchain/abi/usdc.json'
import vaultabi from '../../../../blockchain/abi/vault.json'
import volumeManagerContract from '../../../../blockchain/helper/volumeContract'
import { weiToEth } from '../../../../blockchain/helper/Converstion'

export const Fees = () => {

  const [depositedAmt, setDepositedAmt] = useState<string>('')
  const [last30DaysVolume, setLast30DaysVolume] = useState('0.00')

  const { account, library, chainId } = useWeb3React()

  /** This is function is used to get the deposited balance */
  const getDepositedBalance = async () => {
    if (account) {
      const deposited = await DepositedBalance(
        account,
        library,
        vaultabi['abi'],
        oddzVault,
        usdcabi['abi'],
        usdcTokenAddress
      )
      if (deposited) {
        setDepositedAmt(deposited)
      }
    }
  }

  /**This function is to get the last 30 days volume */
  const volumeManagerFunction = async () => {
    if (account) {
      const volume = await volumeManagerContract(
        account,
        library
      )
      const volumeInEth = weiToEth(volume, 18)
      setLast30DaysVolume(volumeInEth)
    }
  }

  /**This useEffect is used for calling getDepositedBalance & volumeManagerFunction functions. */
  useEffect(() => {
    if (account) {
      getDepositedBalance()
      volumeManagerFunction()
    }
  }, [account, chainId])

  return (
    <FeesContainer>
      <TwoColumns>
        <SharedCard width="100%" background="primary" border="1px solid #1C7EFF" padding="22px 32px">
          <Text fontSize={21} lineHeight={32} fontWeight={500} color="secondary">
            Trailing Volume
          </Text>
          <Text fontSize={24} lineHeight={36} fontWeight={500} color="primary">
            {last30DaysVolume ? Math.abs(parseFloat (last30DaysVolume)).toFixed(4) : "0.00"}ml
          </Text>
          <Text fontSize={16} lineHeight={24} fontWeight={500} color="primary" margin="24px 0 0 0">
            over the last 30 days
          </Text>
        </SharedCard>
        <SharedCard width="100%" background="primary" border="1px solid #1C7EFF" padding="22px 32px">
          <Text fontSize={21} lineHeight={32} fontWeight={500} color="secondary">
            Account Value
          </Text>
          <Text fontSize={24} lineHeight={36} fontWeight={500} color="primary">
            ${account && depositedAmt ? parseFloat(depositedAmt).toFixed(4) : '0.00'}
          </Text>
          <Text fontSize={16} lineHeight={24} fontWeight={500} color="primary" margin="24px 0 0 0">
            currently held
          </Text>
        </SharedCard>
      </TwoColumns>
      <TwoColumns>
        <SharedCard>
          <Text fontSize={16} lineHeight={24} fontWeight={700} color="primary" margin="0 0 16px 0">
            Fee Discount
          </Text>
          <TableWrapper>
            <table>
              <tr>
                <th>Tier</th>
                <th>Balance</th>
                <th>Discount</th>
              </tr>
              {feeStructure.map((type) => (
                <tr>
                  <td>{type.tier}</td>
                  <td>{type.balance}</td>
                  <td>{type.discount}</td>
                </tr>
              ))}
            </table>
          </TableWrapper>
        </SharedCard>
        <SharedCard>
          <Text fontSize={16} lineHeight={24} fontWeight={700} color="primary" margin="0 0 16px 0">
            Fee Structure
          </Text>
          <TableWrapper>
            <table>
              <tr>
                <th>Tier</th>
                <th>Volume (30D)</th>
                <th>Maker</th>
                <th>Taker</th>
              </tr>
              {feeStructure.map((type) => (
                <tr>
                  <td>{type.tier}</td>
                  <td>{type.volume}</td>
                  <td>{type.maker}</td>
                  <td>{type.taker}</td>
                </tr>
              ))}
            </table>
          </TableWrapper>
        </SharedCard>
      </TwoColumns>
    </FeesContainer>
  )
}
