import * as React from 'react';
import { Close, ModalBody, ModalContent, ModelHead } from './style';
import close from '../../assets/images/close-button.svg';
import closeDark from '../../assets/images/closeDark.svg'
import { I_ModalProps } from '../../modules/app/components/types';

const CustomModal = (props: I_ModalProps) => {
  const { show, toggleModal, borderRadius, heading, styles, background, boxShadow, width, padding } = props;
  const handleClickOutside = (e: any) => {
    if (e.target === e.currentTarget) {
      toggleModal();
    }
  };

  return (
    <ModalBody show={show} onMouseDown={handleClickOutside} style={{ ...styles }}>
      <ModalContent borderRadius={borderRadius} background={background} boxShadow={boxShadow} width={width} padding={padding}>
        <ModelHead>
          <div>
          {heading}
          </div>
          {heading !== 'Switch Network' && 
          <Close
            onClick={() => toggleModal(!show)}
            src={localStorage.getItem('theme') === 'light' ? closeDark : close }
          />
          }
        </ModelHead>
        {props.children}
      </ModalContent>
    </ModalBody>
  );
};
export default CustomModal;
