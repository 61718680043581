import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useWeb3React } from '@web3-react/core'
import { ThemeProps, withTheme } from 'styled-components'

import ReactLoading from 'react-loading'
import { Flex } from '../../../../../shared/shared'
import { API_URL } from '../../../../../blockchain/helper/Config'
import usePositionColumns from './usePositionColumns'
import { Paginated } from '../../../../../shared/pagination/pagination'
import { NoDataFound } from '../../../../../shared/noDataFound'

export const MyPositions: React.FC = withTheme((props: ThemeProps<any>) => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { account } = useWeb3React()
  const columns = usePositionColumns()

  /** Function to get all positions data from APIs */
  const getPositionData = () => {
    setLoading(true)
    axios
    .get(API_URL + 'trade/positions', {
      params: {
        wallet_address: account,
        // page_number: 1
      },
    })
    .then((res) => {
      res.data.data.positionsData && res.data.data.positionsData.sort((a:any, b:any) => b.positionID - a.positionID) && setData(res.data.data.positionsData)
      setLoading(false)
     })
    .catch((error) => {
      console.log(error);
      setLoading(false)
    })
  }

  /** getting the user orders  and storing it in data object */
  useEffect(() => {
    setData([])
    account && getPositionData()
  }, [account])

  return (
    <>
      {!loading ? (
        data.length > 0 ? (
          <Paginated data={data} columns={columns} /> 
        ) : (
          <NoDataFound heading='No Positions Found' text="Looks like you haven't made any positions yet" />
          )
      ) : (
        <Flex>
          <ReactLoading type={'spinningBubbles'} color="#1c7eff" />
        </Flex>
      )}
    </>
  )
})
