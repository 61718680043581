import styled, { css } from "styled-components";
import explore from "../assets/images/explore.svg";
interface I_ButtonProps {
  disabled?: boolean;
  width?: string;
  color?: string;
  padding?: string;
  onClick?: any;
  margin?: string;
  textColor?: string;
  borderRadius ?: string;
}
const FontStyles = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
`;

export const SharedTitle = styled.div`
  font-size: 56px;
  overflow-wrap: normal;
  font-weight: 600;
  margin: 10px 0;

  span {
    font-weight: 100;
    margin-right: 12px;
    margin: 0;
    padding-right: 10px;
  }
`;
export const SharedDescription = styled.p`
  font-weight: 300;
  max-width: 780px;
  font-size: 24px;
  line-height: 30px;
  margin: 0px;
`;
export const SharedButton = styled.button<I_ButtonProps>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${FontStyles}
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${(props) => (props.textColor ? props.textColor : "#ffffff")};
  background: ${(props) => (props.color ? props.color : "#1C7EFF")};
  mix-blend-mode: normal;
  border: 1.5px solid ${(props) => (props.color ? props.color : "#1C7EFF")};
  box-sizing: border-box;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "6px")};
  padding: ${(props) => (props.padding ? props.padding : "12px auto")};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
`;

export const ExploreButton = styled(SharedButton)`
  ::before {
    content: url(${explore});
    position: relative;
    top: 3px;
    margin-right: 4px;
  }
`;

export const SharedDetailBlock = styled.div`
  color: #ffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 48px;
    font-weight: 700;
  }
  .description {
    font-size: 14px;
    margin: 5px 0;
    font-weight: 700;
  }
`;

export const SharedForum = styled.div`
  border: 1px solid #ffff;
  padding: 10px 25px;
  font-weight: 300;
  font-size: 24px;
  border-radius: 8px;
`;
interface I_FlexProps {
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  onClick?: any;
  gap?: string;
  margin?: string;
  width?: string;
  padding?: string;
  height?: string;
  borderBottom?: string;
  minHeight?: string;
  scrollX?: boolean;
}

export const Flex = styled.div<I_FlexProps>`
  display: flex;
  /* flex: 1 1 0 0; */
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  /* flex-flow: row wrap; */
  cursor: ${(props) => (props.onClick ? "pointer" : "auto")};
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  height:${(props) => props.height};
  border-bottom:${(props) => props.borderBottom};
  min-height:${(props) => props.minHeight};
  overflow-x: ${(props) => props.scrollX ? 'auto' : '' };
  ::-webkit-scrollbar {
    display: none;
  }

  @media(max-width: 700px){
    /* justify-content: center; */
    flex-direction: column;
  }
`;


