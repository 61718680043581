import React from 'react'
import { useMemo } from "react";
import { Text } from "../../../../../shared/styles/typography";
import SimpleDateTime  from 'react-simple-timestamp-to-date';

export default function useFundingColumns() {
  const useFundingColumns = useMemo<any>(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign:"left"
            }}
          >Time</div>),
                  accessor: "timestamp",
                  Cell: (cell: { accessor: {} | null | undefined; value:any; }) => (
                    <Text color='primary' fontSize={14} fontWeight={500} >
                            <SimpleDateTime dateFormat="DMY" dateSeparator="/"  timeSeparator=":">{cell.value}</SimpleDateTime>
                    </Text> 
                               )
      },

      {
        Header: () => (
          <div
            style={{
              textAlign:"left"
            }}
          >Market</div>),
                  accessor: "marketRef.poolNameMod",
                  Cell: (cell: { accessor: {} | null | undefined; value:any; }) => (
                    <Text color='primary' fontSize={14} fontWeight={700} >{cell.value.toUpperCase()}</Text>
                     )
      },
      {
        Header: () => (
          <div
            style={{
              textAlign:"left"
            }}
          >Funding Payments</div>),
                  accessor: "fundingPayment",
                  Cell: (cell: { accessor: {} | null | undefined; value:any; }) => (
                    <Text color='primary' fontSize={14} fontWeight={700} >{parseFloat(cell.value).toFixed(4)}</Text>
                     )
      },

      // {
      //   Header: () => (
      //     <div
      //       style={{
      //         textAlign:"left"
      //       }}
      //     >Status</div>),
      //             accessor: "status",
      //             Cell: (cell: { accessor: {} | null | undefined; value:any; }) => (
      //               cell.value === 'Pending' ? <Text color='#E1574B' fontSize={14} >{cell.value}</Text> : <Text color='#69CC8D' fontSize={14} >{cell.value}</Text>
      //                )
      //   }
    ],
    []
  );

  return useFundingColumns;
}