import React, { useEffect, useState } from 'react'
import { ApolloClient, InMemoryCache } from '@apollo/client'

import { useWeb3React } from '@web3-react/core'
import gql from 'graphql-tag'
import { ThemeProps, withTheme } from 'styled-components'
import useHistoryColumns from './useHistoryColumns'
import ReactLoading from 'react-loading'
import { Flex } from '../../../../../shared/shared'
import { Sub_Graph_Url } from '../../../../../blockchain/helper/Config'
import { historyQuery } from '../../../../../logic/graphQL/queries'
import { Paginated } from '../../../../../shared/pagination/pagination'
import { NoDataFound } from '../../../../../shared/noDataFound'

export const DepositsWithdraw: React.FC = withTheme((props: ThemeProps<any>) => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const client = new ApolloClient({
    uri: Sub_Graph_Url,
    cache: new InMemoryCache(),
  })

  const { account } = useWeb3React()
  const columns = useHistoryColumns()

  /** getting the user deposited colleterals and storing it in data object */
  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: gql(historyQuery(account)),
      })
      .then((data: any) => {
        setData(data.data.oddzWithdrawDeposits)
        setLoading(false)
      })
      .catch((err: any) => {
        setLoading(false)
      })
  }, [account])

  return (
    <>
          {!account ? <NoDataFound heading='Wallet not connected' text='Please connect your wallet.'/>
          :
      !loading ? (
        data.length > 0 ? ( <Paginated data={data} columns={columns} /> ) : 
        (
          <NoDataFound heading='No History Found' text="Looks like you haven't made any Deposits / Withdraw yet."/>
        )
      ) : (
        <Flex>
          <ReactLoading type={'spinningBubbles'} color="#1c7eff" />
        </Flex>
      )}
    </>
  )
})
