export const historyQuery = (account: string | null | undefined) => {
    return `
    query {
      oddzWithdrawDeposits (
        where: {  traderAddress:"${account}"}
        orderBy: timestamp, orderDirection: desc ) {
        id
        timestamp 
        traderAddress
        eventType
        amount
      }
    }
  `
}

export const ordersQuery = (account: string | null | undefined) => {
  return `query { 
    oddzPositions(where: {  trader:"${account}",} orderBy:timestamp, orderDirection: desc) {
      id
         positionID
         trader
         baseToken
         grouped
         leverage
         collateralForPosition
         swappedPositionSize
         swappedPositionNotional
         liquidationPrice
         marketPriceAfter
         realizedPnl
         timestamp
         marketRef {
           id
           poolName
         }
         positionStatus
       }
     }`
}

  export const FundingRateQuery = (baseToken: any) => {
    return `query {
        oddzFundingUpdateds(where:{baseToken: "${baseToken}"}, orderBy:timestamp, orderDirection: desc, first: 1) {
          id
          markTwap
          baseToken
          indexTwap
          dailyFundingRate
          transactionHash
      }
    }`;
  }


  export const fundingChartsQuery = (baseToken: any, rate:any) => {
    if(rate === 1){
      return `query {
        oddzFundingHourDatas(where:{baseToken:  "${baseToken}"}, orderBy:timestamp, orderDirection: asc) {
          fundingPaymentRate
          timestamp
        }
      }`;
    }
    else if (rate === 8){
      return `query {
        oddzFundingEightHourDatas(where:{baseToken:  "${baseToken}"}, orderBy:timestamp, orderDirection: asc) {
          fundingPaymentRate
          timestamp
        }
      }`; 
    }
    else return `query {
      oddzFundingAnnualDatas(where:{baseToken:  "${baseToken}"}, orderBy:timestamp, orderDirection: asc) {
        fundingPaymentRate
        timestamp
      }
    }`; 
  }

  export const fundingQuery = (account: string | null | undefined) => {
    return `
    query {
      oddzUserFundingPaymentSettleds (
        where: {  trader:"${account}"}
        orderBy: timestamp, orderDirection: desc ) {
          fundingPayment
          trader
          timestamp
          marketRef{
            poolNameMod
          }
      }
    }
  `
}
