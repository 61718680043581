import styled from 'styled-components';
interface TextProps {
  color?: string;
  fontWeight?: number | string;
  fontSize?: number;
  fontFamily?: string;
  fontStyle?: string;
  textTransform?: string;
  lineHeight?: number;
  padding?: string;
  margin?: string;
  textAlign?: string;
  opacity ?: number;
  onClick ?: any;
  ls?:string
  width ?: string;
}
interface colorsUsed {
  black?: string;
  grey?: string;
  white?: string;
  darkGrey?: string;
  button?: string;
}
export const colors: colorsUsed = {
  black: '#000000',
  white: '#FFFFFF',
  button: 'linear-gradient(90deg, #B91372 0%, #6B0F1A 100%)',
};

export const Text = styled.div<TextProps>`
  color: ${({ color }) => color || 'black'};
  color: ${(props) => (props.color === 'primary'? props.theme.primaryText : props.color === 'secondary' ? props.theme.secondaryText : props.color)};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  font-size: ${({ fontSize }) => (fontSize || '28') + 'px'};
  font-family: ${({ fontFamily }) => fontFamily || 'Poppins'};
  font-style: ${({ fontStyle }) => fontStyle || 'normal'};
  text-transform: ${({ textTransform }) => textTransform};
  line-height: ${({ lineHeight }) => (lineHeight || 32) + 'px'};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  text-align: ${({textAlign}) => textAlign};
  opacity: ${({opacity}) => opacity };
  cursor: ${(props) => props.onClick ? 'pointer' : 'auto'};
  letter-spacing: ${(props) => props.ls ? props.ls : ''};
  width: ${(props) => props.width ? props.width : 'auto'};
`;
