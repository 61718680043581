import React from 'react'
import { I_CardProps } from '../types'
import { Container } from './style'

export const SharedCard = (props: I_CardProps) => {
  const { children, background, boxShadow, width, height, padding, margin, scrollY, scrollX, border } = props
  return (
    <Container
      background={background}
      boxShadow={boxShadow}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
      scrollY={scrollY}
      scrollX={scrollX}
      border={border}
    >
      {children}
    </Container>
  )
}
