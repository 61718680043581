import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import {
  groupPositionsPath,
  historyPath,
  homePath,
  ordersPath,
  poolPath,
  rootPath,
  tradePath,
  calculatorPath,
  feesPath,
} from './paths'
import { Home } from '../../pages/Home'
import { Pool } from '../../pages/pool/Pool'
import { Navbar } from '../navbar/Navbar'
import History from '../../pages/history'
import Trade from '../../pages/trade/Trade'
import { useEagerConnect } from '../../../../blockchain/wallethelper/Walletlistner'
import { NotFoundPage } from '../notFoundPage/NotFoundPage'
import Orders from '../../pages/orders'
import { GroupView } from '../../pages/position/components/GroupView'
import { I_Theme } from '../types'
import Calculator from '../../pages/calculator/Calculator'
import { Fees } from '../../pages/fees/Fees'

const notFoundRoute: RouteDefinition = {
  path: '*',
  element: NotFoundPage,
  protected: false,
  title: '',
}

export const routes: RouteDefinition[] = [
  {
    path: rootPath,
    element: Home,
    protected: false,
    title: 'Home',
    pathType: 0,
  },
  {
    path: homePath,
    element: Home,
    protected: false,
    redirect: rootPath,
    title: 'Home',
    pathType: 0,
  },
  {
    path: tradePath,
    element: Trade,
    protected: false,
    redirect: rootPath,
    title: 'Trade',
    pathType: 0,
  },
  {
    path: poolPath,
    element: Pool,
    protected: false,
    redirect: rootPath,
    title: 'Pools',
    pathType: 0,
  },
  // {
  //   path: positionPath,
  //   element: Positions,
  //   protected: false,
  //   redirect: rootPath,
  //   title: 'Positions',
  //   pathType: 0,
  // },
  {
    path: historyPath,
    element: History,
    protected: false,
    redirect: rootPath,
    title: 'History',
    pathType: 0,
  },
  {
    path: calculatorPath,
    element: Calculator,
    protected: false,
    redirect: rootPath,
    title: 'calculator',
    pathType: 0,
  },
  {
    path: feesPath,
    element: Fees,
    protected: false,
    redirect: rootPath,
    title: 'Fees',
    pathType: 0,
  },
  {
    path: ordersPath,
    element: Orders,
    protected: false,
    redirect: rootPath,
    title: 'Orders',
    pathType: 0,
  },
  {
    path: ordersPath,
    element: Orders,
    protected: false,
    redirect: rootPath,
    title: 'Orders',
    pathType: 0,
  },

  {
    path: groupPositionsPath,
    element: GroupView,
    protected: false,
    redirect: rootPath,
    title: 'Orders',
    pathType: 0,
  },
].concat(notFoundRoute as any) // Ensure that notFound is the last route

export interface RouteDefinition {
  path: string
  protected?: boolean
  redirect?: string
  element?: any
  routes?: RouteDefinition[]
  title?: string
  requires?: any
  pathType?: number
}

function getRouteRenderWithAuth(isLoggedIn: boolean, route: RouteDefinition) {
  if (isLoggedIn === route.protected || !route.redirect) {
    const RouteComponent = route.requires ? route.requires(route.element) : route.element
    return { element: <RouteComponent /> }
  } else {
    return { element: <Navigate replace to={route.redirect} /> }
  }
}

export const RoutesComponent = (props: I_Theme) => {
  const { setTheme, theme } = props
  const isLoggedIn = false
  useEagerConnect()

  const mapRoutes = (route: RouteDefinition, i: number) => {
    const render = getRouteRenderWithAuth(isLoggedIn, route)
    return <Route key={i} path={route.path} {...render} />
  }

  return (
    <>
      <Navbar setTheme={setTheme} theme={theme} />
      <Routes>{routes.map(mapRoutes)}</Routes>
    </>
  )
}
