import styled from "styled-components";

export const FeesContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  margin: 0 auto;
  min-height: auto;
  padding: 48px 60px;
  width: 100%;
  transition: all 300ms ease-in-out;
  align-items: center;
  @media (max-width: 900px) {
    padding: 48px;
  }
`;

export const TableWrapper = styled.div`
  table {
    /* border-collapse: collapse; */
    width: 100%;
    border: 1px solid #3a365f;
    border-radius: 16px;
    border-spacing: 0;
  }

  tr:last-child td:last-child {
    border-radius: 0 0 16px 0;
  }
  tr:last-child td:first-child {
    border-radius: 0 0 0 16px;
  }
  th:last-child {
    border-radius: 0 16px 0 0;
  }
  th:first-child {
    border-radius: 16px 0 0 0;
  }
  th,
  td {
    width: auto;
    background: transparent;
    padding: 24px 12px;
    border-top: 1px solid #3a365f;
    border-bottom: 1px solid #3a365f;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.primaryText};
    text-align: left;
  }
`
