/** Library imports */
import React, { useRef } from "react";
import TradingViewWidget, { Themes } from "react-tradingview-widget";

/** Custom imports */
import { useAppSelector } from "../../../../logic/redux/hooks";

const TradingChart = () => {
  const graphSymbol = useAppSelector(
    (state) => state.graphSymbol.marketGraphSymbol || "ETHUSD"
  );
  const tradingRef = useRef(null);

  return (
    <TradingViewWidget
      symbol={graphSymbol}
      interval={"30"}
      theme={
        localStorage.getItem("theme") === "light" ? Themes.LIGHT : Themes.DARK
      }
      locale="en"
      ref={tradingRef}
      autosize
    />
  );
};

export default TradingChart;
