/** Library imports */
import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core';
import Big from "big.js";
import Slider from 'rc-slider';

/** Custom imports */
import { Flex, SharedButton } from "../../../../../../shared/shared";
import { Text } from "../../../../../../shared/styles/typography";
import { FlexBox } from "../../../../../../shared/flexBox";
import {
    Type,
    Input,
    TypeButton,
    TokenWrapper,
    SliderContainer,
} from "../../styles";
import Token from '../Token';
import PoolAbi from "../../../../../../blockchain/abi/pool.json";
import usdcabi from "../../../../../../blockchain/abi/usdc.json";
import { priceToTick, tickToPrice, TICK_MAX, TICK_MIN } from '../../../../../../blockchain/helper/tick';
import eth from "../../../../../../assets/images/eth.svg";
import usdc from "../../../../../../assets/images/usdc.svg";
import contractInstance from '../../../../../../blockchain/instances/contractInstance';
import { SharedCard } from '../../../../../../shared/sharedCard';
import liquidity from '../../../../../../blockchain/helper/AddLiquidity';
import { ethToWei, weiToEth } from '../../../../../../blockchain/helper/Converstion';
import { negativeValidation, specialCharValidation } from '../../../../../../utils/helper';
import { LeverageBlock } from '../styles';
import { DEADLINE, handleMinReceive } from '../../../../../../blockchain/helper/handleMinMax';
import { FlexRow } from '../../../../../../shared/styles/styles';
import { errorToast } from '../../../../../../blockchain/helper/toasts';
import { useAppDispatch, useAppSelector } from '../../../../../../logic/redux/hooks';
import { set_Transaction_Success } from '../../../../../../logic/redux/actions';

interface Props {
    baseTokenAddress?: string;
    poolAddress?: string;
    selectedToken?: string;
    collateralBal?: number;
    toggleModal?: any;
    marketPrice?: number;
    setLiquidtyTransactionSuccess?: any;
    selectedTokenLogo?: string;
    prePrediction?: string
    poolData?: any;
    showModal?: boolean
}

const AdvancedLiquidity = (props: Props) => {

    const { baseTokenAddress, poolAddress, selectedToken, collateralBal, toggleModal,
        marketPrice, setLiquidtyTransactionSuccess, selectedTokenLogo, prePrediction,
        poolData, showModal } = props
    const { account, library } = useWeb3React();
    const dispatch = useAppDispatch()
    const { transactionHash, transactionSuccess } = useAppSelector((state) => state.graphSymbol)

    const [fullRange, setFullRange] = useState<boolean>(false);
    const [enteredMinMax, setEnteredMinMax] = useState<string>('')
    const [enteredIn, setEnteredIn] = useState<string>('')
    const [minInput, setMinInput] = useState<string>('')
    const [minOrgInput, setMinOrgInput] = useState<string>('')
    const [maxInput, setMaxInput] = useState<string>('')
    const [maxOrgInput, setMaxOrgInput] = useState<string>('')
    const [baseTokenInput, setBaseTokenInput] = useState<string>('')
    const [baseTokenOrgInput, setBaseTokenOrgInput] = useState<string>('')
    const [qouteTokenInput, setQouteTokenInput] = useState<string>('')
    const [qouteTokenOrgInput, setQouteTokenOrgInput] = useState<string>('')
    const [lowerTick, setLowerTick] = useState<number>(0)
    const [upperTick, setUpperTick] = useState<number>(0)
    const [collateralInput, setCollateralInput] = useState<string>('')
    const [totalCost, setTotalCost] = useState<number>(0)
    const [leveragePercentage, setLeveragePercntge] = useState<number>(0)
    const [loading, setLoading] = useState(false)
    const [insufficientToast, setInsufficientToast] = useState<boolean>(false)

    /**This useEffect is to set the full range value by default */
    useEffect(() => {

        setFullRange(prePrediction === "full range")

    }, [marketPrice, prePrediction])

    /**This function is to set the min and max input prices */
    const minMaxDefaultValues = async (marketPrice: number) => {

        if (poolData && prePrediction) {
            if (fullRange) {
                setMinInput('0')
                setMaxInput('∞')
                fullRangesPrices()
            } else {
                if (Number(poolData.lowerTick) === TICK_MIN) {
                    setMinInput('0')
                } else {
                    setMinInput(parseFloat(poolData.originalLowerTick).toFixed(4))
                }
                if (Number(poolData.upperTick) === TICK_MAX) {
                    setFullRange(true)
                    setMaxInput('∞')
                } else {
                    setMaxInput(parseFloat(poolData.originalUpperTick).toFixed(4))
                }
            }

            setMinOrgInput(parseFloat(poolData.originalLowerTick).toString())
            setLowerTick(parseFloat(poolData.lowerTick))

            setMaxOrgInput(parseFloat(poolData.originalUpperTick).toString())
            setUpperTick(parseFloat(poolData.upperTick))
        } else {

            setEnteredMinMax("minInput");
            await getPriceRange(Number(marketPrice - 100), "minInput", false)
            setMinInput((Number(marketPrice) - 100).toFixed(4))
            setMinOrgInput((Number(marketPrice) - 100).toString())

            setEnteredMinMax("maxInput");
            await getPriceRange(Number(marketPrice + 100), "maxInput", false)
            setMaxInput((Number(marketPrice) + 100).toFixed(4))
            setMaxOrgInput((Number(marketPrice) + 100).toString())
        }

    }

    /**This useEffect is to call the minMaxDefaultvalues function */
    useEffect(() => {

        if (marketPrice && !fullRange) {
            minMaxDefaultValues(marketPrice)
        }

    }, [marketPrice, fullRange, prePrediction])

    /**This useEffect is to set the slider value automatically */
    useEffect(() => {

        if (collateralInput) {
            const percentage = ((2 * Number(qouteTokenOrgInput)) / (Number(collateralInput) * 10)) * 100
            setLeveragePercntge(percentage)
        }

    }, [qouteTokenOrgInput, collateralInput])

    /**This function is called on change of slider */
    const leveragePercentageHandler = (value: any) => {

        setLeveragePercntge(Number(value))
        if (collateralInput && qouteTokenInput !== "Out of range" &&
            baseTokenInput !== "Out of range") {
            const USDvalue = ((Number(collateralInput) * 10) * (value / 100)) / 2
            setEnteredIn("quote")
            setQouteTokenInput(USDvalue.toFixed(4))
            setQouteTokenOrgInput(USDvalue.toString())
            baseTokenCalculation(USDvalue)
        }

    }

    /**This useEffect is to calculte the total cost value */
    useEffect(() => {

        if (qouteTokenInput !== "Out of range" &&
            baseTokenInput !== "Out of range") {
            const cost = (Number(marketPrice) * Number(baseTokenOrgInput)) + Number(qouteTokenOrgInput)
            setTotalCost(cost)
        }

    }, [marketPrice, baseTokenOrgInput, qouteTokenOrgInput])

    /**This function is to call the reset all the values */
    const ResetValues = () => {

        setBaseTokenInput('')
        setBaseTokenOrgInput('')
        setQouteTokenInput('')
        setQouteTokenOrgInput('')
        setCollateralInput('')

    }

    /**This useEffect is to reset the values on modal close */
    useEffect(() => {

        ResetValues()

    }, [showModal])

    /**This function is to calculate the base and quote token values on full range automatically */
    const fullRangeHandler = async () => {

        if (qouteTokenInput !== "Out of range" &&
            baseTokenInput !== "Out of range") {
            await getPriceRange(0, "minInput", true)
            if (enteredIn === 'base') {
                qouteTokenCalculation(baseTokenOrgInput)
            } else {
                baseTokenCalculation(qouteTokenOrgInput)
            }
        } else {
            ResetValues()
        }

    }

    /** This useeffect is to calculate base and quote token values in Full Range*/
    useEffect(() => {

        if (fullRange) {
            setMinInput('0')
            setMaxInput('∞')
            fullRangeHandler()
            fullRangesPrices()
        }

    }, [fullRange])

    /**This function is called on change of min range input field */
    const minInputHandler = async (event: any) => {

        setFullRange(false)
        setEnteredMinMax("minInput");
        const value = event.target.value;
        if (
            specialCharValidation.test(value) ||
            negativeValidation.test(value) ||
            value.split(".").length > 2
        ) {
            return false;
        }
        else {
            const valueTobeSet = value.replace(/\s/g, '')
            const newValue = valueTobeSet.split('.', 2)[1]
            if (newValue && newValue.length > 4) {
                setMinInput(minInput);
                setMinOrgInput(minOrgInput)
            } else {
                setMinInput(valueTobeSet);
                setMinOrgInput(valueTobeSet)
            }
        }

    };

    /**This function is called on change of max range input field*/
    const maxInputHandler = async (event: any) => {

        setFullRange(false)
        setEnteredMinMax("maxInput");
        const value = event.target.value;
        if (
            specialCharValidation.test(value) ||
            negativeValidation.test(value) ||
            value.split(".").length > 2
        ) {
            return false;
        }
        else {
            const valueTobeSet = value.replace(/\s/g, '')
            const newValue = valueTobeSet.split('.', 2)[1]
            if (newValue && newValue.length > 4) {
                setMaxInput(maxInput);
                setMaxOrgInput(maxOrgInput)
            } else {
                setMaxInput(valueTobeSet);
                setMaxOrgInput(valueTobeSet)
            }
        }

    };

    /**This function is called on change of base token input field */
    const baseTokenInputHandler = (evt: any) => {

        try {
            setEnteredIn("base")
            const value = evt.target.value
            if (
                specialCharValidation.test(value) ||
                negativeValidation.test(value) ||
                value.split(".").length > 2
            ) {
                return false;
            }
            else {
                const valueTobeSet = value.replace(/\s/g, '')
                const newValue = valueTobeSet.split('.', 2)[1]

                if (newValue && newValue.length > 4) {
                    setBaseTokenInput(baseTokenInput)
                    setBaseTokenOrgInput(baseTokenOrgInput)
                } else {
                    setBaseTokenInput(valueTobeSet)
                    setBaseTokenOrgInput(valueTobeSet)
                }
                if (!valueTobeSet) {
                    setQouteTokenInput('')
                    setQouteTokenOrgInput('')
                }
                if (qouteTokenInput !== "Out of range") {
                    qouteTokenCalculation(Number(value))
                }
            }
        } catch (error) {
            console.log("error", error);
        }

    };

    /** This function calculates the quote token value based on base Token */
    const qouteTokenCalculation = (value: any) => {

        if (Number(value) > 0) {
            const maxPrice = maxOrgInput
            const minPrice = minOrgInput
            const numerator = value * Math.sqrt(Number(marketPrice)) * Math.sqrt(Number(maxPrice))
            const denominator = Math.sqrt(Number(maxPrice)) - Math.sqrt(Number(marketPrice))
            const liquidityOfToken = numerator / denominator

            const token2Value = liquidityOfToken * (Math.sqrt(Number(marketPrice)) - Math.sqrt(Number(minPrice)))
            setQouteTokenInput(token2Value.toFixed(4))
            setQouteTokenOrgInput(token2Value.toFixed(18))
        }

    }

    /**This function is called on change of quote token input field */
    const quoteTokenInputHandler = (evt: any) => {

        setEnteredIn("quote")
        const value = evt.target.value
        if (
            specialCharValidation.test(value) ||
            negativeValidation.test(value) ||
            value.split(".").length > 2
        ) {
            return false;
        }
        else {
            if (collateralBal && (Number(value) > (collateralBal * 10))) {
                setQouteTokenInput(qouteTokenInput)
                setQouteTokenOrgInput(qouteTokenOrgInput)
            } else {
                const valueTobeSet = value.replace(/\s/g, '')
                const newValue = valueTobeSet.split('.', 2)[1]
                if (newValue && newValue.length > 4) {
                    setQouteTokenInput(qouteTokenInput)
                    setQouteTokenOrgInput(qouteTokenOrgInput)
                } else {
                    setQouteTokenInput(valueTobeSet)
                    setQouteTokenOrgInput(valueTobeSet)
                }

                if (!valueTobeSet) {
                    setBaseTokenInput('')
                    setBaseTokenOrgInput('')
                }

                if (baseTokenInput !== "Out of range") {
                    if (value.length > 0) {
                        baseTokenCalculation(value);
                    } else {
                        setBaseTokenInput('')
                        setBaseTokenOrgInput('')
                    }
                }
            }
        }

    }

    /** This function calculated the base token value based on quote token */
    const baseTokenCalculation = (value: any) => {

        if (Number(value) > 0) {
            const maxPrice = maxOrgInput
            const minPrice = minOrgInput
            const liquidityOfToken =
                Number(value) / (Math.sqrt(Number(marketPrice)) - Math.sqrt(Number(minPrice)));

            const numerator =
                liquidityOfToken * (Math.sqrt(Number(maxPrice)) - Math.sqrt(Number(marketPrice)));
            const denominator = Math.sqrt(Number(marketPrice)) * Math.sqrt(Number(maxPrice));

            const token1Value = numerator / denominator;

            setBaseTokenInput(token1Value.toFixed(4))
            setBaseTokenOrgInput(token1Value.toFixed(18))
        }

    }

    /**This function is set the lower,upper, min and max price ranges on full range */
    const fullRangesPrices = () => {

        setLowerTick(TICK_MIN)
        setMinOrgInput(weiToEth(tickToPrice(TICK_MIN).toString(), 18))
        setUpperTick(TICK_MAX)
        setMaxOrgInput(weiToEth(tickToPrice(TICK_MAX).toString(), 18))

    }

    //**This function is for calculating high and low price ranges */
    const getPriceRange = async (amount: number, enteredMinMax: string, fullRange: boolean) => {

        if (account && poolAddress) {
            const poolContractInstance = await contractInstance(
                account,
                library,
                PoolAbi["abi"],
                poolAddress
            );
            const bigNumberAmount = new Big(amount);
            const tick = await poolContractInstance.methods.tickSpacing().call();

            if (fullRange) {
                fullRangesPrices()
            } else if (amount > 0) {
                const lowerOrUpperTick = priceToTick(bigNumberAmount, tick);
                if (enteredMinMax === 'minInput') {
                    setLowerTick(lowerOrUpperTick)
                } else {
                    setUpperTick(lowerOrUpperTick)
                }
                const price = tickToPrice(lowerOrUpperTick)
                return price
            }
        }

    }

    /** This useEffect is for setting values which depends on Out of Ranges*/
    /** Note: Don't change anything here else it creates regression */
    useEffect(() => {

        if (!fullRange && marketPrice) {
            if ((parseFloat(minOrgInput) > parseFloat(maxOrgInput)) || loading) {
                setQouteTokenInput("Out of range")
                setQouteTokenOrgInput("Out of range")

                setBaseTokenInput("Out of range")
                setBaseTokenOrgInput("Out of range")
            } else if (parseFloat(minOrgInput) > parseFloat(marketPrice.toString())) {
                setQouteTokenInput("Out of range")
                setQouteTokenOrgInput("Out of range")

                setBaseTokenInput("")
                setBaseTokenOrgInput("")
            } else if (parseFloat(maxOrgInput) < parseFloat(marketPrice.toString())) {
                setQouteTokenInput("")
                setQouteTokenOrgInput("")

                setBaseTokenInput("Out of range")
                setBaseTokenOrgInput("Out of range")
            } else {
                setQouteTokenInput("")
                setQouteTokenOrgInput("")
                setBaseTokenInput("")
                setBaseTokenOrgInput("")
            }
        }

    }, [minOrgInput, maxOrgInput, marketPrice, fullRange, loading])

    /** This useEffect is to calculate min&max ranges & to calculates base and quote token values accordingly*/
    useEffect(() => {

        if (enteredIn === 'base' && baseTokenOrgInput && (qouteTokenOrgInput !== "Out of range")) {
            qouteTokenCalculation(baseTokenOrgInput)
        } else if (enteredIn === 'quote' && qouteTokenOrgInput && (baseTokenOrgInput !== "Out of range")) {
            baseTokenCalculation(qouteTokenOrgInput)
        }
        else if (enteredMinMax === "minInput" && minOrgInput && !fullRange) {
            const timer = setTimeout(async () => {
                const price = await getPriceRange(Number(minOrgInput), enteredMinMax, fullRange);
                if (price && marketPrice) {
                    setMinInput(price.toFixed(4));
                    setMinOrgInput(price.toString())
                    if (enteredIn === "base" && Number(baseTokenOrgInput) > 0 && (qouteTokenOrgInput !== "Out of range")) {
                        qouteTokenCalculation(baseTokenOrgInput)
                    } else if (enteredIn === "quote" && Number(qouteTokenOrgInput) > 0 && (baseTokenOrgInput !== "Out of range")) {
                        baseTokenCalculation(qouteTokenOrgInput)
                    }
                }
            }, 2000);
            return () => {
                clearTimeout(timer);
            };
        } else if (enteredMinMax === "maxInput" && maxOrgInput && !fullRange) {
            const timer = setTimeout(async () => {
                const price = await getPriceRange(Number(maxOrgInput), enteredMinMax, fullRange);
                if (price && marketPrice) {
                    setMaxInput(price.toFixed(4));
                    setMaxOrgInput(price.toString())
                    if (enteredIn === "base" && Number(baseTokenOrgInput) > 0 && (qouteTokenOrgInput !== "Out of range")) {
                        qouteTokenCalculation(baseTokenOrgInput)
                    } else if (enteredIn === "quote" && Number(qouteTokenOrgInput) > 0 && (baseTokenOrgInput !== "Out of range")) {
                        baseTokenCalculation(qouteTokenOrgInput)
                    }
                }
            }, 2000);
            return () => {
                clearTimeout(timer);
            };
        }

    }, [enteredIn, minOrgInput, maxOrgInput, fullRange]);

    /**This function is for calling add liquidity contract */
    const liquidityHandler = async () => {

        if (account && baseTokenAddress && poolAddress && collateralBal) {
            setLoading(true)
            const token1Instance = await contractInstance(
                account,
                library,
                usdcabi["abi"],
                baseTokenAddress
            );
            const token1Decimals = await token1Instance.methods.decimals().call();

            const token1Amount = ethToWei(baseTokenOrgInput, token1Decimals);
            const token2Amount = ethToWei(qouteTokenOrgInput, 18);
            const minToken1Amount = handleMinReceive(baseTokenOrgInput, 0.5);
            const minToken2Amount = handleMinReceive(qouteTokenOrgInput, 0.5);

            const collateral = ethToWei((Number(collateralInput)).toString(), 8)

            const response = await liquidity(
                dispatch,
                account,
                library,
                baseTokenAddress, //Base token address
                token1Amount,
                token2Amount,
                lowerTick,
                upperTick,
                "0",
                "0",
                collateral,
                DEADLINE
            );

            if (response) {
                toggleModal()
                setLoading(false)
                if (response.message) {
                    errorToast("Something went wrong: " + response.message)
                }
            }
        }

    }

    /**This useEffect is to close the modal on succeful removal of liquidity */
    useEffect(() => {

        if (transactionSuccess) {
            setLiquidtyTransactionSuccess(true)
            setLoading(false)
            toggleModal()
            dispatch(set_Transaction_Success(false))
        }

    }, [transactionSuccess, transactionHash])

    /**This function is called on change of collateral input field */
    const collateralInputtHandler = (evt: any) => {

        try {
            const value = evt.target.value
            if (
                specialCharValidation.test(value) ||
                negativeValidation.test(value) ||
                value.split(".").length > 2
            ) {
                return false;
            }
            else {
                const valueTobeSet = value.replace(/\s/g, '')
                const newValue = valueTobeSet.split('.', 2)[1]
                if (newValue && newValue.length > 4) {
                    setCollateralInput(collateralInput)
                } else {
                    setCollateralInput(valueTobeSet)
                }
                //Note: Do not put like "collateralBal &&" in if condition , as it won't work for 0
                //@ts-ignore
                if (parseFloat(value) > parseFloat(collateralBal)) {
                    setInsufficientToast(true)
                    setCollateralInput(collateralInput)
                } else {
                    setInsufficientToast(false)
                }
            }
        }
        catch (e) {
            console.log(e)
        }

    }

    /**This useEffect is to call insufficient collateral toast */
    useEffect(() => {

        if (account && insufficientToast) {
            errorToast('Insufficient Collateral')
        }

    }, [account, insufficientToast])

    return (
        <>
            <SharedCard background="secondary" padding="24px" margin="26px 0 0 0">
                <Text
                    fontSize={16}
                    fontWeight={500}
                    lineHeight={24}
                    color="secondary"
                >
                    Set Price Range
                </Text>
                <FlexRow justifyContent="space-between">
                    <Text
                        fontSize={14}
                        fontWeight={500}
                        lineHeight={21}
                        color="secondary"
                        margin="16px 0 16px 0"
                    >
                        Mark Price : ${Number(marketPrice).toFixed(4)}
                    </Text>
                    <FlexBox>
                        <Text
                            fontSize={12}
                            fontWeight={500}
                            lineHeight={16}
                            color="secondary"
                            textAlign="center"
                            margin="0 4px 0 0"
                        >
                            Full Range
                        </Text>
                        <Flex flexDirection="row">
                            <Type borderRadius='20px'>
                                <TypeButton
                                    color="#1C7EFF"
                                    active={!fullRange}
                                    onClick={() => {
                                        if (prePrediction) {
                                            setFullRange(fullRange)
                                        } else {
                                            setFullRange(!fullRange)
                                        }
                                    }}
                                    width="10px"
                                    borderRadius='30px'
                                ></TypeButton>
                                <TypeButton
                                    color="#1C7EFF"
                                    active={fullRange}
                                    onClick={() => {
                                        if (prePrediction) {
                                            setFullRange(fullRange)
                                        } else {
                                            setFullRange(!fullRange)
                                        }
                                    }}
                                    width="10px"
                                    borderRadius='30px'
                                ></TypeButton>
                            </Type>
                        </Flex>
                    </FlexBox>
                </FlexRow>
                <Input
                    width="100%"
                    margin="0 0 24px 0"
                    placeholder="Amount"
                    background="primary"
                    align="right"
                    padding="10px 24px 10px 84px"
                    value={minInput}
                    onChange={minInputHandler}
                    disabled={prePrediction}
                />
                <Text
                    fontSize={14}
                    fontWeight={500}
                    lineHeight={21}
                    color="secondary"
                    margin="-56px 0 16px 24px"
                    width="40px"
                >
                    Min
                </Text>
                <Input
                    width="100%"
                    margin="0 0 24px 0"
                    placeholder="Amount"
                    background="primary"
                    align="right"
                    padding="10px 24px 10px 84px"
                    value={maxInput}
                    onChange={maxInputHandler}
                    disabled={prePrediction}
                />
                <Text
                    fontSize={14}
                    fontWeight={500}
                    lineHeight={21}
                    color="secondary"
                    margin="-56px 0 16px 24px"
                    width="40px"
                >
                    Max
                </Text>

                <FlexRow justifyContent="flex-start">
                    <Flex flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <Text fontSize={16} fontWeight={500} lineHeight={18} color="secondary" margin="8px 0">
                            Provide Collateral
                        </Text>
                        <Input width="90%" margin="0 4px 12px 0" placeholder="Amount" background="primary"
                            borderColor={collateralBal && (Number(collateralInput) > collateralBal) ? 'red' : '#1c7efe'}
                            value={collateralInput} onChange={collateralInputtHandler} />
                    </Flex>
                    <Flex flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                        <Text fontSize={16} fontWeight={500} lineHeight={18} color="secondary" margin="8px 0">
                            Leverage ({isNaN(leveragePercentage / 10) ? "0.00" : (leveragePercentage / 10) > 10 ? "10" : (leveragePercentage / 10).toFixed(2)}x)
                        </Text>
                        <LeverageBlock>
                            <Text fontSize={10} fontWeight={500} lineHeight={16} color="secondary" margin='0 8px 0 0'>
                                0.00x
                            </Text>
                            <SliderContainer width="80%" padding="0">
                                <Slider
                                    defaultValue={0}
                                    value={leveragePercentage}
                                    min={0}
                                    max={100}
                                    step={1}
                                    onChange={leveragePercentageHandler}
                                ></Slider>
                            </SliderContainer>
                            <Text fontSize={10} fontWeight={500} lineHeight={16} color="secondary" margin='0 0 0 8px'>
                                10x
                            </Text>
                        </LeverageBlock>
                    </Flex>
                </FlexRow>

                <FlexRow justifyContent='space-between'>
                    <Text
                        fontSize={14}
                        fontWeight={500}
                        lineHeight={21}
                        color="secondary"
                        margin="0 0 6px 0"
                    >
                        Provide Liquidity
                    </Text>
                    <Text
                        fontSize={14}
                        fontWeight={500}
                        lineHeight={21}
                        color="secondary"
                        margin="0 0 6px 0"
                        textAlign="right"
                    >
                        Buying Power ≈ ${isNaN(Number(collateralInput)) ? "0" : (Number(collateralInput) * 10).toFixed(3)}
                    </Text>
                </FlexRow>
                <Input
                    width="100%"
                    margin="0 0 24px 0"
                    placeholder="Amount"
                    background="primary"
                    align="right"
                    padding="10px 8px 10px 84px"
                    value={baseTokenInput}
                    onChange={baseTokenInputHandler}
                    disabled={baseTokenInput === "Out of range"}
                />
                <TokenWrapper>
                    <Token logo={selectedTokenLogo ? selectedTokenLogo : eth} token={selectedToken} />
                </TokenWrapper>
                <Input
                    width="100%"
                    margin="0 0 24px 0"
                    placeholder="Amount"
                    background="primary"
                    align="right"
                    value={qouteTokenInput}
                    padding="10px 8px 10px 84px"
                    onChange={quoteTokenInputHandler}
                    borderColor={Number(totalCost) > (Number(collateralInput) * 10) ? 'red' : '#1c7efe'}
                    disabled={qouteTokenInput === "Out of range"}
                />
                <TokenWrapper>
                    <Token logo={usdc} token="USD" />
                </TokenWrapper>
                <Text
                    fontSize={16}
                    fontWeight={500}
                    lineHeight={18}
                    color="secondary"
                    margin="0 0 0 0"
                    textAlign="center"
                >
                    Total Cost :
                    <span
                        style={{
                            color: "#69CC8D",
                            fontWeight: "700",
                            fontStyle: "Poppins",
                        }}
                    >
                        {" "}
                        {!totalCost || isNaN(totalCost) ? '0.00' : totalCost}
                    </span>
                </Text>
            </SharedCard>
            <Flex>
                <SharedButton
                    margin="18px 0 0 0"
                    padding="12px 60px"
                    color="#69CC8D"
                    onClick={liquidityHandler}
                    disabled={(Number(totalCost) > (Number(collateralInput) * 10)) ||
                        (collateralBal && (Number(collateralInput) > collateralBal)) ||
                        !minInput || !maxInput || !collateralInput || !qouteTokenInput ||
                        !baseTokenInput || Number(maxInput) <= 0 || loading
                        || Number(baseTokenInput) <= 0 || Number(qouteTokenInput) <= 0
                        || Number(collateralInput) <= 0 || Number(totalCost) <= 0
                    }
                >
                    {loading ? 'Loading...' : 'Add Liquidity'}
                </SharedButton>
            </Flex>
        </>
    )
}

export default AdvancedLiquidity;