/** Library imports */
import React, { useState, useEffect } from "react";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import moment from "moment";
import gql from "graphql-tag";
import { useWeb3React } from "@web3-react/core";

/** Custom imports */
import { Flex } from "../../../../shared/shared";
import {
  FlexContainer,
  Items,
  MarketDetailsWrapper,
  TradeContentWrapper,
  TradeContentChildTwo,
} from "./styles";
import Orders from "./components/Orders";
import { PoolDetails } from "./components/PoolDetails";
import { ContainerWrapper } from "./styles";
import TradingChart from "./tradingChart";
import MarketTrades from "./components/MarketTrades";
import PositionTab from "./components/PositionTab";
import { useAppDispatch, useAppSelector } from "../../../../logic/redux/hooks";
import {
  Sub_Graph_Url,
  vEth_Address,
  VETH_USD_PairAddress,
} from "../../../../blockchain/helper/Config";
import { tvlQuery } from "../../../../utils/Queries";
import IndexPrice from "../../../../blockchain/helper/indexPrice";
import BaseTokenAbi from "../../../../blockchain/abi/basetoken.json";
import { FourColumns } from "../../../../shared/basicGrid";
import { FundingRateQuery } from "../../../../logic/graphQL/queries";
import FundingCharts from "./components/FundingCharts";
import { getreceipt } from "../../../../blockchain/helper/getReceipt";
import { LightCharts } from "./LightChart/LightCharts";

interface I_NotionalData {
  data: { oddzMarketsVolumeHistories: [] };
  loading: boolean;
  networkStatus: number;
}

interface Props {}

export const Trade = (props: Props) => {
  const { account, library } = useWeb3React();
  const dispatch = useAppDispatch();
  const { transactionHash, transactionType } = useAppSelector(
    (state) => state.graphSymbol
  );
  const currentTimeStamp = moment().unix();
  const types = ["Price", "Funding"];
  const [active, setActive] = useState(types[0]);
  const [baseTokenAddr, setBaseTokenAddr] = useState<string>(vEth_Address);
  const [poolAddress, setPoolAddress] = useState<string>(VETH_USD_PairAddress);
  const [selectedToken, setSelectedToken] = useState<string>("ETH");
  const [selectedLogo, setSelectedLogo] = useState<string>("");
  const [baseTokenIndexPrice, setBaseTokenIndexPrice] = useState<string>(
    "0.00"
  );
  const [marketPrice, setMarketPrice] = useState<number>(0);
  const [oddzMarketsVolumeData, setoddzMarketsVolumeData] = useState<
    Array<{}>
  >();
  const [tvlAmount, settvlAmount] = useState<string>("0");
  const [positionTransactionSuccess, setPositionTransactionSuccess] = useState<
    boolean
  >(false);
  const [fundingRate, setFundingRate] = useState<string>("");

  /**This array contains the Index price, mark price, funding rate and 24h volume values */
  const marketDetails = [
    { name: "Index Price", amount: "$ " + baseTokenIndexPrice },
    { name: "Mark Price", amount: "$ " + marketPrice },
    {
      name: "Funding Rate",
      amount: fundingRate ? parseFloat(fundingRate).toFixed(4) + " %" : "00.00",
    },
    { name: "24h Volume", amount: "$ " + tvlAmount },
  ];

  /** getting the index price and storing it in baseTokenIndexPrice */
  const getIndexPrice = async () => {
    const Index_Price = await IndexPrice(BaseTokenAbi, baseTokenAddr);

    if (Index_Price) {
      setBaseTokenIndexPrice(Index_Price.substring(0, 8));
    }
  };

  const client = new ApolloClient({
    uri: Sub_Graph_Url,
    cache: new InMemoryCache(),
  });

  /** getting the 24h Volume and storing it in oddzMarketsVolumeData */
  const oneDayVolumeValue = () => {
    client
      .query({
        query: gql(tvlQuery(currentTimeStamp, baseTokenAddr)),
      })
      .then((data: I_NotionalData) => {
        setoddzMarketsVolumeData(data.data.oddzMarketsVolumeHistories);
      })
      .catch((err: any) => {
        console.log("Error fetching tvlQuery data: ", err);
      });
  };

  /** Getting the Funding rate values */
  const fundingRateFunction = () => {
    client
      .query({
        query: gql(FundingRateQuery(baseTokenAddr)),
      })
      .then((data: any) => {
        setFundingRate(data.data.oddzFundingUpdateds[0].dailyFundingRate);
      })
      .catch((err: any) => {
        console.log("error from FundingRateQuery", err);
      });
  };

  /**This useEffect is to call index price, 24h volume and funding rate contracts */
  useEffect(() => {
    if (baseTokenAddr) {
      getIndexPrice();
      oneDayVolumeValue();
      fundingRateFunction();

      const interval = setInterval(() => {
        getIndexPrice();
        oneDayVolumeValue();
        fundingRateFunction();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [baseTokenAddr]);

  /**calculating the 24h Volume of selected BaseToken */
  useEffect(() => {
    const SwappedPositionArray: number[] = [];
    if (!oddzMarketsVolumeData) return;
    oddzMarketsVolumeData.map((item: any) => {
      SwappedPositionArray.push(Math.abs(item.baseTokenVolume));
    });
    settvlAmount(
      SwappedPositionArray.reduce(
        (previousValue: number, currentValue: number) =>
          previousValue + currentValue,
        0
      )
        .toString()
        .substring(0, 8)
    );
  }, [oddzMarketsVolumeData]);

  /**This useEffect is to get receipt of all the trading related transactions */
  useEffect(() => {
    if (transactionHash && transactionType) {
      const interval = setInterval(() => {
        getreceipt(transactionHash, dispatch, transactionType);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [transactionHash, dispatch, transactionType]);

  return (
    <>
      <ContainerWrapper>
        <PoolDetails
          setBaseTokenAddr={setBaseTokenAddr}
          setPoolAddr={setPoolAddress}
          setSelectedTokenName={setSelectedToken}
          setSelectedLogo={setSelectedLogo}
          setMarketPrice={setMarketPrice}
        />
        <TradeContentWrapper>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            gap="8px"
            width="100%"
          >
            <FourColumns>
              {marketDetails.map((type, index) => (
                <MarketDetailsWrapper key={index}>
                  <div>
                    {type.name} <br />
                    {type.amount}
                  </div>
                </MarketDetailsWrapper>
              ))}
            </FourColumns>
            <FlexContainer>
              {types.map((type, index) => (
                <Items
                  key={index}
                  active={active === type}
                  onClick={() => {
                    setActive(type);
                  }}
                >
                  {type}
                  <br />
                </Items>
              ))}
            </FlexContainer>
            {active === "Price" && (
              <div
                style={{
                  padding: "0 0 0 0",
                  height: "490px",
                  width: "100%",
                  zIndex: "0",
                }}
              >
                {/* <LightCharts /> */}
                <TradingChart />
              </div>
            )}
            {active === "Funding" && (
              <div
                style={{
                  padding: "12px 0 0 0",
                  height: "490px",
                  width: "100%",
                  border: "1px solid #3A365F",
                }}
              >
                {/* <div>
                  hello
                </div> */}
                <FundingCharts baseToken={baseTokenAddr} />
              </div>
            )}
            <Flex width="100%">
              <PositionTab
                baseTokenAddr={baseTokenAddr}
                poolAddress={poolAddress}
                selectedLogo={selectedLogo}
                selectedToken={selectedToken}
                positionTransactionSuccess={positionTransactionSuccess}
                setPositionTransactionSuccess={setPositionTransactionSuccess}
              />
            </Flex>
          </Flex>
          <TradeContentChildTwo>
            <Orders
              baseTokenAddr={baseTokenAddr}
              poolAddress={poolAddress}
              selectedToken={selectedToken}
              setPositionTransactionSuccess={setPositionTransactionSuccess}
              marketPrice={marketPrice}
            />
            <MarketTrades baseTokenAddr={baseTokenAddr} />
          </TradeContentChildTwo>
        </TradeContentWrapper>
      </ContainerWrapper>
    </>
  );
};

export default Trade;
