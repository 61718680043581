/**Library imports */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Box, makeStyles, Drawer } from "@material-ui/core";

/** styles */
import {
  ConnectWallet,
  Container,
  FlexContainer,
  HeaderButton,
  HeaderContainer,
  LogoContainer,
  NavContainer,
  NavItems,
  WalletContainer,
  WalletIcon,
  Img,
  Balence,
  ThemeSwitcher,
  MobileNav,
  BottomDrawer,
} from "./style";

/** Custom imports */
import { FlexBox } from "../../../../shared/flexBox";
import logo from "../../../../assets/images/logo.svg";
import wallet from "../../../../assets/images/wallet-icon.svg";
import settings from "../../../../assets/images/settings.svg";
import downArrow from "../../../../assets/images/downArrow.svg";
import connected from "../../../../assets/images/connected-wallet.svg";
import { Text } from "../../../../shared/styles/typography";
import { SharedCard } from "../../../../shared/sharedCard";
import closeButton from "../../../../assets/images/closeButton.svg";
import copy from "../../../../assets/images/copy.svg";
import { sectionRender } from "../../../../utils/constants";
import useAuth from "../../../../blockchain/wallethelper/Useauth";
import SettingsModal from "./components/settings-modal";
import { Flex, SharedButton } from "../../../../shared/shared";
import { BigNumber } from "bignumber.js";
import { WalletConnectModal } from "../../../../shared/wallet-connect/WalletConnectModal";
import { SwitchNetworkModal } from "../../../../shared/wallet-connect/SwitchNetworkModal";
import OddzLogo from "../../../../assets/images/OddzLogo.svg";
import ArbitriumLogo from "../../../../assets/images/logo-matic.png";
import { DisconnectModal } from "../../../../shared/wallet-connect/DisconnectModal";
import { copyAccount } from "../../../../logic/helpers/utilities";
import darkLogo from "../../../../assets/images/darkLogo.svg";
import lightLogo from "../../../../assets/images/lightLogoNew.svg";
import { I_Theme } from "../types";
import hamburger from "../../../../assets/images/hamburger.svg";
import dots from "../../../../assets/images/3dots.svg";
import { FlexRow } from "../../../../shared/styles/styles";

export const Navbar = (props: I_Theme) => {
  const { setTheme, theme } = props;
  const location = useLocation();
  const [show, setShow] = useState<string>("");
  const [showNav, setShowNav] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const close = () => {
    setShow("");
  };

  const [copyText, setCopyText] = useState("");
  const useStyles = makeStyles((theme: any) => ({
    menuSliderContainer: {
      width: 250,
      background: "#23262F",
      height: "100%",
      padding: "24px 0",
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleSlider = () => {
    setOpen(!open);
  };

  const sideList = () => (
    <Box className={classes.menuSliderContainer} component="div">
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {sectionRender.map((type, index) => (
          <>
            <NavItems
              key={index}
              active={activeType === type.link}
              onClick={() => {
                setActiveType(type.link);
                navigate(`${type.link}`);
                toggleSlider();
              }}
            >
              {type.text}
            </NavItems>
          </>
        ))}
      </Flex>
    </Box>
  );
  const handleCopy = () => {
    copyAccount(account);
    setTimeout(() => {
      setCopyText("");
    }, 2000);
    setCopyText("Copied!");
  };

  const navigate = useNavigate();
  const [activeType, setActiveType] = useState(
    location.pathname === "/"
      ? sectionRender[0].link
      : "/" + location.pathname.split("/")[1]
  );
  const [walletConnected, setWalletConnected] = useState(false);
  const [balence, setBalence] = useState<number>(0);
  const [switchNetworkModal, setSwitchNetworkModal] = useState(false);

  const { account, active, library, chainId, connector } = useWeb3React();
  const { logout } = useAuth();

  const getAccountBalance = async () => {
    const balence = await library.eth.getBalance(account);
    const amt = new BigNumber(balence).dividedBy(10 ** 18).toFixed();
    //@ts-ignore
    setBalence(amt);
  };

  // const disconnectSuccess = () => toast.success('Disconnected!')
  //@ts-ignore
  const walletType = JSON.parse(localStorage.getItem("connectorId"));
  useEffect(() => {
    //@ts-ignore
    const walletconnect = JSON.parse(localStorage.getItem("walletconnect"));
    if (account) {
      localStorage.setItem("walletConnected", JSON.stringify(true));
      getAccountBalance();
      setWalletConnected(true);
    }
  }, [account, walletType]);

  /** Disconnect Wallet */
  const disconnectWallet = () => {
    close();
    setWalletConnected(false);
    // account = null;
    const connectorId = localStorage.getItem("connectorId");
    if (connectorId) {
      if (connectorId === "3" && connector) {
        // connector.close()
      }
      logout(JSON.parse(connectorId));
      return;
    }
    // logout();

    // disconnectSuccess();
  };

  const handleConnectModal = () => {
    if (walletConnected && account) {
      setShow("disconnect");
    } else {
      setShow("connect");
    }
  };

  const handleClickOutside = (e: any) => {
    if (e.target === e.currentTarget) {
      setShowNav(!showNav);
    }
  };

  /** This is useEffect is to detect the wrong network modal */
  useEffect(() => {
    const connected = localStorage.getItem("walletConnected");
  
    if (active && walletConnected && chainId !== 421614) {
      setSwitchNetworkModal(true);
    } else {
      setSwitchNetworkModal(false);
    }
  }, [walletConnected, active, chainId, walletType]);

  useEffect(() => {
    setActiveType(
      location.pathname === "/"
        ? sectionRender[0].link
        : "/" + location.pathname.split("/")[1]
    );
  }, [activeType]);

  console.log(library)
  return (
    <Container>
      <HeaderContainer>
        <FlexBox>
          <LogoContainer>
            <img src={logo} alt="" />
          </LogoContainer>
          <NavContainer>
            {walletConnected && account && (
              <>
                <HeaderButton>
                  <FlexBox>
                    <Img
                      margin_top={0}
                      margin_left={0}
                      src={ArbitriumLogo}
                      alt=""
                      borderRadius="16px"
                    />
                    <Balence>{balence.toString().slice(0, 6)}</Balence>
                  </FlexBox>
                </HeaderButton>
                <HeaderButton>
                  <FlexBox>
                    <Img
                      margin_top={0}
                      margin_left={0}
                      margin_right={8}
                      src={ArbitriumLogo}
                      alt=""
                      borderRadius="16px"
                    />
                    {"  Polygon Testnet"}
                    <Img
                      margin_top={0}
                      margin_left={6}
                      src={downArrow}
                      alt=""
                    />
                  </FlexBox>
                </HeaderButton>
              </>
            )}
            {walletConnected && account && <WalletIcon src={connected} />}
            <ConnectWallet
              onClick={() => handleConnectModal()}
              connected={walletConnected && account}
            >
              <FlexBox onClick={() => setWalletConnected(true)}>
                {(!walletConnected || !account) && (
                  <Img margin_top={0} margin_left={0} src={wallet} alt="" />
                )}
                {walletConnected && account ? (
                  //@ts-ignore
                  account?.slice(0, 3) +
                  "..." + //@ts-ignore
                  account?.substring(account.length - 5)
                ) : (
                  <Balence>Connect Wallet</Balence>
                )}
              </FlexBox>
            </ConnectWallet>
            {walletConnected && account && (
              <img src={settings} alt="" onClick={() => setShow("settings")} />
            )}
            {/* <img src={lightLogo} style = {{height:'24px', width:'24px', fill:'red'}} alt="" onClick={() => setShow("settings")} /> */}
            {!theme ? (
              <ThemeSwitcher
                src={lightLogo}
                onClick={() => {
                  localStorage.setItem("theme", !theme ? "light" : "dark");
                  setTheme(!theme);
                }}
              />
            ) : (
              <ThemeSwitcher
                className="svg-icon"
                src={darkLogo}
                onClick={() => {
                  localStorage.setItem("theme", !theme ? "light" : "dark");
                  setTheme(!theme);
                }}
              />
            )}
          </NavContainer>
        </FlexBox>
      </HeaderContainer>
      <HeaderContainer color="secondary">
        <FlexContainer>
          {sectionRender.map((type, index) => (
            <>
              <NavItems
                key={index}
                active={activeType === type.link}
                onClick={() => {
                  setActiveType(type.link);
                  navigate(`${type.link}`);
                }}
              >
                {type.text}
              </NavItems>
            </>
          ))}
        </FlexContainer>
        {/* <ToastContainer position="top-center" style={{zIndex:"100000000000000000000000000"}} /> */}
      </HeaderContainer>
      {switchNetworkModal ? (
        <SwitchNetworkModal toggleModal={() => close()} />
      ) : (
        ""
      )}
      {show === "connect" ? (
        <WalletConnectModal
          show={show}
          setWalletConnected={setWalletConnected}
          toggleModal={() => close()}
        />
      ) : (
        ""
      )}
      {show === "disconnect" ? (
        <DisconnectModal
          show={show}
          toggleModal={() => close()}
          disconnect={disconnectWallet}
        />
      ) : (
        ""
      )}
      {console.log("show", show, walletConnected, account)}

      <SettingsModal
        show={show === "settings" ? true : false}
        toggleModal={() => close()}
        heading="Wallet"
        padding="16px"
        width="280px"
        background="primary"
      >
        <SharedCard padding="16px" background="secondary">
          <WalletContainer>
            <FlexBox>
              <Text
                fontSize={14}
                lineHeight={16}
                fontWeight={500}
                color="secondary"
              >
                {account
                  ? copyText === ""
                    ? //@ts-ignore
                      account?.slice(0, 3) +
                      "..." + //@ts-ignore
                      account?.substring(account.length - 5)
                    : "Copied!!"
                  : "Connect Wallet"}
              </Text>
              <img src={copy} onClick={() => handleCopy()} />
            </FlexBox>
          </WalletContainer>
          {/* <Flex justifyContent='center'> */}
          <SharedButton
            color="#E0476A"
            margin="18px 0 0 0"
            width="100%"
            onClick={disconnectWallet}
          >
            Disconnect
          </SharedButton>
          {/* </Flex> */}
        </SharedCard>
      </SettingsModal>
      <MobileNav
        width="100%"
        background="secondary"
        onMouseDown={handleClickOutside}
      >
        <img
          src={hamburger}
          style={{ height: "auto", width: "24px" }}
          onClick={toggleSlider}
        />
        <img src={OddzLogo} alt="" />
        <HeaderButton>
          <FlexRow className="logo-matic">
            <Img
              margin_top={0}
              margin_left={0}
              src={ArbitriumLogo}
              alt=""
              borderRadius="16px"
            />
            <Balence>{balence.toString().slice(0, 6)}</Balence>
          </FlexRow>
        </HeaderButton>
        <HeaderButton>
          <FlexRow>
            <Img
              margin_top={0}
              margin_left={0}
              margin_right={8}
              src={ArbitriumLogo}
              alt=""
              borderRadius="16px"
            />
            {" Polygon Testnet"}
          </FlexRow>
        </HeaderButton>
        <img
          src={dots}
          style={{ height: "44px", width: "24px" }}
          onClick={() => setShowDrawer(!showDrawer)}
        />
        <Drawer open={open} anchor="left" onClose={toggleSlider}>
          {sideList()}
        </Drawer>
      </MobileNav>
      {/* <MobileSideNav background='primary' active={showNav} >
      <Flex width='100%' justifyContent='flex-start' alignItems='flex-start'>
          {sectionRender.map((type, index) => (
            <>
            <MobileNavItems
              key={index}
              active={activeType === type.link}
              onClick={() => {
                setActiveType(type.link);
                navigate(`${type.link}`);
              }}
            >
              {type.text}
            </MobileNavItems>
            </>
          ))}
        </Flex>
      </MobileSideNav> */}
      <BottomDrawer active={showDrawer}>
        <Flex>
          <img
            style={{ transform: "translateY(-84px)", position: "absolute" }}
            src={closeButton}
            onClick={() => setShowDrawer(false)}
          />
        </Flex>
        <NavContainer>
          {walletConnected && account && <WalletIcon src={connected} />}
          <ConnectWallet
            onClick={() => handleConnectModal()}
            connected={walletConnected}
          >
            <FlexBox onClick={() => setWalletConnected(true)}>
              {!walletConnected && (
                <Img margin_top={0} margin_left={0} src={wallet} alt="" />
              )}
              {walletConnected && account ? (
                //@ts-ignore
                account?.slice(0, 3) +
                "..." + //@ts-ignore
                account?.substring(account.length - 5)
              ) : (
                <Balence>Connect Wallet</Balence>
              )}
            </FlexBox>
          </ConnectWallet>
          {!theme ? (
            <ThemeSwitcher
              src={lightLogo}
              onClick={() => {
                localStorage.setItem("theme", !theme ? "light" : "dark");
                setTheme(!theme);
              }}
            />
          ) : (
            <ThemeSwitcher
              className="svg-icon"
              src={darkLogo}
              onClick={() => {
                localStorage.setItem("theme", !theme ? "light" : "dark");
                setTheme(!theme);
              }}
            />
          )}
        </NavContainer>
        {walletConnected && account && (
          <SharedButton
            color="#E0476A"
            margin="18px 0 0 0"
            width="100%"
            padding="12px"
            onClick={disconnectWallet}
          >
            Disconnect
          </SharedButton>
        )}
      </BottomDrawer>
    </Container>
  );
};
