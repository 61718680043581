import React, { useEffect, useState } from "react"
import "./App.css"
import { RoutesComponent } from "./components/routes/Routes"
import { ThemeProvider } from "styled-components"
import { getTheme, Themes } from "../../styles/theme"
import { GlobalStyle } from "../../styles/globalStyle"

export const App = () => {

    // const [isDarkMode, setDarkMode] = useState(false);
        const lightTheme = { ...getTheme(Themes.LIGHT), selected: Themes.LIGHT }
        const darkTheme = { ...getTheme(Themes.DARK), selected: Themes.DARK }
        const [theme, setTheme] = useState(localStorage.getItem('theme') === 'light' ? true : false);
    return <ThemeProvider theme={theme ? lightTheme : darkTheme}>
        <GlobalStyle />
        <RoutesComponent setTheme = {setTheme} theme={theme}/>
    </ThemeProvider>
}