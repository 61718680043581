import React from "react";
import { useWeb3React } from "@web3-react/core";

/** custom module import of the contract instance */
import useAuth from "../../blockchain/wallethelper/Useauth";
import CustomModal from "../custom-modal";
import { FlexBox } from "../flexBox";
import { SharedCard } from "../sharedCard";
import { Text } from "../styles/typography";
import { wallets } from "../../utils/constants";
import {
  HR,
  NetworkImage,
  WalletContainer,
} from "../../modules/app/components/navbar/style";
import ArbitriumLogo from "../../assets/images/logo-matic.png";
import binance from "../../assets/images/wallets/Binance.svg";

export const WalletConnectModal = (props: any) => {
  const { show, toggleModal, setWalletConnected } = props;
  const { account, connector } = useWeb3React();
  const { login } = useAuth();

  //This function handles login and logout functions
  const walletConnectHandler = async (type: number) => {
    if (account) {
      toggleModal();
    } else {
      try {
        setWalletConnected(true);
        login(type);
        localStorage.setItem("connectorId", JSON.stringify(type));
        toggleModal();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <CustomModal
      show={show === "connect" ? true : false}
      toggleModal={toggleModal}
      heading="Connect"
      padding="16px"
      width="280px"
      background="primary"
    >
      <SharedCard padding="16px" background="secondary">
        <>
          <NetworkImage
            src={ArbitriumLogo}
            // onClick={() => setNetwork("Avax")}
            active={true}
          />
          {/* <NetworkImage
              src={binance}
              // onClick={() => setNetwork("Binance")}
              // active={network === "Binance"}
            /> */}
        </>
        <HR />
        <Text
          fontWeight={600}
          fontSize={12}
          lineHeight={12}
          color="primary"
          margin="10px 0 8px 0"
          ls="1px"
        >
          Polygon Testnet Wallet
        </Text>
        {wallets.map((wallet, index) => {
          return (
            <WalletContainer
              onClick={() => walletConnectHandler(wallet.type)}
              key={index}
            >
              <FlexBox>
                <Text fontSize={12} lineHeight={12} color="secondary" ls="1px">
                  {wallet.name}
                </Text>
                <img src={wallet.logo} />
              </FlexBox>
            </WalletContainer>
          );
        })}
      </SharedCard>
      <Text
        fontWeight={400}
        fontSize={12}
        lineHeight={14}
        color="secondary"
        margin="8px 0 0 0"
      >
        Are you New?{" "}
        <span
          style={{
            color: "#1C7EFF",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Click here
        </span>{" "}
        to know more.
      </Text>
    </CustomModal>
  );
};
