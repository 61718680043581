import React from 'react'
import { useMemo } from "react";
import { Text } from "../../../../../shared/styles/typography";
import SimpleDateTime  from 'react-simple-timestamp-to-date';

export default function useHistoryColumns() {
  const historyColumns = useMemo<any>(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign:"left"
            }}
          >Time</div>),
                  accessor: "timestamp",
                  Cell: (cell: { accessor: {} | null | undefined; value:any; }) => (
          <Text color='primary' fontSize={14} fontWeight={500} >
                  <SimpleDateTime dateFormat="DMY" dateSeparator="/"  timeSeparator=":">{cell.value}</SimpleDateTime>
          </Text> 
                     )
      },

      {
        Header: () => (
          // <Flex justifyContent='flex-start' alignItems='center'>
          <div
            style={{
              textAlign:"left",
              // display: 'inline-block',
            }}
          >Amount</div>
          /* <ToolTip  toolTipText= 'History for user transx'/> */
          /* </Flex> */
          ),
                  accessor: "amount",
                  Cell: (cell: { accessor: {} | null | undefined; value:any; }) => (
                    <Text color='primary' fontSize={14} fontWeight={700} >$ {cell.value}</Text>
                     )
      },
      {
        Header: () => (
          <div
            style={{
              textAlign:"left"
            }}
          >Transaction Types</div>),
                  accessor: "eventType",
                  Cell: (cell: { accessor: {} | null | undefined; value:any; }) => (
                    cell.value === 'deposit' ? <Text color='#1C7EFF' fontSize={14} fontWeight={700} >Deposit</Text> : <Text color='#69CC8D' fontSize={14} fontWeight={700} >Withdraw</Text>
                     )
        }
    ],
    []
  );
  return historyColumns;
}