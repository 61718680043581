import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { ThemeProps, withTheme } from "styled-components";
import { NoDataFound } from "../../../../shared/noDataFound";
import { Flex } from "../../../../shared/shared";
import { Text } from "../../../../shared/styles/typography";
import { FlexContainer, Items } from "../history/style";
import { MyGroupPositions } from "./components/MyGroupPositions";
import { MyPositions } from "./components/MyPositions";

export const Positions: React.FC = withTheme((props: ThemeProps<any>) => {
/** Types for positions */
  const types = ["Position", "Group Position"]; 
  const [active, setActive] = useState(types[0]);
  const { account } = useWeb3React()
  return (
      <>
      <FlexContainer>
        {types.map((type, index) => (
          <Items
            key={index}
            active={active === type}
            onClick={() => setActive(type)}
          >
            {type}
          </Items>
        ))}
      </FlexContainer>
      { !account ? <NoDataFound heading='Wallet not connected' text='Please connect your wallet.'/>:
       active === "Position" && account ? (
        <MyPositions  />
      ) : active === "Group Position" && account ? (
        <MyGroupPositions />
      ) : (
        ""
      )}
    </>
  )
})
export default Positions
