/** Library imports */
import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import Slider from 'rc-slider'
import Big from 'big.js'

/** Custom imports */
import { Flex, SharedButton } from '../../../../../../shared/shared'
import { Text } from '../../../../../../shared/styles/typography'
import { Input, Predict, SliderContainer, TokenWrapper } from '../../styles'
import Token from '../Token'
import usdc from '../../../../../../assets/images/usdc.svg'
import down from '../../../../../../assets/images/down-red.svg'
import up from '../../../../../../assets/images/up-green.svg'
import usdcabi from '../../../../../../blockchain/abi/usdc.json'
import contractInstance from '../../../../../../blockchain/instances/contractInstance'
import { priceToTick, tickToPrice } from '../../../../../../blockchain/helper/tick'
import { SharedCard } from '../../../../../../shared/sharedCard'
import liquidity from '../../../../../../blockchain/helper/AddLiquidity'
import { ethToWei } from '../../../../../../blockchain/helper/Converstion'
import { negativeValidation, specialCharValidation } from '../../../../../../utils/helper'
import { LeverageBlock } from '../styles'
import { DEADLINE } from '../../../../../../blockchain/helper/handleMinMax'
import { FlexRow } from '../../../../../../shared/styles/styles'
import tickSpacingContract from '../../../../../../blockchain/helper/tickSpacing'
import indexPrice from '../../../../../../blockchain/helper/indexPrice'
import BaseTokenAbi from '../../../../../../blockchain/abi/basetoken.json'
import { useAppDispatch, useAppSelector } from '../../../../../../logic/redux/hooks'
import { errorToast } from '../../../../../../blockchain/helper/toasts'
import { set_Transaction_Success } from '../../../../../../logic/redux/actions'

interface Props {
  baseTokenAddress?: string
  poolAddress?: string
  selectedToken?: string
  collateralBal?: number
  toggleModal?: any
  marketPrice?: number
  prePrediction?: string
  setLiquidtyTransactionSuccess?: any
  poolData?: any
  showModal?: boolean
}

const SimpleLiquidity = (props: Props) => {

  const {
    baseTokenAddress,
    poolAddress,
    poolData,
    collateralBal,
    toggleModal,
    marketPrice,
    prePrediction,
    setLiquidtyTransactionSuccess,
    showModal,
  } = props
  const { account, library } = useWeb3React()
  const dispatch = useAppDispatch()
  const { transactionHash, transactionSuccess } = useAppSelector((state) => state.graphSymbol)

  const [simpleTokenInput, setSimpleTokenInput] = useState<string>('')
  const [simpleTokenOrgInput, setSimpleTokenOrgInput] = useState<string>('')
  const [collateralInput, setCollateralInput] = useState<string>('')
  const [priceAt0, setPriceAt0] = useState<number>(0)
  const [priceAt20, setPriceAt20] = useState<number>(0)
  const [priceAt40, setPriceAt40] = useState<number>(0)
  const [priceAt60, setPriceAt60] = useState<number>(0)
  const [prediction, setPrediction] = useState<string>('down')
  const [sliderPercntge, setSliderPercntge] = useState<number>(20)
  const [lowerTick, setLowerTick] = useState<number>(0)
  const [upperTick, setUpperTick] = useState<number>(0)
  const [tokenResult, setTokenResult] = useState<string>('')
  const [leveragePercentage, setLeveragePercntge] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [currentTick, setCurrentTick] = useState(0)
  const [destTick, setDestTick] = useState(0)
  const [insufficientToast, setInsufficientToast] = useState<boolean>(false)
  const [tick, setTick] = useState<number>(60)
  const [baseTokenIndexPrice, setBaseTokenIndexPrice] = useState<string>('0.00')
  const [notEnoughColletral, setNotEnoughColletral] = useState<boolean>(false)

  /**This function is set the default slider percentage value */
  const defaultSliderPercentage = async () => {

    if (prePrediction && poolData) {
      let price: number
      if (prePrediction === 'down') {
        price = Math.floor(poolData.originalLowerTick)
      } else {
        price = Math.floor(poolData.originalUpperTick)
      }
      if (price === priceAt0) {
        setSliderPercntge(0)
      } else if (price === priceAt20) {
        setSliderPercntge(20)
      } else if (price === priceAt40) {
        setSliderPercntge(40)
      } else if (price === priceAt60) {
        setSliderPercntge(60)
      }
    }

  }

  /**This useEffect is to set the prediction value on change of prePrediction */
  useEffect(() => {

    if (prePrediction) {
      setPrediction(prePrediction)
    }

  }, [prePrediction])

  /**This useEffect is to call the defaultSliderPercentage function */
  useEffect(() => {

    if (prePrediction) {
      defaultSliderPercentage()
    }

  }, [prePrediction, poolData, priceAt60])

  /**This useEffect is to set the leverae percentage automatically */
  useEffect(() => {

    if (collateralInput && tokenResult && simpleTokenOrgInput) {
      const percentage = (Number(simpleTokenOrgInput) / (Number(collateralInput) * 10)) * 100
      setLeveragePercntge(percentage)

      if (prediction && prediction === 'up') {

        if (Number(collateralInput) * 10 < parseFloat(tokenResult) * parseFloat(baseTokenIndexPrice)) {
          setNotEnoughColletral(true)
        } else {
          setNotEnoughColletral(false)
        }
      }
    }

  }, [simpleTokenOrgInput, tokenResult, collateralInput])

  //**This function is called on change of leverage slider */
  const leveragePercentageHandler = (value: any) => {

    setLeveragePercntge(Number(value))
    if (collateralInput) {

      const USDvalue = Number(collateralInput) * 10 * (value / 100)
      setSimpleTokenInput(USDvalue.toFixed(4))
      setSimpleTokenOrgInput(USDvalue.toString())
      getSliderPriceRange(sliderPercntge)
    }

  }

  /**This function is to reset all the values */
  const resetValues = () => {

    setSimpleTokenInput('')
    setSimpleTokenOrgInput('')
    setTokenResult('')
    getSliderPriceRange(20)
    setCollateralInput('')
    setLeveragePercntge(0)

  }

  /**This useEffect to reset the values on modal close and to get index price */
  useEffect(() => {

    resetValues()

    if (!showModal) {
      setSliderPercntge(20)
      setPrediction('down')
    }

    getIndexPrice()
    const interval = setInterval(() => {
      getIndexPrice()
    }, 5000)

    return () => clearInterval(interval)

  }, [prediction, marketPrice, showModal])

  /**This function is to get the tick value from contract */
  const tickSpacingValue = async () => {

    if (poolAddress) {
      const tickValue = await tickSpacingContract(poolAddress)
      setTick(tickValue)
    }

  }

  /**This useEffect is to call tick spacing contract on change of pool */
  useEffect(() => {

    tickSpacingValue()

  }, [poolAddress])

  //** This function is for calculating high and low price ranges */
  const getPriceRange = async (amount: number) => {

    const bigNumberAmount = new Big(amount)
    const lowerOrUpperTick = priceToTick(bigNumberAmount, tick)
    let TickRange = lowerOrUpperTick

    if (amount === Number(marketPrice)) {
      if (prediction === 'down') {
        setCurrentTick(lowerOrUpperTick - Number(tick))
        TickRange = lowerOrUpperTick - Number(tick)
      } else {
        setCurrentTick(lowerOrUpperTick + Number(tick))
        TickRange = lowerOrUpperTick + Number(tick)
      }
    } else {
      setDestTick(lowerOrUpperTick)
    }

    const price = tickToPrice(TickRange)
    return price

  }

  /**This useEffect is to set the lower and upper ticks automatically */
  useEffect(() => {

    if (prePrediction) {
      setLowerTick(poolData.lowerTick)
      setUpperTick(poolData.upperTick)
    } else {
      if (prediction === 'down') {
        setLowerTick(destTick)
        setUpperTick(currentTick)
      } else {
        setLowerTick(currentTick)
        setUpperTick(destTick)
      }
    }
  }, [prePrediction, prediction, currentTick, destTick])

  /**This functions is to calculate the price ranges at different positions on slider */
  const getSliderPriceRange = async (value: number) => {

    if (marketPrice && Number(value) === 0) {
      const price = await getPriceRange(Number(marketPrice))
      return parseInt(price)
    } else {
      if (marketPrice) {
        const interval = value / 20
        const ratio = value / 100
        const currentIndex = interval
        const postiveOrNegative = prediction === 'up' ? 1 : -1
        const deltaVal = currentIndex * (ratio / interval) * postiveOrNegative
        const deltaPrice = marketPrice * (1 + deltaVal)
        if (deltaPrice) {
          const price = await getPriceRange(Number(deltaPrice))
          return parseInt(parseFloat(price?.toString()))
        }
      }
    }

  }

  /**This function is called on change of input field */
  const simpleTokenInputHandler = (evt: any) => {

    try {
      const value = evt.target.value
      if (specialCharValidation.test(value) || negativeValidation.test(value) || value.split('.').length > 2) {
        return false
      } else {
        if (collateralBal && Number(value) > collateralBal * 10) {
          setSimpleTokenInput(simpleTokenInput)
          setSimpleTokenOrgInput(simpleTokenOrgInput)
        } else {
          const valueTobeSet = value.replace(/\s/g, '')
          const newValue = valueTobeSet.split('.', 2)[1]
          if (newValue && newValue.length > 4) {
            setSimpleTokenInput(simpleTokenInput)
            setSimpleTokenOrgInput(simpleTokenOrgInput)
          } else {
            setSimpleTokenInput(valueTobeSet)
            setSimpleTokenOrgInput(valueTobeSet)
          }
        }
      }
    } catch (error) {
      console.log('error ', error)
    }

  }

  /**This useEffect is to token value, which will be sent to add liquidity contract */
  useEffect(() => {

    if (prediction === 'down') {
      setTokenResult(simpleTokenOrgInput)
      getSliderPriceRange(sliderPercntge)
    } else if (prediction === 'up' && marketPrice) {
      const tokenValue = parseFloat(simpleTokenOrgInput) / marketPrice
      setTokenResult(tokenValue.toFixed(18))
      getSliderPriceRange(sliderPercntge)
    }

  }, [prediction, simpleTokenOrgInput])

  /**This function is to set price ranges according to slider percentages */
  const getSliderValueFunction = async () => {

    const valueAt0 = await getSliderPriceRange(0)
    setPriceAt0(Number(valueAt0))
    const valueAt20 = await getSliderPriceRange(20)
    setPriceAt20(Number(valueAt20))
    const valueAt40 = await getSliderPriceRange(40)
    setPriceAt40(Number(valueAt40))
    const valueAt60 = await getSliderPriceRange(60)
    setPriceAt60(Number(valueAt60))

  }

  /**This function is to calculate slider values according to prediction type */
  useEffect(() => {

    if (marketPrice) {
      getSliderValueFunction()
    }

  }, [marketPrice, prediction])

  /**This function is to call the add liquidity contract */
  const liquidityHandler = async () => {

    if (account && baseTokenAddress && poolAddress) {
      setLoading(true)
      const token1Instance = await contractInstance(account, library, usdcabi['abi'], baseTokenAddress)
      const token1Decimals = await token1Instance.methods.decimals().call()

      let token1Amount: string
      let token2Amount: string
      let minToken1Amount: string
      let minToken2Amount: string

      if (prediction === 'up') {
        token1Amount = ethToWei(tokenResult, 18)
      } else {
        token1Amount = '0'
      }

      if (prediction === 'up') {
        token2Amount = '0'
      } else {
        token2Amount = ethToWei(tokenResult, 18)
      }
      const collateral = ethToWei(Number(collateralInput).toString(), 8)

      const response = await liquidity(
        dispatch,
        account,
        library,
        baseTokenAddress, //Base token address
        token1Amount,
        token2Amount,
        lowerTick,
        upperTick,
        '0',
        '0',
        collateral,
        DEADLINE
      )

      if (response) {
        toggleModal()
        setLoading(false)
        if (response.message) {
          errorToast("Something went wrong: " + response.message)
        }
      }
    }
  }

  /**This useEffect is to close the modal on succeful removal of liquidity */
  useEffect(() => {

    if (transactionSuccess) {
      setLiquidtyTransactionSuccess(true)
      setLoading(false)
      toggleModal()
      dispatch(set_Transaction_Success(false))
    }

  }, [transactionSuccess, transactionHash])

  /**This function is called on change of collateral input field */
  const collateralInputHandler = (evt: any) => {

    try {
      const value = evt.target.value
      if (specialCharValidation.test(value) || negativeValidation.test(value) || value.split('.').length > 2) {
        return false
      } else {
        const valueTobeSet = value.replace(/\s/g, '')
        const newValue = valueTobeSet.split('.', 2)[1]

        if (newValue && newValue.length > 4) {
          setCollateralInput(collateralInput)
        } else {
          setCollateralInput(valueTobeSet)
        }

        //Note: Do not put like "collateralBal &&" in if condition , as it won't work for 0
        //@ts-ignore
        if (parseFloat(value) > parseFloat(collateralBal)) {
          setInsufficientToast(true)
          setCollateralInput(collateralInput)
        } else {
          setInsufficientToast(false)
        }
      }
    } catch (e) {
      console.log(e)
    }

  }

  /**This useEffect is to call insufficient collateral toast */
  useEffect(() => {

    if (account && insufficientToast) {
      errorToast('Insufficient Collateral')
    }

  }, [account, insufficientToast])

  /**  This function is for getting the index price and storing it in baseTokenIndexPrice */
  const getIndexPrice = async () => {

    if (baseTokenAddress) {
      const Index_Price = await indexPrice(BaseTokenAbi, baseTokenAddress)
      if (Index_Price) {
        setBaseTokenIndexPrice(Index_Price.substring(0, 8))
      }
    }

  }

  return (
    <>
      <SharedCard background="secondary" padding="12px 24px" margin="16px 0 0 0">
        <Text fontSize={16} fontWeight={500} lineHeight={24} color="secondary">
          Set Price Range
        </Text>
        <Text fontSize={16} fontWeight={500} lineHeight={24} color="secondary" margin="0 0 16px 0">
          Narrow ({sliderPercntge}% {prediction === 'up' ? <>above</> : <>below</>} the current price)
        </Text>
        <FlexRow gap="26px">
          <Predict
            background="linear-gradient(180deg, rgba(46, 51, 57, 0) 41.67%, #69CC8D 100%)"
            border="1px solid #69CC8D"
            active={prediction === 'down'}
            onClick={() => !prePrediction && setPrediction('down')}
          >
            <img src={up} style={{ marginTop: '6px' }} />
            <Text textAlign="center" fontSize={12} fontWeight={500} lineHeight={18} color="primary" margin="0 0 16px 0">
              Go Down then UP
            </Text>
          </Predict>
          <Predict
            background="linear-gradient(180deg, rgba(46, 51, 57, 0) 41.67%, #E0476A 100%)"
            border="1px solid #E0476A"
            active={prediction === 'up'}
            onClick={() => !prePrediction && setPrediction('up')}
          >
            <img src={down} style={{ marginTop: '6px' }} />
            <Text textAlign="center" fontSize={12} fontWeight={500} lineHeight={18} color="primary" margin="0 0 16px 0">
              Go UP then Down
            </Text>
          </Predict>
        </FlexRow>
        <SliderContainer>
          <Slider
            reverse={prediction === 'down' ? true : false}
            className="up"
            onChange={(nextValues) => {
              if (nextValues !== 0) {
                setSliderPercntge(Math.abs(Number(nextValues)))
                getSliderPriceRange(nextValues)
              }
            }}
            min={0}
            max={60}
            defaultValue={sliderPercntge}
            step={20}
            disabled={prePrediction}
            value={sliderPercntge}
          />
        </SliderContainer>
        {prediction === 'up' ? (
          <FlexRow>
            <Text fontSize={16} fontWeight={700} lineHeight={24} color="secondary" textAlign="center">
              Current <br /> ${priceAt0 ? priceAt0 : '0.00'}
            </Text>
            <Text
              fontSize={16}
              fontWeight={700}
              lineHeight={24}
              color="secondary"
              textAlign="center"
              margin="0 24px 0 0"
            >
              +20% <br /> ${priceAt20 ? priceAt20 : '0.00'}
            </Text>
            <Text
              fontSize={16}
              fontWeight={700}
              lineHeight={24}
              color="secondary"
              textAlign="center"
              margin="0 16px 0 0"
            >
              +40% <br /> ${priceAt40 ? priceAt40 : '0.00'}
            </Text>
            <Text fontSize={16} fontWeight={700} lineHeight={24} color="secondary" textAlign="center">
              +60% <br /> ${priceAt60 ? priceAt60 : '0.00'}
            </Text>
          </FlexRow>
        ) : (
          <FlexRow>
            <Text fontSize={16} fontWeight={700} lineHeight={24} color="secondary" textAlign="center">
              -60% <br /> ${priceAt60 ? priceAt60 : '0.00'}
            </Text>
            <Text
              fontSize={16}
              fontWeight={700}
              lineHeight={24}
              color="secondary"
              textAlign="center"
              margin="0 0 0 24px"
            >
              -40% <br /> ${priceAt40 ? priceAt40 : '0.00'}
            </Text>
            <Text
              fontSize={16}
              fontWeight={700}
              lineHeight={24}
              color="secondary"
              textAlign="center"
              margin="0 0 0 36px"
            >
              -20% <br /> ${priceAt20 ? priceAt20 : '0.00'}
            </Text>
            <Text fontSize={16} fontWeight={700} lineHeight={24} color="secondary" textAlign="center">
              Current <br /> ${priceAt0 ? priceAt0 : '0.00'}
            </Text>
          </FlexRow>
        )}
        <Text
          fontSize={13}
          fontWeight={400}
          lineHeight={20}
          color="primary"
          opacity={0.7}
          margin="16px 0 4px 0"
          padding="0 0"
        >
          {prediction === 'up' ? (
            <>
              I speculate the price will go up as much as {sliderPercntge}% above $
              {marketPrice ? Number(marketPrice).toFixed(2) : '0.00'}. But ultimately it will go below $
              {marketPrice ? Number(marketPrice).toFixed(2) : '0.00'}.{' '}
            </>
          ) : (
            <>
              I speculate the price will go down as much as {sliderPercntge}% below $
              {marketPrice ? Number(marketPrice).toFixed(2) : '0.00'}. But ultimately it will go above $
              {marketPrice ? Number(marketPrice).toFixed(2) : '0.00'}.
            </>
          )}
        </Text>

        <FlexRow justifyContent="flex-start">
          <Flex flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Text fontSize={16} fontWeight={500} lineHeight={18} color="secondary" margin="8px 0">
              Provide Collateral
            </Text>
            <Input
              width="90%"
              margin="0 4px 12px 0"
              placeholder="Amount"
              background="primary"
              borderColor={collateralBal && Number(collateralInput) > collateralBal ? 'red' : '#1c7efe'}
              value={collateralInput}
              onChange={collateralInputHandler}
            />
          </Flex>
          <Flex flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Text fontSize={16} fontWeight={500} lineHeight={18} color="secondary" margin="8px 0">
              Leverage (
              {isNaN(leveragePercentage / 10)
                ? '0.00'
                : leveragePercentage / 10 > 10
                  ? '10'
                  : (leveragePercentage / 10).toFixed(2)}
              x)
            </Text>
            <LeverageBlock>
              <Text fontSize={10} fontWeight={500} lineHeight={16} color="secondary" margin="0 8px 0 0">
                0.00x
              </Text>
              <SliderContainer width="80%" padding="0">
                <Slider
                  defaultValue={0}
                  value={leveragePercentage}
                  min={0}
                  max={100}
                  step={1}
                  onChange={leveragePercentageHandler}
                ></Slider>
              </SliderContainer>
              <Text fontSize={10} fontWeight={500} lineHeight={16} color="secondary" margin="0 0 0 8px">
                10x
              </Text>
            </LeverageBlock>
          </Flex>
        </FlexRow>
        <FlexRow>
          <Text fontSize={16} fontWeight={500} lineHeight={18} color="secondary" margin="16px 0">
            Provide Liquidity
          </Text>
          <Text fontSize={16} fontWeight={500} lineHeight={18} color="secondary" margin="16px 0">
            Buying Power ≈ ${isNaN(Number(collateralInput)) ? '0' : (Number(collateralInput) * 10).toFixed(3)}
          </Text>
        </FlexRow>
        <FlexRow>
          <div>
            <Input
              width="354px"
              margin="0 0 24px 0"
              placeholder="USDC Amount"
              background="primary"
              align="right"
              padding="10px 8px 10px 84px"
              value={isNaN(Number(simpleTokenInput)) ? '' : simpleTokenInput}
              onChange={simpleTokenInputHandler}
              borderColor={Number(simpleTokenOrgInput) > Number(collateralInput) * 10 ? 'red' : '#1c7efe'}
            />
            <TokenWrapper>
              <Token logo={usdc} token="USD" />
            </TokenWrapper>
          </div>
        </FlexRow>
        {notEnoughColletral ? (
          <Text fontSize={16} fontWeight={500} lineHeight={16} color="#CF3E40" margin="0 0 0 8px" textAlign="center">
            Not Enough Collateral
          </Text>
        ) : null}
      </SharedCard>
      <Flex>
        <SharedButton
          margin="18px 0 0 0"
          padding="12px 60px"
          color="#69CC8D"
          onClick={liquidityHandler}
          disabled={
            Number(simpleTokenOrgInput) > Number(collateralInput) * 10 ||
            (collateralBal && Number(collateralInput) > collateralBal) ||
            !simpleTokenOrgInput ||
            !collateralInput ||
            parseFloat(simpleTokenInput) <= 0 ||
            sliderPercntge === 0 ||
            loading ||
            (prediction === 'up' &&
              Number(collateralInput) * 10 < parseFloat(tokenResult) * parseFloat(baseTokenIndexPrice))
          }
        >
          {loading ? 'Loading...' : 'Add Liquidity'}
        </SharedButton>
      </Flex>
    </>
  )
}
export default SimpleLiquidity
