import styled, { css } from "styled-components";

interface NavItemProps {
    active ?: boolean;
    margin ?: string;
}

export const GridBox = styled.div`
    padding: 0 60px;
    display: flex;
`;

const FontStyles = css`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #FFFFFF;
`;

export const FlexContainer = styled.div`
padding: 54px 0 0 0;
display : flex ;
justify-content :flex-start;
justify-content :center;
align-items : center;
/* flex-flow : row wrap; */
`;

export const Items = styled.p<NavItemProps>`
cursor: pointer;
${FontStyles}
font-size: 16px;
font-weight:${(props)=>props.active ? '700' :'400' };
line-height: 24px;
color: ${(props)=>props.active ? props.theme.primaryText : props.theme.secondaryText }; 
padding: 0 12px 16px 12px ;
margin: ${(props) => props.margin ? props.margin : '0 64px 0 0' };
${(props)=>props.active ? "border-bottom: 4px solid #1C7EFF;" :"" }; 
`;

export const Container = styled.div`
padding: 0 0 0 0;
display : flex ;
/* justify-content :flex-start; */
justify-content :center;
align-items : center;
flex-flow : row wrap;
margin: 24px 0;
`;

export const HR = styled.hr`
  width: 100%;
  border: 1px solid rgba(28, 126, 255, 0.1);
  transform: translateY(-4px);
  margin: 0;
`;

export const Block = styled.div`
background: transparent;
padding:12px;
margin-top:16px;
margin-bottom: 18px;
border: 1px solid #374151;
box-shadow: inset 0px 2px 4px #1C1D29;
border-radius: 8px;
display: flex;
justify-content: space-between;
`;

export const ContainerWrapper = styled.div`
padding: 0 60px;
margin-bottom: 24px;
`;

export const TradeContentWrapper = styled.div`
display:flex;
justify-content:space-between;
gap:8px;
align-items:flex-start;
@media (max-width: 1065px) {
  flex-direction:column;
  gap:16px;
  }
`

export const TradeContentChildTwo = styled.div`
display:flex;
justify-content:flex-start;
align-items:flex-start;
flex-direction:column;
@media (max-width: 1065px) {
  width:100%;
  }
`

export const MarketDetailsWrapper = styled.div`
cursor: pointer;
min-width: auto;
display: flex;
justify-content: space-around !important;
align-items: center;
/* flex: 1 1 0; */
${FontStyles}
padding:12px;
font-size: 16px;
line-height: 24px;
color: ${(props)=> props.theme.primaryText }; 
background: rgba(28, 126, 255, 0.2);
border: 1px solid #1C7EFF;
border-radius: 8px;
width:100%;
/* height:57px; */
 @media (max-width: 1325px) {
  padding:12px 30px;
  }
  @media (max-width: 1024px) {
  padding:12px 24px;
  }
  @media (max-width: 1024px) {
  padding:12px;
  }
`;
