/** custom module import of the contract instance */
import contractInstance from '../instances/contractInstance'
import { clearingHouseAddress } from './Config'
import clearHouseAbi from '../abi/clearhouse.json'
import { set_Transaction_Hash, set_Transaction_Type } from '../../logic/redux/actions'
import { useAppDispatch } from '../../logic/redux/hooks'
/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {any} dispatch - useDispatch().
 * @param {string} account - user account address.
 * @param {any} library - wallet connection provider.
 * @param {string} baseTokenAddress - address of base token.
 * @param {boolean} isIsolate - isolate position or not.
 * @param {boolean} isShort - short market or not.
 * @param {string} specifiedAmount - entered amount.
 * @param {boolean} isExactInput - exact input or not.
 * @param {string} oppositeBoundAmount - opposite bound amount of entered amount.
 * @param {boolean} isDefaultGroup - default group or not.
 * @param {number} groupId - id of the group.
 * @param {number} positionId - id of the position.
 * @param {string} collateralForPosition - collateral amount provided by user.
 * @param {string} sqrtPriceLimit - square root of price limit.
 * @param {string} orderType - type of market.
 * @param {string} trigeringPrice - triggering price or limit price of markets.
 * @param {number} DEADLINE - Dead line of contract.
 * */

/** this function is used to call the liquidity contract on wallets */
const openPostionMarket = async (
  dispatch: any,
  account: string,
  library: any,
  baseTokenAddress: string, //Base token address
  isIsolate: boolean,
  isShort: boolean,
  specifiedAmount: string,
  isExactInput: boolean,
  oppositeBoundAmount: string,
  isDefaultGroup: boolean,
  groupId: number,
  positionId: number,
  collateralForPosition: string,
  sqrtPriceLimit: string,
  orderType:string,
  trigeringPrice:string,
  DEADLINE: number
) => {
  try {
    if (account) {
      const clearingHouseInstance = await contractInstance(account, library, clearHouseAbi['abi'], clearingHouseAddress)

      const gasLimit = await clearingHouseInstance.methods
        .openPosition([
          baseTokenAddress, //Base token address
          isIsolate,
          isShort,
          specifiedAmount,
          isExactInput,
          oppositeBoundAmount,
          isDefaultGroup,
          groupId,
          positionId,
          collateralForPosition,
          sqrtPriceLimit,
          orderType,
          trigeringPrice,
          DEADLINE,
        ])
        .estimateGas({
          from: account,
        })

      await clearingHouseInstance.methods
        .openPosition([
          baseTokenAddress,
          isIsolate,
          isShort,
          specifiedAmount,
          isExactInput,
          oppositeBoundAmount,
          isDefaultGroup,
          groupId,
          positionId,
          collateralForPosition,
          sqrtPriceLimit,
          orderType,
          trigeringPrice,
          DEADLINE,
        ])
        .send({
          from: account,
          gas: gasLimit + 1000,
        })
        .on('transactionHash', (e: string) => {
          dispatch(set_Transaction_Hash(e))
          dispatch(set_Transaction_Type('Transaction'))
        })
    }
  } catch (error) {
    console.log('error in openPostionMarket', error)
    //@ts-ignore
    if (error?.message.split('.')[1]) {
      //@ts-ignore
      return JSON.parse(error.message.split('.')[1])
    } else {
      return error
    }
  }
}

export default openPostionMarket
