/** Library imports */
import React, { useEffect } from 'react'

/** Custom imports */
import { FlexBox, Image, Img, Text, Wrapper } from './styles'
import { useAppDispatch } from '../../../../../logic/redux/hooks'
import { set_graph_symbol, set_market_address } from '../../../../../logic/redux/actions'
import up from '../../../../../assets/images/stroke-up.svg'
import down from '../../../../../assets/images/stroke-down.svg'
import drop from '../../../../../assets/images/drop.svg'
interface Props {
  logo?: string
  token?: string
  value?: string
  position?: string
  setSelectedToken?: any
  marketGraphSymbol: string
  active?: boolean
  poolAddress: string
  setSelectedPool?: any
  width?: string
  setMarketPrice?: any
  setSelectedTokenLogo?: any
  marketAddress: string
  liquidityPositions?: any
}

export const TokenCard = (props: Props) => {
  const dispatch = useAppDispatch()
  const {
    logo,
    token,
    value,
    position,
    setSelectedToken,
    setSelectedTokenLogo,
    active,
    poolAddress,
    setSelectedPool,
    width,
    setMarketPrice,
    marketGraphSymbol,
    marketAddress,
    liquidityPositions,
  } = props

  /**This useEffect it select logo and market price on first go */
  useEffect(() => {
    if (active) {
      setSelectedTokenLogo(logo)
      setMarketPrice(value)
    }
  })

  return (
    <Wrapper
      onClick={() => {
        setSelectedToken(token)
        setSelectedPool(poolAddress)
        setMarketPrice(value)
        setSelectedTokenLogo(logo)
        dispatch(set_graph_symbol(marketGraphSymbol))
        dispatch(set_market_address(marketAddress))
      }}
      active={active}
      width={width}
    >
      <FlexBox>
        <Img src={logo} alt="" />
        <Text active={active}>
          {token} <br /> <span>{value} </span>{' '}
        </Text>
        {liquidityPositions > 0 && (
          <img style={{ position: 'relative', top: '-11px', left: '40px' }} src={drop} alt="" />
        )}
        {position === 'up' ? <Image src={up} alt="" /> : <Image src={down} alt="" />}
      </FlexBox>
    </Wrapper>
  )
}
