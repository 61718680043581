/** custom module import of the contract instance */
import contractInstance from '../instances/contractInstance'
import { weiToEth } from './Converstion'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {string} account - wallet address of user.
 * @param {any} library - wallet connection provider.
 * @param {object} oddzabi - abi of oddz contract.
 * @param {string} oddzVault - address of token.
 * @param {object} abi - abi of token contract.
 * @param {string} tokenAddress - address of token.
 */
const collateralBalance = async (
  account: string,
  library: any,
  oddzabi: object,
  oddzVault: string,
  abi: object,
  tokenAddress: string
) => {
  const oddzVaultContractInstance = await contractInstance(account, library, oddzabi, oddzVault)

  const tokenContractInstance = await contractInstance(account, library, abi, tokenAddress)
  const decimals = await tokenContractInstance.methods.decimals().call()

  const balance = await oddzVaultContractInstance.methods.getAvailableCollateral(account).call()
  const collateralBal = weiToEth(balance, decimals)
  return collateralBal
}

export default collateralBalance
