/** custom module import of the contract instance */
import contractInstance from '../instances/contractInstance'
import { clearingHouseAddress } from './Config'
import clearHouseAbi from '../../blockchain/abi/clearhouse.json'
import { set_Transaction_Hash, set_Transaction_Type } from '../../logic/redux/actions'
/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {any} dispatch - useDispatch().
 * @param {string} account - user account address.
 * @param {any} library - wallet connection provider.
 * @param {string} baseTokenAddress - address of base token. 
 * @param {number} lowerTick - lower tick of contract.
 * @param {number} upperTick - upper tick of contract. 
 * @param {string} liquidityAmount - amount of liquidity.
 * @param {boolean} isClose - close position or not.
 * @param {boolean} isIsolate - isolate position or not.
 * @param {boolean} isDefaultGroup - default group or not.
 * @param {number} groupId - id of the group.
 * @param {number} positionId - id of the position.
 * @param {number} collateralForPosition - provided collateral for the contract.
 * @param {string} minBaseAmount - minimum amount of base token (with slippage).
 * @param {string} minQouteAmount - minimum amount of quote token (with slippage).
 * @param {string} orderType - type of market.
 * @param {string} trigeringPrice - triggering price or limit price of markets.
 * @param {number} DEADLINE - Dead line of contract.  
 

/** this function is used to call the liquidity contract on wallets */
const removeLiquidity = async (
  dispatch: any,
  account: string,
  library: any,
  baseTokenAddress: string, //Base token address
  lowerTick: string,
  upperTick: string,
  liquidityAmount: string,
  isClose: boolean,
  isIsolate: boolean,
  isDefaultGroup: boolean,
  groupId: number,
  positionId: number,
  collateralForPosition: number,
  minBaseAmount: string,
  minQouteAmount: string,
  orderType:string,
  trigeringPrice:string,
  DEADLINE: string
) => {
  try {
    if (account) {
      const clearingHouseInstance = await contractInstance(account, library, clearHouseAbi['abi'], clearingHouseAddress)

      const gasLimit = await clearingHouseInstance.methods
        .removeLiquidity([
          baseTokenAddress, //Base token address
          lowerTick,
          upperTick,
          liquidityAmount,
          isClose,
          isIsolate,
          isDefaultGroup,
          groupId,
          positionId,
          collateralForPosition,
          minBaseAmount,
          minQouteAmount,
          orderType,
          trigeringPrice,
          DEADLINE,
        ])
        .estimateGas({
          from: account,
        })
      await clearingHouseInstance.methods
        .removeLiquidity([
          baseTokenAddress, //Base token address
          lowerTick,
          upperTick,
          liquidityAmount,
          isClose,
          isIsolate,
          isDefaultGroup,
          groupId,
          positionId,
          collateralForPosition,
          minBaseAmount,
          minQouteAmount,
          orderType,
          trigeringPrice,
          DEADLINE,
        ])
        .send({
          from: account,
          gas: gasLimit + 1000,
        })
        .on('transactionHash', (e: string) => {
          dispatch(set_Transaction_Hash(e))
          dispatch(set_Transaction_Type('Remove Liquidity'))
        })
    }
  } catch (error) {
    console.log('error in remove Liquidity', error)
    //@ts-ignore
    if (error?.message.split('.')[1]) {
      //@ts-ignore
      return JSON.parse(error.message.split('.')[1])
    } else {
      return error
    }
  }
}

export default removeLiquidity
