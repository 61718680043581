/** custom module import of the contract instance */
import contractInstance from '../instances/contractInstance'
import balanceManagerAbi from '../../blockchain/abi/balanceManager.json'
import { balanceManager } from './Config'

/**
 * This function is used to fetch the token balance from blockchain
 * @constructor
 * @param {string} account - wallet address of user.
 * @param {any} library - wallet connection provider.
 * @param {string} baseTokenAddress - address of base Token.
 * @param {string} id - orderId of market.
 */
const balanceManagerContract = async (account: string, library: any, baseTokenAddress: string, id: string) => {
  try {
    const balanceManagerInstance = await contractInstance(account, library, balanceManagerAbi['abi'], balanceManager)
    const profitLoss = await balanceManagerInstance.methods
      .getLiquidityPositionUnrealisedPnL(id)
      .call()
    const groupId = await balanceManagerInstance.methods.getDefaultGroupForTrader(account).call()

    return [profitLoss, groupId]
  } catch (error) {
    console.log('error in balanceManagerInstance', error)
  }
}

export default balanceManagerContract
