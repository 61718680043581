import styled from "styled-components";

interface ModalBodyProps {
  show: boolean;
}

interface ModalContentProps {
  width ?: string;
  background ?: string;
  borderRadius ?: string;
  boxShadow ?: string;
  padding ?: string;
}

export const ModelHead = styled.div<any>`
  color: ${(props) => (props.theme.primaryText)};
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const ModalBody = styled.div<ModalBodyProps>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* background:rgba(26, 39, 50, 0.7); */
  background: rgba(1, 1, 1, 0.8);
  backdrop-filter: blur(5px);
`;

export const ModalContent = styled.div<ModalContentProps>`
  padding: ${(props) => props.padding ? props.padding : '20px' };
  display: inline-block;
  border-radius: 8px;
  margin: 0 auto;
  border: none;
  border-radius: 4px;
  overflow: visible;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  max-height: 85%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  /* max-height: 100%; */
  width: ${(props) => props.width ? props.width : '320px'};
  /* height: 418px; */
    background: ${(props) => props.background === 'primary' ? props.theme.primary : props.background === 'secondary' ? props.theme.secondary :  '#222D40' };
    box-shadow: ${(props) => props.boxShadow ? props.boxShadow : '0px 4px 12px rgba(218, 220, 224, 0.25)' };   
    border-radius: 12px;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  /* width: 80%; */
  @media (max-width: 600px) {
    width: 80%;
  }
`;

export const Close = styled.img`
  cursor: pointer;
  margin-right: 0;
`;
