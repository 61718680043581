import styled, { css } from 'styled-components'

interface ButtonProps {
  disabled?: boolean
  width?: string
}

const FontStyles = css`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
`

export const Button = styled.button<ButtonProps>`
  ${FontStyles};
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  background: #1c7eff;
  mix-blend-mode: normal;
  border: 1.5px solid #8d9eea;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 12px 48px;
  width: ${(props) => props.width};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

export const ThreeColumns = styled.div`
  display: grid;
  grid-gap: 0px;
  padding: 24px;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 410px) {
    padding: 24px;
    grid-gap: 8px;
    /* grid-template-columns: repeat(1, 1fr); */
  }
  @media (min-width: 700px) {
    padding: 24px;
    grid-gap: 8px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 1023px) {
    padding: 66px;
    grid-gap: 8px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1280px) {
    padding: 66px;
    grid-gap: 77px;
    grid-template-columns: repeat(3, 1fr);
  }
`

interface TableProps {
  padding?: string
}
export const TableStyles = styled.div<TableProps>`
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #3a365f;
    border-radius: 16px;
  }

  tr:last-child td:last-child {
    border-radius: 0 0 16px 0;
  }
  tr:last-child td:first-child {
    border-radius: 0 0 0 16px;
  }
  th:last-child {
    border-radius: 0 16px 0 0;
  }
  th:first-child {
    border-radius: 16px 0 0 0;
  }

  th,
  td {
    width: auto;
    background: transparent;
    /* background: ${(props) => props.theme.primary}; */
    /* border-radius: 4px; */
    padding: 24px 24px;
    border-top: 1px solid #3a365f;
    border-bottom: 1px solid #3a365f;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.primaryText};
    min-width: 148px;
  }

  th {
    font-size: 12px;
    /* letter-spacing: 0.03em; */
    text-transform: uppercase;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* color: rgba(255, 255, 255, 0.72); */
    color: ${(props) => props.theme.secondaryText};
  }

  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : '25px')};
  display: flex;
  justify-content: flex-start;
  border-radius: 16px;
  margin-bottom: 60px;
  flex-direction: column;
  /* overflow-x: auto; */

  @media (max-width: 1024px) {
    td {
      min-width: 140px;
    }
  }
`

interface BlockProps {
  gap?: number | string
  margin?: string
  justifyContent?: string
}

export const Block = styled.div<BlockProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  gap: ${({ gap }) => (gap || '10') + 'px'};
  margin: ${({ margin }) => margin};
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
interface I_FlexProps {
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  onClick?: any
  gap?: string
  margin?: string
  width?: string
  padding?: string
  height?: string
  scrollX?: boolean
}

export const FlexRow = styled.div<I_FlexProps>`
  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'row')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  cursor: auto;
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};

  .logo-matic {
    height: 20px;
    width: 20px;
  }
`
