/** Library imports */
import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import Slider from 'rc-slider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import BigNumber from "bignumber.js";

/** Custom imports */
import { SharedCard } from '../../../../../shared/sharedCard'
import { Items, Container, Block } from '../styles'
import { SliderContainer, Type, TypeButton } from '../../pool/styles'
import { Flex, SharedButton } from '../../../../../shared/shared'
import { Text } from '../../../../../shared/styles/typography'
import { ArrowImage, DropdownValues, Input } from './styles'
import downArrow from '../../../../../assets/images/downArrow.svg'
import { Dropdown, DropDownContainer, RadioButton } from '../../pool/components/styles'
import { negativeValidation, specialCharValidation } from '../../../../../utils/helper'
import swapRouterContract from '../../../../../blockchain/helper/SwapRouterContract'
import { ethToWei, weiToEth } from '../../../../../blockchain/helper/Converstion'
import { API_URL, oddzVault, quoteTokenAddress, usdcTokenAddress } from '../../../../../blockchain/helper/Config'
import MarketPrice from '../../../../../blockchain/helper/marketPrice'
import PoolAbi from '../../../../../blockchain/abi/pool.json'
import openPostionMarket from '../../../../../blockchain/helper/OpenPositionMarket'
import collateralBalance from '../../../../../blockchain/helper/CollateralBalance'
import usdcabi from '../../../../../blockchain/abi/usdc.json'
import vaultabi from '../../../../../blockchain/abi/vault.json'
import OddzMarketContract from '../../../../../blockchain/helper/OddzMarketContract'
import { DEADLINE, handleMaxSold, handleMinReceive } from '../../../../../blockchain/helper/handleMinMax'
import contractInstance from '../../../../../blockchain/instances/contractInstance'
import OddzConfigContract from '../../../../../blockchain/helper/OddzConfigContract'
import { useAppDispatch, useAppSelector } from '../../../../../logic/redux/hooks'
import { set_Transaction_Success } from '../../../../../logic/redux/actions'
import { errorToast } from '../../../../../blockchain/helper/toasts'
import { ToolTip } from '../../../../../shared/tooltip'
import { FlexRow } from '../../../../../shared/styles/styles'
interface Props {
  baseTokenAddr: string
  poolAddress: string
  selectedToken: string
  setPositionTransactionSuccess?: any
  marketPrice?: number
}

const Orders = (props: Props) => {

  const { baseTokenAddr, poolAddress, selectedToken, setPositionTransactionSuccess,
    marketPrice } = props
  const { account, library, chainId } = useWeb3React()
  const dispatch = useAppDispatch()
  const { transactionSuccess } = useAppSelector((state) => state.graphSymbol)

  const [positionType, setPositionType] = useState<string>('Long') // Type of Position Short or Long
  const orderType = ['Market', 'Limit', 'Stop'] // Types for Order
  const [orderTypes, setOrderTypes] = useState(orderType[0]) //
  const [dropdown, setDropdown] = useState<boolean>(false)
  const [dropdownValue, setDropdownValue] = useState<string>('Isolated margin')

  const [baseTokenInput, setBaseTokenInput] = useState<string>('')
  const [baseTokenOrgInput, setBaseTokenOrgInput] = useState<string>('')
  const [quoteTokenInput, setQuoteTokenInput] = useState<string>('')
  const [quoteTokenOrgInput, setQuoteTokenOrgInput] = useState<string>('')

  const [collateralInput, setCollateralInput] = useState<string>('')
  const [collateralBal, setcollateralBal] = useState<number>(0)
  const [leveragePercentage, setLeveragePercntge] = useState<number>(0)

  const [group, setGroup] = useState<string>('new')
  const [isolatedPosition, setIsolatedPosition] = useState<string>('new')
  const [enteredIn, setEnteredIn] = useState<string>('')
  const [groupId, setGroupId] = useState<string>('')
  const [positionId, setPositionId] = useState<string>('')
  const [insufficientToast, setInsufficientToast] = useState<boolean>(false)
  const [marketFee, setMarketFee] = useState<number>(0)
  const [swapFee, setSwapFee] = useState<number>(0)
  const [priceImpact, setPriceImpact] = useState<number>(0)
  const [groupsData, setGroupsData] = useState<any>([])
  const [positionData, setPositionData] = useState<any>([])
  const [loading, setLoading] = useState(false)

  /**This function is to reset the position values */
  const resetPositionValues = () => {

    setIsolatedPosition('new')
    setGroup('new')

  }

  /**This function is to reset all the values */
  const resetValues = () => {

    setCollateralInput('')
    setBaseTokenInput('')
    setBaseTokenOrgInput('')
    setQuoteTokenInput('')
    setQuoteTokenOrgInput('')
    setDropdownValue("Isolated margin")
    setLeveragePercntge(0)
    setPriceImpact(0)
    resetPositionValues()

  }

  /**This useEffect is to call the resetValues function */
  useEffect(() => {

    resetValues()

  }, [positionType, orderTypes, baseTokenAddr])

  /**This useEffect is to call the resetPositionValues function */
  useEffect(() => {

    resetPositionValues()

  }, [dropdownValue])

  /**This function is to check the max groups limit exceeded or not */
  const maxGroupPositionsLimit = async () => {

    if (account) {
      const maxGroupPositions = await OddzConfigContract(account, library)

      if (dropdownValue === 'Cross margin' && parseFloat(groupsData.length) >= parseFloat(maxGroupPositions?.maxGroups)) {
        errorToast('Max Groups Limit Exceeded')
        return true
      } else {
        return false
      }
    }

  }

  /** This function is used to get the collateral amount to calculate BUYING POWER */
  const getDepositedBalance = async () => {

    if (account) {
      const Balance = await collateralBalance(
        account,
        library,
        vaultabi['abi'],
        oddzVault,
        usdcabi['abi'],
        usdcTokenAddress
      )
      if (Balance) {
        setcollateralBal(Number(Balance))
      }
    }

  }

  /**This function is to get the swapfee and market fee values */
  const getMarketFee = async () => {

    if (account) {
      const marketData = await OddzMarketContract(
        account,
        library,
        baseTokenAddr
      );
      if (marketData) {
        const swapFee = marketData[0]
        const swapFeePercentage = parseFloat(swapFee) / 1000000
        setSwapFee(swapFeePercentage)
        setMarketFee(marketData[1])
      }
    }

  }

  /**This useEffect is to call the getMarketPrice, getDepositedBalance, getMarketFee */
  useEffect(() => {

    if (poolAddress) {
      getDepositedBalance()
      getMarketFee()
    }

  }, [account, chainId, poolAddress])

  /**This function is called on change of base token input field */
  const handleBaseTokenInput = async (evt: any) => {

    const value = evt.target.value
    setEnteredIn("base")
    if (
      specialCharValidation.test(value) ||
      negativeValidation.test(value) ||
      value.split(".").length > 2
    ) {
      return false;
    }
    else {

      const valueTobeSet = value.replace(/\s/g, '')
      const newValue = valueTobeSet.split('.', 2)[1]

      if (newValue && newValue.length > 4) {
        setBaseTokenInput(baseTokenInput)
        setBaseTokenOrgInput(baseTokenOrgInput)
      } else {
        setBaseTokenInput(valueTobeSet)
        setBaseTokenOrgInput(valueTobeSet)
        setQuoteTokenInput('')
        setQuoteTokenOrgInput('')
        if (account) {
          const tokenInstance = await contractInstance(
            account,
            library,
            usdcabi["abi"],
            baseTokenAddr
          );
          const basetokenLimit = await tokenInstance.methods.balanceOf(poolAddress).call()
          const decimals = await tokenInstance.methods.decimals().call()

          const baseTokenLimitInEth = weiToEth(basetokenLimit, decimals)
          if (parseFloat(valueTobeSet) > parseFloat(baseTokenLimitInEth)) {
            setBaseTokenInput(Number(baseTokenLimitInEth).toFixed(4))
            setBaseTokenOrgInput(baseTokenLimitInEth)
            errorToast('Not Enough Liquidity')
          }
        }
      }
      if (!valueTobeSet || Number(valueTobeSet) <= 0) {
        setQuoteTokenInput('')
        setQuoteTokenOrgInput('')
      }
    }

  }

  /**This function is for calculating quote token based on base token input*/
  const quoteTokenCalculation = async (value: string) => {

    if (account && chainId && baseTokenAddr) {
      setQuoteTokenInput(quoteTokenInput ? quoteTokenInput : "Loading...")
      let amountInAddress: string
      let amountOutAddress: string
      const isShort = positionType === 'Long' ? false : true
      if (isShort) {
        amountInAddress = baseTokenAddr
        amountOutAddress = quoteTokenAddress
      } else {
        amountInAddress = quoteTokenAddress
        amountOutAddress = baseTokenAddr
      }
      const result = await swapRouterContract(
        positionType === 'Long' ? true : false,
        amountInAddress, //Base token address
        amountOutAddress,
        marketFee,
        ethToWei(value, 18)
      )
      const resultInEth = weiToEth(parseFloat(result).toString(), 18)
      //@ts-ignore
      if (!isNaN(resultInEth) && marketPrice) {
        setQuoteTokenInput(Number(resultInEth).toFixed(4))
        setQuoteTokenOrgInput(resultInEth)

        if (isShort) {
          const BaseTokenPrice = parseFloat(value) * marketPrice
          const impact = (Math.abs(parseFloat(resultInEth) - BaseTokenPrice) / BaseTokenPrice) * 100
          setPriceImpact(impact)
        } else {
          const BaseTokenPrice = parseFloat(value) * marketPrice
          const impact = (Math.abs(BaseTokenPrice - parseFloat(resultInEth)) / BaseTokenPrice) * 100
          setPriceImpact(impact)
        }
      } else {
        setQuoteTokenInput('')
        setQuoteTokenOrgInput('')
        setPriceImpact(0)
      }
    } else {
      setQuoteTokenInput('')
      setQuoteTokenOrgInput('')
      setPriceImpact(0)
    }

  }

  /**This function is called on change of quote token input field */
  const handleQuoteTokenInput = async (evt: any) => {

    const value = evt.target.value
    setEnteredIn("quote")
    if (
      specialCharValidation.test(value) ||
      negativeValidation.test(value) ||
      value.split(".").length > 2
    ) {
      return false;
    }
    else {
      const valueTobeSet = value.replace(/\s/g, '')
      const newValue = valueTobeSet.split('.', 2)[1]
      if ((newValue && newValue.length > 4) || (collateralBal && (Number(value) > (collateralBal * 10)))) {
        setQuoteTokenInput(quoteTokenInput)
        setQuoteTokenOrgInput(quoteTokenOrgInput)
      } else {
        setQuoteTokenInput(valueTobeSet)
        setQuoteTokenOrgInput(valueTobeSet)
        setBaseTokenInput('')
        setBaseTokenOrgInput('')
        if (account) {
          const tokenInstance = await contractInstance(
            account,
            library,
            usdcabi["abi"],
            quoteTokenAddress
          );
          const quoteTokenLimit = await tokenInstance.methods.balanceOf(poolAddress).call()
          const decimals = await tokenInstance.methods.decimals().call()

          const quoteTokenLimitInEth = weiToEth(quoteTokenLimit, decimals)
          if (parseFloat(valueTobeSet) > parseFloat(quoteTokenLimitInEth)) {
            setQuoteTokenInput(Number(quoteTokenLimitInEth).toFixed(4))
            setQuoteTokenOrgInput(quoteTokenLimitInEth)

            errorToast('Not Enough Liquidity')
          }
        }
      }

      if (!valueTobeSet || Number(valueTobeSet) <= 0) {
        setBaseTokenInput('')
        setBaseTokenOrgInput('')
      }
    }
  }

  /**This function is for calculating base token based on quote token input */
  const baseTokenCalculation = async (value: string) => {

    if (account && chainId && baseTokenAddr) {
      setBaseTokenInput(baseTokenInput ? baseTokenInput : "Loading...")
      let amountInAddress: string
      let amountOutAddress: string
      const isShort = positionType === 'Long' ? false : true

      if (isShort) {
        amountInAddress = baseTokenAddr
        amountOutAddress = quoteTokenAddress
      } else {
        amountInAddress = quoteTokenAddress
        amountOutAddress = baseTokenAddr
      }

      const result = await swapRouterContract(
        positionType === 'Long' ? false : true,
        amountInAddress, //Base token address
        amountOutAddress,
        marketFee,
        ethToWei(value, 18)
      )
      const resultInEth = weiToEth(parseFloat(result).toString(), 18)
      //@ts-ignore
      if (!isNaN(resultInEth) && MarketPrice) {
        setBaseTokenInput(Number(resultInEth).toFixed(4))
        setBaseTokenOrgInput(resultInEth)

        if (isShort) {
          const quoteValue = parseFloat(value) / marketPrice
          const impact = ((parseFloat(resultInEth) - quoteValue) / parseFloat(resultInEth)) * 100
          setPriceImpact(impact)
        } else {
          const quoteValue = parseFloat(value) / marketPrice
          const impact = ((quoteValue - parseFloat(resultInEth)) / quoteValue) * 100
          setPriceImpact(impact)
        }
      } else {
        setBaseTokenInput('')
        setBaseTokenOrgInput('')
        setPriceImpact(0)
      }
    } else {
      setBaseTokenInput('')
      setBaseTokenOrgInput('')
      setPriceImpact(0)
    }

  }

  /**This function is called on change of collateral input field */
  const collateralInputHandler = (evt: any) => {

    try {
      const value = evt.target.value
      if (specialCharValidation.test(value) || negativeValidation.test(value) || value.split('.').length > 2) {
        return false
      } else {
        const valueTobeSet = value.replace(/\s/g, '')
        const newValue = valueTobeSet.split('.', 2)[1]
        if (newValue && newValue.length > 4) {
          setLeveragePercntge(0)

          setCollateralInput(collateralInput)
        } else {
          setLeveragePercntge(0)
          setCollateralInput(valueTobeSet)
        }

        //Note: Do not put like "collateralBal &&" in if condition , as it won't work for 0
        //@ts-ignore
        if (parseFloat(value) > parseFloat(collateralBal)) {
          setInsufficientToast(true)
          setCollateralInput(collateralInput)
        } else {
          setInsufficientToast(false)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**This useEffect resets quote and base token inputs based on other token */
  useEffect(() => {

    if ((baseTokenOrgInput === '' || Number(baseTokenOrgInput) <= 0) && enteredIn === "base") {
      setQuoteTokenInput('');
      setQuoteTokenOrgInput('')
    }
    if ((quoteTokenOrgInput === '' || Number(quoteTokenOrgInput) <= 0) && enteredIn === "quote") {
      setBaseTokenInput('');
      setBaseTokenOrgInput('')
    }

  }, [baseTokenOrgInput, quoteTokenOrgInput])

  /**This useEffect is to show the insufficient collateral toast */
  useEffect(() => {

    if (account && insufficientToast) {
      errorToast('Insufficient Collateral')
    }

  }, [account, insufficientToast])

  /**This function is called on change slider postion */
  const leveragePercentageHandler = (value: any) => {
    setLeveragePercntge(Number(value))
    setEnteredIn("quote")

    const USDvalue = ((Number(collateralInput) * 10) * (value / 100))
    setQuoteTokenInput(USDvalue.toFixed(4))
    setQuoteTokenOrgInput(USDvalue.toString())
    setBaseTokenInput('')
    setBaseTokenOrgInput('')
  }

  //**This useEffect is for Debounse method on input fields */
  useEffect(() => {
    if (enteredIn === 'quote' && quoteTokenOrgInput) {
      const timer = setTimeout(() => {
        baseTokenCalculation(quoteTokenOrgInput)
      }, 1000)
      return () => {
        clearTimeout(timer);
      }
    } else if (enteredIn === 'base' && baseTokenOrgInput) {
      const timer = setTimeout(() => {
        quoteTokenCalculation(baseTokenOrgInput)
      }, 1000)
      return () => {
        clearTimeout(timer);
      }
    }

  }, [enteredIn, quoteTokenOrgInput, baseTokenOrgInput])

  /**This useEffect sets slider value automatically */
  useEffect(() => {

    if (collateralInput) {
      const percentage = (Number(quoteTokenOrgInput) / (Number(collateralInput) * 10)) * 100
      setLeveragePercntge(percentage)
    }

  }, [quoteTokenOrgInput, collateralInput])

  /**This function is for getting existing groups data */
  const getGroupPositions = (groupsInfo: any) => {

    axios
      .get(API_URL + 'trade/user_groups', {
        params: {
          wallet_address: account
        },
      })
      .then((res: any) => {
        const groupsArray = [...groupsInfo]
        res.data.data.map((data: any) => {
          const groupIdIncluded = groupsArray.includes(data.groupID)
          if (!groupIdIncluded) {
            groupsArray.push(data.groupID)
          }
        })
        setGroupsData(groupsArray)
      })
      .catch((error: any) => {
        console.log(error);
      })

  }

  /**This useEffect is for getting the existin positions data */
  const getPositionData = () => {
    setPositionData([])
    setGroupsData([])
    axios
      .get(API_URL + 'trade/positions', {
        params: {
          wallet_address: account,
        },
      })
      .then((res) => {
        let array: any = []
        const groupsArray = [...groupsData]
        res.data.data.positionsData.map((data: any) => {
          if (data.baseToken.toLowerCase() === baseTokenAddr.toLowerCase() && data.groupID === 0) {
            array.push(data.positionID)
          } else if (data.baseToken.toLowerCase() === baseTokenAddr?.toLowerCase() && data.groupID !== 0) {
            groupsArray.push(data.groupID.toString())
          }
        })
        setPositionData(array)
        getGroupPositions(groupsArray)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  /**This useEffect is for setting default position id */
  useEffect(() => {

    if (positionData.length > 0) {
      setPositionId(positionData[0])
    }

  }, [positionData])

  /**This useEffect is for setting default group id */
  useEffect(() => {

    if (groupsData.length > 0) {
      setGroupId(groupsData[0])
    }

  }, [groupsData])

  /**This useEffect is for calling getGroupPositions && getPositionData */
  useEffect(() => {

    account && getPositionData()

  }, [account, baseTokenAddr])

  /**This function is for calling the open position market contract */
  const confirmPositionMarket = async () => {

    const maxLimitReached = await maxGroupPositionsLimit()
    if (account && !maxLimitReached) {
      setLoading(true)
      const isIsolate = dropdownValue === 'Cross margin' ? false : true
      const isShort = positionType === 'Long' ? false : true
      let isExactInput: boolean
      let enteredAmount: string
      let oppisiteBoundAmount: string
      let groupIdValue: string
      let positionIdValue: string
      if (isIsolate) {
        if (isolatedPosition === 'new') {
          positionIdValue = ''
        } else {
          positionIdValue = positionId
        }
        groupIdValue = ''
      } else {
        positionIdValue = ''
        if (group === 'new') {
          groupIdValue = ''
        } else {
          groupIdValue = groupId
        }
      }

      const defaultSlippage = 40
      const largeBigValue = new BigNumber(Math.pow(2, 96))
      const minSqrtPrice = new BigNumber(parseFloat(quoteTokenOrgInput) / parseFloat(baseTokenOrgInput)).squareRoot()
      const minSqrtPriceLimit = new BigNumber(minSqrtPrice).multipliedBy(largeBigValue).toFixed()

      if (isShort) {
        if (enteredIn === 'base') {
          isExactInput = true
          enteredAmount = ethToWei(baseTokenOrgInput, 18)
          oppisiteBoundAmount = ethToWei(handleMinReceive(quoteTokenOrgInput, defaultSlippage).toFixed(18), 18)

        } else {
          isExactInput = false
          enteredAmount = ethToWei(quoteTokenOrgInput, 18)
          oppisiteBoundAmount = ethToWei(handleMaxSold(baseTokenOrgInput, defaultSlippage).toFixed(18), 18)
        }

      } else {
        if (enteredIn === 'base') {
          isExactInput = false
          enteredAmount = ethToWei(baseTokenOrgInput, 18)
          oppisiteBoundAmount = ethToWei(handleMaxSold(quoteTokenOrgInput, defaultSlippage).toFixed(18), 18)
        } else {
          isExactInput = true
          enteredAmount = ethToWei(quoteTokenOrgInput, 18)
          oppisiteBoundAmount = ethToWei(handleMinReceive(baseTokenOrgInput, defaultSlippage).toFixed(18), 18)
        }
      }

      const isDefaultGroup = false
      const collateral = ethToWei(Number(collateralInput).toString(), 8)

      const response = await openPostionMarket(
        dispatch,
        account,
        library,
        baseTokenAddr, //Base token address
        isIsolate,
        isShort,
        enteredAmount,
        isExactInput,
        oppisiteBoundAmount,
        isDefaultGroup,
        Number(groupIdValue),
        Number(positionIdValue),
        collateral,
        minSqrtPriceLimit.split('.')[0] ? minSqrtPriceLimit.split('.')[0] : minSqrtPriceLimit, /** sqrt price limit */
        '0',
        '0',
        DEADLINE
      )

      if (response) {        // it is a catch block error
        setLoading(false)
        if (response.message) {
          errorToast("Something went wrong: " + response.message)
        }
      }
    }
  }

  /**This useEffect is for resetting values on succesful transaction */
  useEffect(() => {

    if (transactionSuccess) {
      resetValues()
      setPositionTransactionSuccess(true)
      setLoading(false)
      dispatch(set_Transaction_Success(false))
    }

  }, [transactionSuccess])

  return (
    <SharedCard background="primary" width="340px" height="678px" scrollX={true} border='1px solid #1C7EFF'>
      <Flex>
        <Type>
          <TypeButton
            color="#1C7EFF"
            active={positionType === 'Long'}
            onClick={() => setPositionType('Long')}
            width="80px"
            margin="2px"
          >
            Long
          </TypeButton>
          <TypeButton
            color="#1C7EFF"
            active={positionType === 'Short'}
            onClick={() => setPositionType('Short')}
            width="80px"
            margin="2px"
          >
            Short
          </TypeButton>
        </Type>
      </Flex>
      <Container>
        {orderType.map((type, index) => (
          <Items
            margin="0 4px 0 0"
            key={index}
            active={orderTypes === type}
            onClick={() => {
              setOrderTypes(type)
            }}
          >
            {type}
          </Items>
        ))}
      </Container>

      <FlexRow gap="8px" justifyContent="space-between">
        <Text fontSize={14} fontWeight={500} lineHeight={21} color="secondary" margin="24px 0 6px 0">
          Provide Collateral
        </Text>
        <Text fontSize={14} fontWeight={500} lineHeight={21} color="secondary" margin="16px 0 6px 0">
          Buying Power <ToolTip toolTipText='Max Buying power = Collateral x 10' /> ${isNaN(Number(collateralInput)) ? '0' : (Number(collateralInput) * 10).toFixed(3)}
        </Text>
      </FlexRow>
      <Flex gap="8px">
        <Input
          placeholder="Amount"
          value={collateralInput}
          onChange={collateralInputHandler}
        />
      </Flex>

      <Text fontSize={14} fontWeight={500} lineHeight={21} color="secondary" margin="24px 0 6px 0">
        Amount (Set order size)
      </Text>

      <Flex gap="8px">
        <Input placeholder={"0.00    " + "(" + selectedToken + ")"}
          value={baseTokenInput}
          onChange={handleBaseTokenInput} />
        <Input placeholder="0.00    (USD)"
          value={quoteTokenInput}
          onChange={handleQuoteTokenInput} />
      </Flex>

      {orderTypes === 'Stop' && (
        <>
          <Text fontSize={14} fontWeight={500} lineHeight={21} color="secondary" margin="24px 0 6px 0">
            Trigger Price (USD)
          </Text>
          <Input step="0.01" type="number" min={0} placeholder="0.000" />
        </>
      )}
      {(orderTypes === 'Limit' || orderTypes === 'Stop') && (
        <>
          <Text fontSize={14} fontWeight={500} lineHeight={21} color="secondary" margin="24px 0 6px 0">
            Limit Price (USD) <ToolTip toolTipText='Only the limit price or better can be used to fulfil this order. If your order crosses another one at the time of placement, the crossing order will be fulfilled at the best price.' />
          </Text>
          <Input step="0.01" type="number" min={0}
            placeholder={"0.00"} />
        </>
      )}
      <Text fontSize={14} fontWeight={500} lineHeight={21} color="secondary" margin="18px 0 6px 0">
        Leverage ({isNaN(leveragePercentage / 10) ? "0.00" : (leveragePercentage / 10) > 10 ? "10" : (leveragePercentage / 10).toFixed(2)}x)
      </Text>
      <Block>
        <Text fontSize={10} fontWeight={500} lineHeight={15} color="secondary" margin="0 16px 0 0">
          0.00x
        </Text>
        <SliderContainer width="80%" padding="0">
          <Slider
            className="up"
            defaultValue={0}
            value={leveragePercentage}
            min={0}
            max={100}
            step={1}
            onChange={leveragePercentageHandler}
            disabled={!collateralInput}
          ></Slider>
        </SliderContainer>
        <Text fontSize={10} fontWeight={500} lineHeight={15} color="secondary" margin="0 0 0 18px">
          10x
        </Text>
      </Block>
      <Dropdown onClick={() => setDropdown(!dropdown)}>
        <Text
          fontSize={16}
          fontWeight={400}
          lineHeight={24}
          color="secondary"
          margin="0 0 0 12px"
          onClick={() => setDropdownValue('Cross margin')}
        >
          {dropdownValue}
        </Text>
        {!dropdown ? (
          <ArrowImage active={dropdown} src={downArrow} />
        ) : (
          <ArrowImage active={dropdown} src={downArrow} />
        )}
      </Dropdown>
      {dropdown && (
        <>
          {dropdownValue !== 'Cross margin' ? (
            <DropdownValues
              onClick={() => {
                setDropdownValue('Cross margin')
                setDropdown(false)
              }}
            >
              <Text
                fontSize={16}
                fontWeight={400}
                lineHeight={24}
                color="secondary"
                margin="0 0 0 12px"
                onClick={() => {
                  setDropdownValue('Cross margin')
                  setDropdown(false)
                }}
              >
                Cross Margin
              </Text>
            </DropdownValues>
          ) : (
            <DropdownValues
              onClick={() => {
                setDropdownValue('Isolated margin')
                setDropdown(false)
              }}
            >
              <Text
                fontSize={16}
                fontWeight={400}
                lineHeight={24}
                color="secondary"
                margin="0 0 0 12px"
                onClick={() => {
                  setDropdownValue('Isolated margin')
                  setDropdown(false)
                }}
              >
                Isolated Margin
              </Text>
            </DropdownValues>
          )}
        </>
      )}

      {dropdownValue === 'Cross margin' ? (
        <>
          <FlexRow>
            <FlexRow
              onClick={() => {
                setGroup('new')
              }}
            >
              <RadioButton active={group === 'new'} />
              <Text fontSize={14} fontWeight={400} lineHeight={21} color="secondary" margin="16px 0 0 6px">
                {' '}
                New Group
              </Text>
            </FlexRow>
            {
              groupsData && groupsData.length > 0 ?
                <FlexRow
                  onClick={() => {
                    setGroup('existing')
                  }}
                >
                  <RadioButton active={group === 'existing'} />
                  <Text fontSize={14} fontWeight={400} lineHeight={21} color="secondary" margin="16px 0 0 6px">
                    {' '}
                    Existing Group
                  </Text>
                </FlexRow>
                : ""
            }
          </FlexRow>
        </>
      ) : (
        <>
          <FlexRow>
            <FlexRow
              onClick={() => {
                setIsolatedPosition('new')
              }}
            >
              <RadioButton active={isolatedPosition === 'new'} />
              <Text fontSize={14} fontWeight={400} lineHeight={21} color="secondary" margin="16px 0 0 6px">
                {' '}
                New Position
              </Text>
            </FlexRow>
            {
              positionData && positionData.length > 0 ?
                <FlexRow
                  onClick={() => {
                    setIsolatedPosition('existing')
                  }}
                >
                  <RadioButton active={isolatedPosition === 'existing'} />
                  <Text fontSize={14} fontWeight={400} lineHeight={21} color="secondary" margin="16px 0 0 6px">
                    {' '}
                    Existing Position
                  </Text>
                </FlexRow>
                : ""
            }
          </FlexRow>
        </>
      )}

      <Flex justifyContent="flex-start">
        <Flex flexDirection="column">

        </Flex>
        {group === 'existing' && dropdownValue === 'Cross margin' ? (
          <Flex>
            <Text fontSize={14} fontWeight={400} lineHeight={21} color="secondary" margin="16px 0 0 6px">
              {' '}
              Group ID
            </Text>
          </Flex>
        ) : (
          ''
        )}
        {isolatedPosition === 'existing' && dropdownValue === 'Isolated margin' ? (
          <Flex flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
            <Text
              fontSize={14}
              fontWeight={400}
              lineHeight={21}
              color="secondary"
              margin="16px 0 0 6px"
              textAlign="left"
            >
              {' '}
              Position ID
            </Text>
          </Flex>
        ) : (
          ''
        )}
      </Flex>

      {isolatedPosition === 'existing' && dropdownValue === 'Isolated margin' ? (
        <DropDownContainer>
          <div className="select_box">
            <select onChange={(evt: any) => {
              setPositionId(evt.target.value)
            }}
              disabled={!(positionData.length > 0)}
            >
              {
                positionData && positionData.length > 0 && positionData.map((data: any, idx: number) => {
                  return (
                    <option style={{ color: "blue" }}>{data}</option>
                  )
                })
              }
            </select>
          </div>
        </DropDownContainer>
      ) : (
        ''
      )}
      {group === 'existing' && dropdownValue === 'Cross margin' ? (
        <DropDownContainer>
          <div className="select_box">
            <select onChange={(evt: any) => {
              setGroupId(evt.target.value)
            }}
              disabled={!(groupsData.length > 0)}
            >
              {
                groupsData && groupsData.length > 0 && groupsData.map((data: any, idx: number) => {
                  return (
                    <option style={{ color: "blue" }}>{data}
                    </option>
                  )
                })
              }
            </select>
          </div>
        </DropDownContainer>
      ) : (
        ''
      )}
      <SharedCard background="secondary" width="100%" padding="12px 0" margin='8px 0 0 0'>
        <FlexRow justifyContent="space-between" margin="0 24px 8px 24px">
          <Text fontSize={12} fontWeight={500} lineHeight={18} color="secondary">
            Expected Price
          </Text>
          <Text
            fontSize={12}
            fontWeight={500}
            lineHeight={18}
            color="secondary"
          >
            {quoteTokenOrgInput && baseTokenOrgInput ? parseFloat(quoteTokenOrgInput) / parseFloat(baseTokenOrgInput) : "0.00"}
          </Text>
        </FlexRow>

        <FlexRow justifyContent="space-between" margin="0 24px 8px 24px">
          <Text fontSize={12} fontWeight={500} lineHeight={18} color="secondary">
            Price Impact
          </Text>
          <Text fontSize={12} fontWeight={500} lineHeight={18} color="secondary">
            {priceImpact ? priceImpact.toFixed(2) : "0.00"}%
          </Text>
        </FlexRow>

        <FlexRow justifyContent="space-between" margin="0 24px 8px 24px">
          <Text fontSize={12} fontWeight={500} lineHeight={18} color="secondary">
            Fee (Taker)
          </Text>
          <Text
            fontSize={12}
            fontWeight={500}
            lineHeight={18}
            color="secondary"
          >
            {quoteTokenOrgInput && swapFee ? parseFloat(quoteTokenOrgInput) * swapFee / 100 : "0.00"}
          </Text>
        </FlexRow>
      </SharedCard>
      <Flex>
        <SharedButton
          margin="24px 0 0 0"
          padding="12px 50px"
          width="100%"
          onClick={confirmPositionMarket}
          disabled={loading || (Number(quoteTokenOrgInput) > (Number(collateralInput) * 10)) ||
            (collateralBal && (Number(collateralInput) > collateralBal)) ||
            !quoteTokenOrgInput || !baseTokenOrgInput || !collateralInput}>

          {loading ? 'Loading...' : <>Confirm {positionType}</>}

        </SharedButton>
      </Flex>
      <ToastContainer position="top-right" style={{ zIndex: '10000' }} />
    </SharedCard>
  )
}

export default Orders
